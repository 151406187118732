import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteIcon from "@mui/icons-material/Delete";
import InactiveIcon from "@mui/icons-material/Hotel";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";

export const StatusIconStatus = Object.freeze({
  Ok: "ok",
  Warning: "warning",
  Deleted: "deleted",
  Inactive: "inactive",
});

const StatusIcon = ({
  status,
  okTooltip,
  warningTooltip,
  deletedTooltip,
  inactiveTooltip,
  ...containerProps
}) => {
  return (
    <Box sx={{ ...containerProps }}>
      {status === StatusIconStatus.Ok && (
        <Tooltip title={okTooltip || "-"}>
          <CheckIcon color="success" />
        </Tooltip>
      )}
      {status === StatusIconStatus.Warning && (
        <Tooltip title={warningTooltip || "-"}>
          <WarningIcon color="warning" />
        </Tooltip>
      )}
      {status === StatusIconStatus.Deleted && (
        <Tooltip title={deletedTooltip || "-"}>
          <DeleteIcon color="warning" />
        </Tooltip>
      )}
      {status === StatusIconStatus.Inactive && (
        <Tooltip title={inactiveTooltip || "-"}>
          <InactiveIcon color="warning" />
        </Tooltip>
      )}
    </Box>
  );
};

export default StatusIcon;
