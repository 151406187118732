import { Box, Typography } from "@mui/material";
import { getCurrencyStr } from "../../../utils/billing";
import Decimal from "decimal.js";
import { useTranslation } from "react-i18next";
import { PrimaryBlue } from "../../../theme/colors";

const CostSummary = ({
  totalCost,
  preTaxTotalCost,
  originalTotalCost,
  preTaxOriginalTotalCost,
  currency,
  originalCurrency,
  recordCountLabel,
  recordCount,
  hideRecordCount,
  hideCost,
  hideOriginalCost,
}) => {
  const { t } = useTranslation();

  const renderCost = (preTaxCost, cost, currency) => {
    const currencyStr = getCurrencyStr(currency);
    const preTaxYearlyCostStr = `${new Decimal(preTaxCost).toFixed(2)}${currencyStr}`;
    const yearlyCostWithVatStr = `${new Decimal(cost).toFixed(2)}${currencyStr}`;

    return (
      <Box sx={{ display: "flex", mr: 2 }}>
        <Box minWidth={180}>
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              backgroundColor: `${PrimaryBlue}10`,
              px: 1,
              borderRadius: 2,
            }}
          >
            <Typography>{preTaxYearlyCostStr}</Typography>
            <Typography variant="caption" sx={{ lineHeight: 2.2, ml: 0.5 }}>
              {`(${t("preTax")})`}
            </Typography>
          </Box>
        </Box>
        <Box minWidth={180}>
          <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            <Typography>{yearlyCostWithVatStr}</Typography>
            <Typography variant="caption" sx={{ lineHeight: 2.2, ml: 0.5 }}>
              {`(${t("withVat")})`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {hideCost !== true && renderCost(preTaxTotalCost, totalCost, currency)}
      {hideOriginalCost !== true &&
        renderCost(
          preTaxOriginalTotalCost,
          originalTotalCost,
          originalCurrency,
        )}
      {hideRecordCount !== true && (
        <Typography variant="caption" sx={{ lineHeight: 2.2 }}>
          {`(${recordCount} ${recordCountLabel || t("accounts")})`}
        </Typography>
      )}
    </>
  );
};

export default CostSummary;
