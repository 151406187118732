import * as Joi from "joi";
import { getFieldStatusGroupsForField } from "./fieldRules";

export const TilkkaRecordProps = Object.freeze({
  SERVICE: "service",
  ACCOUNT_ID: "accountId",
  ACCOUNT_NAME: "accountName",
  OWNER: "owner",
  PRODUCT_CODE: "productCode",
  COST_CENTER: "costCenter",
  ORDER_NUMBER: "orderNumber",
  TAX_CODE: "taxCode",
  PURCHASE_GROUP_NUMBER: "purchaseGroupNumber",
  GL_ACCOUNT_NUMBER: "glAccountNumber",
  DESCRIPTION: "description",
});

const valueOptional = Joi.string().max(100).allow(null, "").optional();
const valueRequired = Joi.string().max(100).required();
const valueRequiredNotEmpty = valueRequired.not("");
const accountNameRequired = Joi.string().max(250).required();
const boolOptional = Joi.bool().allow(null, "").optional();

const taxCode = Joi.object({
  value: valueRequiredNotEmpty,
  description: valueOptional,
}).required();

const purchaseGroupNumber = Joi.object({
  value: valueRequiredNotEmpty,
  description: valueOptional,
}).required();

const glAccountNumber = Joi.object({
  value: valueRequiredNotEmpty,
  description: valueOptional,
  fieldStatusGroup: valueRequiredNotEmpty,
  fieldStatusGroupDescription: valueOptional,
}).required();

const costCenterMandatory = Joi.object({
  value: valueRequiredNotEmpty,
  description: valueOptional,
  companyCode: valueOptional,
  validFrom: valueOptional,
  validTo: valueOptional,
}).required();

const costCenterOptional = Joi.object({
  value: valueOptional,
  description: valueOptional,
  companyCode: valueOptional,
  validFrom: valueOptional,
  validTo: valueOptional,
})
  .allow(null, "")
  .optional();

const orderNumberMandatory = Joi.object({
  value: valueRequiredNotEmpty,
  description: valueOptional,
}).required();

const orderNumberOptional = Joi.object({
  value: valueOptional,
  description: valueOptional,
})
  .allow(null, "")
  .optional();

const productCodeMandatory = Joi.object({
  value: valueRequiredNotEmpty,
  description: valueOptional,
  parent: valueOptional,
  isParent: Joi.bool().optional().allow(null, ""),
}).required();

const productCodeOptional = Joi.object({
  value: valueOptional,
  description: valueOptional,
  parent: valueOptional,
  isParent: Joi.bool().optional().allow(null, ""),
})
  .allow(null, "")
  .optional();

const costCenterFieldStatusGroups = getFieldStatusGroupsForField("COST_CENTER");
const productCodeFieldStatusGroups =
  getFieldStatusGroupsForField("PRODUCT_CODE");
const orderNumberFieldStatusGroups = getFieldStatusGroupsForField(
  "INTERNAL_ORDER_NUMBER"
);

const getSchema = (existingAccountIds = []) => {
  return Joi.object({
    createdAt: valueOptional,
    modifiedAt: valueOptional,
    accountId: Joi.string()
      .custom((value, helper) => {
        // already added
        if (existingAccountIds.includes(value) === true) {
          return helper.error("any.invalid");
        }
        return value;
      })
      .required(),
    accountName: accountNameRequired,
    service: valueRequired,
    owner: valueRequired,
    accountInactive: boolOptional,
    ownerInvalid: boolOptional,
    accountDeleted: boolOptional,
    deletedAt: valueOptional,
    description: valueOptional,
    // billing fields start
    // mandatory
    taxCode,
    purchaseGroupNumber,
    glAccountNumber,
    // optional
    costCenter: Joi.object()
      .when("glAccountNumber.fieldStatusGroup", {
        is: Joi.any().valid(...costCenterFieldStatusGroups.mandatory),
        then: costCenterMandatory,
      })
      .concat(
        Joi.object().when("glAccountNumber.fieldStatusGroup", {
          is: Joi.any().valid(...costCenterFieldStatusGroups.optional),
          then: costCenterOptional,
        })
      )
      .concat(
        Joi.object().when("glAccountNumber.fieldStatusGroup", {
          is: Joi.any().valid(...costCenterFieldStatusGroups.forbidden),
          then: Joi.forbidden(),
        })
      ),
    orderNumber: Joi.object()
      .when("glAccountNumber.fieldStatusGroup", {
        is: Joi.any().valid(...orderNumberFieldStatusGroups.mandatory),
        then: orderNumberMandatory,
      })
      .concat(
        Joi.object().when("glAccountNumber.fieldStatusGroup", {
          is: Joi.any().valid(...orderNumberFieldStatusGroups.optional),
          then: orderNumberOptional,
        })
      )
      .concat(
        Joi.object().when("glAccountNumber.fieldStatusGroup", {
          is: Joi.any().valid(...orderNumberFieldStatusGroups.forbidden),
          then: Joi.forbidden(),
        })
      ),
    productCode: Joi.object()
      .when("glAccountNumber.fieldStatusGroup", {
        is: Joi.any().valid(...productCodeFieldStatusGroups.mandatory),
        then: productCodeMandatory,
      })
      .concat(
        Joi.object().when("glAccountNumber.fieldStatusGroup", {
          is: Joi.any().valid(...productCodeFieldStatusGroups.optional),
          then: productCodeOptional,
        })
      )
      .concat(
        Joi.object().when("glAccountNumber.fieldStatusGroup", {
          is: Joi.any().valid(...productCodeFieldStatusGroups.forbidden),
          then: Joi.forbidden(),
        })
      ),
    // billing fields end
  });
};

export const validateTilkkaRecord = (record, existingAccountIds = []) => {
  const result = getSchema(existingAccountIds).validate(record, {
    abortEarly: false,
  });
  const { error } = result;
  if (error != null) {
    const { details } = error;
    return details.reduce((acc, next) => {
      acc.set(next.context.key, next.message);
      return acc;
    }, new Map());
  }
  return new Map();
};
