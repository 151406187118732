import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../loader/loader";
import SortableTableCell from "../table/sortableTableCell";
import { SortDirection, getComparator } from "../../utils/table";

const SortProps = Object.freeze({
  SERVICE: "service",
  ACCOUNT_NAME: "accountName",
  STATUS: "status",
});

const AccountTable = ({
  records,
  fetchingAccounts,
  onRowClick,
  ...containerPops
}) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState(SortDirection.Descending);
  const [orderByProp, setOrderByProp] = useState(SortProps.ACCOUNT_NAME);
  const [sortedRecords, setSortedRecords] = useState([]);

  useEffect(() => {
    setSortedRecords(records);
  }, [records, setSortedRecords]);

  const handleRequestSort = (property) => {
    const isAsc = orderByProp === property && order === SortDirection.Ascending;
    const newOrder = isAsc ? SortDirection.Descending : SortDirection.Ascending;
    setOrder(newOrder);
    setOrderByProp(property);
    setSortedRecords(
      [...sortedRecords].sort(getComparator(newOrder, property))
    );
  };

  return (
    <Box>
      <TableContainer component={Paper} sx={{ ...containerPops }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.SERVICE}
                label={t("service")}
                onSort={handleRequestSort}
              />
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.ACCOUNT_NAME}
                label={t("accountName")}
                onSort={handleRequestSort}
              />
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("accountId")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("tenant")}</TableCell>
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.STATUS}
                label={t("status")}
                onSort={handleRequestSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchingAccounts === true && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {fetchingAccounts === false && sortedRecords.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t("itemsNotFound")}
                </TableCell>
              </TableRow>
            )}
            {fetchingAccounts === false &&
              sortedRecords.map((row, index) => {
                console.log(row);
                const accountInactive = row.status === "INACTIVE";
                return (
                  <TableRow
                    key={`${row.accountId}_${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor:
                        accountInactive === true ? "lightgrey" : null,
                      "&:hover": {
                        backgroundColor: "#fafafa !important",
                      },
                    }}
                    onClick={() => onRowClick(row)}
                  >
                    <TableCell>{row.service}</TableCell>
                    <TableCell>{row.accountName}</TableCell>
                    <TableCell>{row.accountId}</TableCell>
                    <TableCell>{row.tenantId}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AccountTable;
