import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { createAccountsChartData } from "../../../utils/chart";

const BillingServiceChart = ({ service, billingRecordsMonthlyMap }) => {
  const { t } = useTranslation();

  const chartData = createAccountsChartData(
    t,
    billingRecordsMonthlyMap,
    service
  );
  const data = {
    options: {
      chart: {
        id: "service-chart",
        stacked: true,
      },
      xaxis: {
        categories: chartData.categories,
      },
    },
    series: chartData.series,
  };

  return (
    <Box>
      <Chart options={data.options} series={data.series} type="bar" />
    </Box>
  );
};

BillingServiceChart.propTypes = {
  service: PropTypes.string,
  billingRecordsMonthlyMap: PropTypes.object,
};

export default BillingServiceChart;
