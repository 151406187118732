import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductCodes } from "../../../redux/reducers/sapBillingDataReducer";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import moment from "moment";
import { Box } from "@mui/system";
import LoadMoreButton from "../../loadMoreButton/loadMoreButton";

const ProductCodeTable = ({ ...containerPops }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    productCodes,
    fetchingProductCodes,
    productCodesFetchError,
    productCodesCursor,
  } = useSelector((state) => state.sapBilling);

  useEffect(() => {
    dispatch(fetchProductCodes());
  }, [dispatch]);

  // Error notifications
  useEffect(() => {
    if (productCodesFetchError != null) {
      dispatch(
        showSnackbar({
          message: productCodesFetchError,
          severity: "error",
        })
      );
    }
  }, [productCodesFetchError, dispatch]);

  const records = (productCodes || []).filter(
    (record) => record.isParent === false
  );
  return (
    <Box>
      <TableContainer component={Paper} sx={{ ...containerPops }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("productCode")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("description")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("updated")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchingProductCodes === true && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {fetchingProductCodes === false && records.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {t("itemsNotFound")}
                </TableCell>
              </TableRow>
            )}
            {records.map((row, index) => {
              const value = row.value || row.productCode;
              return (
                <TableRow
                  key={`${value}_row_${index}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{value}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    {moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadMoreButton
        mt={2}
        visible={productCodesCursor != null}
        loading={fetchingProductCodes}
        onClick={() => dispatch(fetchProductCodes(productCodesCursor))}
      />
    </Box>
  );
};

ProductCodeTable.propTypes = {};

export default ProductCodeTable;
