import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import "./index.css";
import theme from "./theme/theme";
import i18n from "./localization/i18n";
import configureStore from "./redux/config/configureStore";
import { Authenticator } from "@aws-amplify/ui-react";

// Disable logs on prod
if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  console.log = () => {};
}

// redux store
const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <Authenticator.Provider>
            <App />
          </Authenticator.Provider>
        </I18nextProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
