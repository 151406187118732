import { Alert, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../layout/layout";

const Unauthorized = () => {
  const { t } = useTranslation();
  return (
    <Layout p={2}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Alert sx={{ m: 2 }} severity="error">
          {t("unauthorizedInfo")}
        </Alert>
      </Box>
    </Layout>
  );
};

export default Unauthorized;
