import objectPath from "object-path";

const TilkkaAuditLogType = Object.freeze({
  TILKKA_UPDATE: "TILKKA_UPDATE",
  TILKKA_CREATE: "TILKKA_CREATE",
  TILKKA_DELETE: "TILKKA_DELETE",
  TILKKA_RESTORE: "TILKKA_RESTORE",
});

const AppClientAuditLogType = Object.freeze({
  APP_CLIENT_DELETE: "APP_CLIENT_DELETE",
  APP_CLIENT_CREATE: "APP_CLIENT_CREATE",
});

const VirtualEmailAuditLogType = Object.freeze({
  VIRTUAL_EMAIL_UPDATE: "VIRTUAL_EMAIL_UPDATE",
});

const SapAuditLogType = Object.freeze({
  SAP_UPDATE: "SAP_UPDATE",
});

const BillingDataAuditLogType = Object.freeze({
  BILLING_DATA_UPDATE: "BILLING_DATA_UPDATE",
});

const AzureProvisionerAuditLogType = Object.freeze({
  SUBSCRIPTION_ADD_ORDER: "SUBSCRIPTION_ADD_ORDER",
  EXPRESS_ROUTE_ADD_ORDER: "EXPRESS_ROUTE_ADD_ORDER",
  EXPRESS_ROUTE_REMOVE_ORDER: "EXPRESS_ROUTE_REMOVE_ORDER",
});

const AzureSimulateModes = ["SIMULATE_SUCCESS", "SIMULATE_ERROR"];

const AzureOrderResultStatus = Object.freeze({
  SUCCESS: "success",
  FAILURE: "failure",
});

export const filterAuditLogByEventOrName = (t, entry, filterText) => {
  const { user, uiTitle, isFailed, isSimulated } = entry;

  let failedText = isFailed ? t("failed") : "";
  let simulatedText = isSimulated ? t("simulated") : "";

  return (
    (uiTitle || "").includes(filterText) ||
    failedText.includes(filterText) ||
    simulatedText.includes(filterText) ||
    (user || "").includes(filterText)
  );
};

export const parseAuditLog = (t, auditLog) => {
  const { auditLogType } = auditLog;

  if (Object.values(TilkkaAuditLogType).includes(auditLogType)) {
    // Tilkka
    return createTilkkaAuditLogUITitle(t, auditLog);
  } else if (Object.values(AppClientAuditLogType).includes(auditLogType)) {
    // App client
    return createAppClientAuditLogUITitle(t, auditLog);
  } else if (Object.values(VirtualEmailAuditLogType).includes(auditLogType)) {
    // Virtual email
    return createVirtualEmailAuditLogUITitle(t, auditLog);
  } else if (
    Object.values(AzureProvisionerAuditLogType).includes(auditLogType)
  ) {
    // Azure provisioner
    return createAzureProvisionerAuditLogUITitle(t, auditLog);
  } else if (Object.values(SapAuditLogType).includes(auditLogType)) {
    // SAP
    return createSAPAuditLogUITitle(t, auditLog);
  } else if (Object.values(BillingDataAuditLogType).includes(auditLogType)) {
    // Billing data
    return createBillingUpdateAuditLogUITitle(t, auditLog);
  }
  return auditLog;
};

export const auditLogDataToString = (data) => {
  if (typeof data === "string") {
    try {
      // might be legacy data which will lead to error
      const objectData = JSON.parse(data);
      return JSON.stringify(objectData, 0, 2);
    } catch {
      return data;
    }
  }
  return JSON.stringify(data, 0, 2);
};

const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

export const createTilkkaAuditLogUITitle = (t, auditLog) => {
  const { data, auditLogType } = auditLog;
  const dataObj = parseData(data);
  const uiTitle = `${t("tilkkaRecord")} "${dataObj.accountName}" ${t(
    `auditLogAction.${auditLogType}`
  )}`;
  return { ...auditLog, uiTitle };
};

export const createVirtualEmailAuditLogUITitle = (t, auditLog) => {
  const { data, auditLogType } = auditLog;
  const dataObj = parseData(data);
  const uiTitle = `${t("virtualEmailRecord")} "${dataObj.id}" ${t(
    `auditLogAction.${auditLogType}`
  )}`;
  return { ...auditLog, uiTitle };
};

export const createBillingUpdateAuditLogUITitle = (t, auditLog) => {
  const { data } = auditLog;
  const dataObj = parseData(data);

  // legacy support
  if (typeof dataObj === "string") {
    return { ...auditLog, uiTitle: dataObj };
  }

  const uiTitle = `${t("billingDataUpdatedFor")} ${t(
    "service"
  ).toLocaleLowerCase()} ${dataObj.service}, ${t("year").toLocaleLowerCase()} ${
    dataObj.year
  }, ${t("month").toLocaleLowerCase()} ${t(`months.${dataObj.monthNumber}`)} }`;
  return { ...auditLog, uiTitle };
};

export const createAppClientAuditLogUITitle = (t, auditLog) => {
  const { data, auditLogType } = auditLog;
  const dataObj = parseData(data);
  const uiTitle = `${t("appClient")} "${dataObj.ClientName}" ${t(
    `auditLogAction.${auditLogType}`
  )}`;
  return { ...auditLog, uiTitle };
};

export const createSAPAuditLogUITitle = (t, auditLog) => {
  const { data, auditLogType } = auditLog;
  const dataObj = parseData(data);

  // legacy support
  if (typeof dataObj === "string") {
    console.log("was string", dataObj);
    return { ...auditLog, uiTitle: dataObj };
  }

  const uiTitle = `${dataObj.count} ${t(`billingDataType.${dataObj.type}`)} ${t(
    `auditLogAction.${auditLogType}`
  )}`;
  console.log("uiTitle", uiTitle);
  return { ...auditLog, uiTitle };
};

export const createAzureProvisionerAuditLogUITitle = (t, auditLog) => {
  const { data, auditLogType } = auditLog;
  const dataObj = parseData(data);
  const orderType = objectPath.get(dataObj, "order.orderType");
  const isSubscriptionOrder = orderType === "azureSubscription";
  const subscriptionName = objectPath.get(dataObj, "order.subscriptionName");
  const simulateMode = objectPath.get(dataObj, "creatorMetadata.simulateMode");
  const status = objectPath.get(dataObj, "status");
  const isFailed = status === AzureOrderResultStatus.FAILURE;
  const isSimulated = AzureSimulateModes.includes(simulateMode);
  const user = auditLog.user || objectPath.get(dataObj, "order.callerEmail");

  let uiTitle;
  if (isSubscriptionOrder === true) {
    uiTitle = `${t("subscription")} "${subscriptionName}" ${t(
      `auditLogAction.${auditLogType}`
    )}`;
  } else {
    uiTitle = `${t("expressRoute")} ${t(`auditLogAction.${auditLogType}`)}`;
  }

  return { ...auditLog, uiTitle, isFailed, isSimulated, user };
};
