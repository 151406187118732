import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillingAccountNumbers } from "../../../redux/reducers/sapBillingDataReducer";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import moment from "moment";

const BillingAccountNumberTable = ({ ...containerPops }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    billingAccountNumbers,
    fetchingBillingAccountNumbers,
    billingAccountNumbersFetchError,
  } = useSelector((state) => state.sapBilling);

  useEffect(() => {
    dispatch(fetchBillingAccountNumbers());
  }, [dispatch]);

  // Error notifications
  useEffect(() => {
    if (billingAccountNumbersFetchError != null) {
      dispatch(
        showSnackbar({
          message: billingAccountNumbersFetchError,
          severity: "error",
        })
      );
    }
  }, [billingAccountNumbersFetchError, dispatch]);

  const records = billingAccountNumbers || [];
  return (
    <TableContainer component={Paper} sx={{ ...containerPops }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("glAccountNumber")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("description")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("fieldStatusGroup")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("fieldStatusGroupDescription")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>{t("updated")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchingBillingAccountNumbers === true && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Loader />
              </TableCell>
            </TableRow>
          )}
          {fetchingBillingAccountNumbers === false && records.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {fetchingBillingAccountNumbers === false &&
            records.map((row, index) => {
              const value = row.value || row.gLAccounNumber;
              return (
                <TableRow
                  key={`${value}_row_${index}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{value}</TableCell>
                  <TableCell>{row.description || row.gLDescription}</TableCell>
                  <TableCell>{row.fieldStatusGroup}</TableCell>
                  <TableCell>{row.fieldStatusGroupDescription}</TableCell>
                  <TableCell>
                    {moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BillingAccountNumberTable.propTypes = {};

export default BillingAccountNumberTable;
