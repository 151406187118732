import axios from "../../utils/axios";
import { sortRecordsByName } from "../../utils/billing";

const Types = {
  FETCHING_TILKKA_RECORDS_START: "FETCHING_TILKKA_RECORDS_START",
  FETCHING_TILKKA_RECORDS_FINISH: "FETCHING_TILKKA_RECORDS_FINISH",
  FETCHING_TILKKA_RECORDS_ERROR: "FETCHING_TILKKA_RECORDS_ERROR",
  UPDATE_TILKKA_RECORDS: "UPDATE_TILKKA_RECORDS",
  SAVING_TILKKA_RECORD_START: "SAVING_TILKKA_RECORD_START",
  SAVING_TILKKA_RECORD_FINISH: "SAVING_TILKKA_RECORD_FINISH",
  SAVING_TILKKA_RECORD_ERROR: "SAVING_TILKKA_RECORD_ERROR",
};

const API_URL = process.env.REACT_APP_API_URL;
const TILKKA_API_URL = `${API_URL}tilkka`;
let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

export const saveTilkkaRecord = (recordData) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.SAVING_TILKKA_RECORD_START });

      const url = `${TILKKA_API_URL}/`;
      const response = await axios.post(url, recordData);
      console.log("doSaveTilkkaRecord", response.data);

      const newRecord = response.data.item;
      const tilkkaRecords = getState().tilkka.tilkkaRecords.slice(0);

      // update or add
      const index = tilkkaRecords.findIndex(
        (record) => record.accountId === newRecord.accountId
      );
      if (index !== -1) {
        tilkkaRecords.splice(index, 1, newRecord);
      } else {
        tilkkaRecords.push(newRecord);
      }

      dispatch({
        type: Types.UPDATE_TILKKA_RECORDS,
        payload: sortRecordsByName(tilkkaRecords),
      });
      dispatch({ type: Types.SAVING_TILKKA_RECORD_FINISH });

      return Promise.resolve(newRecord);
    } catch (error) {
      dispatch({
        type: Types.SAVING_TILKKA_RECORD_ERROR,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

export const fetchTilkkaRecords = (service = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_TILKKA_RECORDS_START });

      let url = TILKKA_API_URL;
      if (service != null) {
        url = `${url}?service=${service}`;
      }

      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchTilkkaRecords", response.data);

      dispatch({
        type: Types.UPDATE_TILKKA_RECORDS,
        payload: sortRecordsByName(response.data.items),
      });

      dispatch({ type: Types.FETCHING_TILKKA_RECORDS_FINISH });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_TILKKA_RECORDS_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const deleteTilkkaRecord = (accountId) => {
  return async (dispatch, getState) => {
    const url = `${TILKKA_API_URL}/delete`;
    return await doDeleteRestoreTilkkaRecord(
      dispatch,
      getState,
      url,
      accountId
    );
  };
};

export const restoreTilkkaRecord = (accountId) => {
  return async (dispatch, getState) => {
    const url = `${TILKKA_API_URL}/restore`;
    return await doDeleteRestoreTilkkaRecord(
      dispatch,
      getState,
      url,
      accountId
    );
  };
};

const doDeleteRestoreTilkkaRecord = async (
  dispatch,
  getState,
  url,
  accountId
) => {
  try {
    console.log("doDeleteRestoreTilkkaRecord", accountId);

    dispatch({ type: Types.SAVING_TILKKA_RECORD_START });

    const response = await axios.post(url, {
      accountId,
    });
    console.log("doDeleteRestoreTilkkaRecord", response.data);

    const newRecord = response.data.item;
    const tilkkaRecords = getState().tilkka.tilkkaRecords.slice(0);

    // update or add
    const index = tilkkaRecords.findIndex(
      (record) => record.accountId === newRecord.accountId
    );
    if (index !== -1) {
      tilkkaRecords.splice(index, 1, newRecord);
    } else {
      tilkkaRecords.push(newRecord);
    }

    dispatch({
      type: Types.UPDATE_TILKKA_RECORDS,
      payload: sortRecordsByName(tilkkaRecords),
    });
    dispatch({ type: Types.SAVING_TILKKA_RECORD_FINISH });

    return Promise.resolve(newRecord);
  } catch (error) {
    dispatch({
      type: Types.SAVING_TILKKA_RECORD_ERROR,
      payload: error.message,
    });
    return Promise.reject(error);
  }
};

const INIT_STATE = {
  tilkkaRecords: [],
  fetchingTilkkaRecords: false,
  tilkkaRecordsFetchError: null,
  savingTilkkaRecord: false,
  tilkkaRecordSaveError: null,
};

export const tilkkaReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Types.FETCHING_TILKKA_RECORDS_START:
      return {
        ...state,
        fetchingTilkkaRecords: true,
        tilkkaRecordsFetchError: null,
      };
    case Types.FETCHING_TILKKA_RECORDS_FINISH:
      return {
        ...state,
        fetchingTilkkaRecords: false,
      };
    case Types.FETCHING_TILKKA_RECORDS_ERROR:
      return {
        ...state,
        fetchingTilkkaRecords: false,
        tilkkaRecordsFetchError: action.payload,
      };
    case Types.UPDATE_TILKKA_RECORDS:
      return { ...state, tilkkaRecords: action.payload };
    case Types.SAVING_TILKKA_RECORD_START:
      return {
        ...state,
        savingTilkkaRecord: true,
        tilkkaRecordSaveError: null,
      };
    case Types.SAVING_TILKKA_RECORD_FINISH:
      return { ...state, savingTilkkaRecord: false };
    case Types.SAVING_TILKKA_RECORD_ERROR:
      return {
        ...state,
        savingTilkkaRecord: false,
        tilkkaRecordSaveError: action.payload,
      };
    default:
      return state;
  }
};
