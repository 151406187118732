import AuditLogRecordType from "../../components/auditLog/auditLogRecordType";
import axios from "../../utils/axios";

const Types = {
  FETCH_AUDIT_LOGS_START: "FETCH_AUDIT_LOGS_START",
  FETCH_AUDIT_LOGS_SUCCESS: "FETCH_AUDIT_LOGS_SUCCESS",
  FETCH_AUDIT_LOGS_FAILURE: "FETCH_AUDIT_LOGS_FAILURE",
  UPDATE_AUDIT_LOGS: "UPDATE_AUDIT_LOGS",
};

const API_URL = process.env.REACT_APP_API_URL;
const AUDIT_LOG_API_URL = `${API_URL}admin/audit-log`;
let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

export const fetchAuditLogs = (recordType, nextCursor = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCH_AUDIT_LOGS_START });

      let url = `${AUDIT_LOG_API_URL}/${recordType}`;
      if (nextCursor != null) {
        url += `?cursor=${nextCursor}`;
      }

      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchAuditLogs", response.data);

      const { items, cursor } = response.data;
      let currentItems = items;

      // if Azure sort records by timestamp, we need to reverse the order to show the latest first
      if (recordType === AuditLogRecordType.AZURE_PROVISIONER) {
        currentItems = items.reverse();
      }

      if (nextCursor != null) {
        currentItems = [...getState().auditLog.auditLogs, ...items];
      }

      dispatch({
        type: Types.UPDATE_AUDIT_LOGS,
        payload: { items: currentItems, cursor },
      });

      dispatch({ type: Types.FETCH_AUDIT_LOGS_SUCCESS });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCH_AUDIT_LOGS_FAILURE,
          payload: error.message,
        });
      }
    }
  };
};

export const clearAuditLogs = () => {
  return {
    type: Types.UPDATE_AUDIT_LOGS,
    payload: { items: [], cursor: null },
  };
};

const INIT_STATE = {
  auditLogs: [],
  fetchingAuditLogs: false,
  auditLogsFetchError: null,
  cursor: null,
};

export const auditLogReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_AUDIT_LOGS_START:
      return {
        ...state,
        fetchingAuditLogs: true,
        auditLogsFetchError: null,
      };
    case Types.FETCH_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        fetchingAuditLogs: false,
      };
    case Types.FETCH_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        fetchingAuditLogs: false,
        auditLogsFetchError: action.payload,
      };
    case Types.UPDATE_AUDIT_LOGS: {
      const { items, cursor } = action.payload;
      return {
        ...state,
        auditLogs: items,
        cursor,
      };
    }
    default:
      return state;
  }
};
