import { Paper } from "@mui/material";
import Layout from "../../layout/layout";
import { useTranslation } from "react-i18next";
import PageHeaderText from "../../pageHeaderText/pageHeaderText";

const Azure = () => {
  const { t } = useTranslation();

  return (
    <Layout p={2}>
      <PageHeaderText text={t("azure")} />
      <Paper sx={{ width: "100%" }}>TODO</Paper>
    </Layout>
  );
};

export default Azure;
