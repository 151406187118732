import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { signOut } from "aws-amplify/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Account = ({ userData, ...containerProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "account-popper" : undefined;

  return (
    <Box sx={{ ...containerProps }}>
      <IconButton
        color="secondary"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <AccountCircleOutlinedIcon fontSize="medium" />
      </IconButton>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ lineHeight: 1, fontWeight: "bold" }}
          >
            {t("username")}
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1, mt: 1 }}>
            {`${userData.email}`}
          </Typography>
          <Typography
            variant="body1"
            sx={{ lineHeight: 1, mt: 2, fontWeight: "bold" }}
          >
            {t("roles")}
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: 1, mt: 1 }}>
            {`${userData.roles.join(", ")}`}
          </Typography>
          <Divider sx={{ py: 1, width: "100%" }} />
          <Button
            size="small"
            sx={{ mt: 2 }}
            variant="outlined"
            color="primary"
            onClick={() => {
              signOut();
              navigate("/");
            }}
          >
            {t("logout")}
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default Account;
