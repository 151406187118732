import { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLogTargets,
  fetchLogsForTarget,
} from "../../redux/reducers/logsReducer";
import { showSnackbar } from "../../redux/reducers/notificationReducer";
import { useTranslation } from "react-i18next";
import { LogSource } from "./logs";
import LogPanel from "./logPanel";

const DEFAULT_DAYS = 1;
const MIN_DAYS = 1;
const MAX_DAYS = 14;

const AwsLogs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    logs,
    fetchingLogs,
    logsFetchError,
    logTargets,
    fetchingLogTargets,
    logTargetsFetchError,
  } = useSelector((state) => state.log);
  const [days, setDays] = useState(DEFAULT_DAYS);
  const [target, setTarget] = useState(null);

  useEffect(() => {
    dispatch(fetchLogTargets(LogSource.AWS));
  }, [dispatch]); // eslint-disable-line

  // Error notifications
  useEffect(() => {
    if (logsFetchError != null) {
      dispatch(showSnackbar({ message: logsFetchError, severity: "error" }));
    }
    if (logTargetsFetchError != null) {
      dispatch(
        showSnackbar({ message: logTargetsFetchError, severity: "error" })
      );
    }
  }, [logsFetchError, logTargetsFetchError, dispatch]);

  const doFetchLogs = (days) => {
    const daysNumber = Number(days);
    if (daysNumber >= MIN_DAYS && daysNumber <= MAX_DAYS && target != null) {
      dispatch(fetchLogsForTarget(LogSource.AWS, target, daysNumber));
    }
  };

  return (
    <Box>
      <Box sx={{ my: 2 }}>
        <TextField
          sx={{ minWidth: 100 }}
          size="small"
          label={t("fromDays")}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min: MIN_DAYS, max: MAX_DAYS } }}
          value={days}
          onChange={(e) => {
            setDays(e.target.value);
          }}
        />
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          onClick={() => doFetchLogs(days)}
          disabled={days < MIN_DAYS || days > MAX_DAYS}
        >
          {t("fetch")}
        </Button>
      </Box>
      <LogPanel
        fetchingLogs={fetchingLogs}
        logs={logs}
        fetchingLogTargets={fetchingLogTargets}
        logTargets={logTargets}
        onLogTargetSelected={(target) => {
          setTarget(target);
          dispatch(fetchLogsForTarget(LogSource.AWS, target, days));
        }}
        selectedLogTarget={target}
      />
    </Box>
  );
};

export default AwsLogs;
