import PropTypes from "prop-types";
import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { auditLogDataToString } from "../../utils/auditLog";

const AuditLogEntry = ({ auditLog, ...containerPops }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onChange = (value) => {
    setOpen(value);
  };

  const {
    uiTitle,
    isFailed,
    isSimulated,
    createdAt,
    user,
    data,
    dataChanges,
    timestamp,
  } = auditLog;
  return (
    <Box sx={{ ...containerPops }}>
      <Accordion expanded={open} onChange={(_e, value) => onChange(value)}>
        <AccordionSummary
          sx={{
            backgroundColor: open ? "primary.main" : "primary.contrastText",
            color: open ? "primary.contrastText" : "default",
          }}
          expandIcon={
            <ExpandMoreIcon
              sx={{ color: open ? "primary.contrastText" : "default" }}
            />
          }
        >
          <Box sx={{ flex: 2 }}>
            <Typography variant="body1">{uiTitle}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isFailed && (
                <Typography variant="body2" color="error">
                  {t("failed")}
                </Typography>
              )}
              {isFailed && isSimulated && (
                <Typography
                  sx={{
                    mr: 0.5,
                  }}
                  variant="body2"
                >
                  ,
                </Typography>
              )}
              {isSimulated && (
                <Typography variant="body2" color="green">{`${t(
                  "simulated"
                )}`}</Typography>
              )}
            </Box>
          </Box>
          <Typography sx={{ flex: 1 }} variant="body2">
            {user || t("unknownUser")}
          </Typography>
          <Typography sx={{ flex: 1 }} variant="body2">
            {moment(createdAt || timestamp).format("DD.MM.YYYY HH:mm:ss")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Typography variant="body1">{t("payload")}</Typography>
              <pre>{auditLogDataToString(data)}</pre>
            </Box>
            {dataChanges != null && Array.isArray(dataChanges) && (
              <Box sx={{ ml: 2 }}>
                <Typography sx={{ mb: 1 }} variant="body1">
                  {t("changes")}
                </Typography>
                {dataChanges.map((change, index) => (
                  <Typography key={index} variant="caption">
                    {change}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

AuditLogEntry.propTypes = {
  auditLog: PropTypes.object,
};

export default AuditLogEntry;
