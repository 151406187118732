import { useState } from "react";
import { Box, Paper } from "@mui/material";
import YearSelect from "../filters/yearSelect";
import moment from "moment";
import OwnerList from "./ownerList";
import OwnerContent from "./ownerContent";

const BillingByOwner = ({ ...containerProps }) => {
  const [year, setYear] = useState(moment().year());
  const [selectedOwner, setSelectedOwner] = useState("");

  return (
    <Box sx={{ p: 2, ...containerProps }}>
      <YearSelect
        value={year}
        onSelect={(value) => {
          setYear(value);
        }}
      />
      <Box sx={{ my: 2, display: "flex" }}>
        <Paper sx={{ p: 2, flex: 1 }}>
          <OwnerList
            selectedOwner={selectedOwner}
            onSelect={(newOwner) => setSelectedOwner(newOwner)}
          />
        </Paper>
        <Box sx={{ flex: 3, ml: 2, overflow: "auto" }}>
          <OwnerContent selectedOwner={selectedOwner} selectedYear={year} />
        </Box>
      </Box>
    </Box>
  );
};

BillingByOwner.propTypes = {};

export default BillingByOwner;
