import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../redux/reducers/notificationReducer";
import Loader from "../loader/loader";
import {
  clearAuditLogs,
  fetchAuditLogs,
} from "../../redux/reducers/auditLogReducer";
import AuditLogEntry from "./auditLogEntry";
import AuditLogRecordType from "./auditLogRecordType";
import LoadMoreButton from "../loadMoreButton/loadMoreButton";
import {
  filterAuditLogByEventOrName,
  parseAuditLog,
} from "../../utils/auditLog";
import { useTranslation } from "react-i18next";

const AuditLogSap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auditLogs, fetchingAuditLogs, auditLogsFetchError, cursor } =
    useSelector((state) => state.auditLog);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    dispatch(clearAuditLogs());
    dispatch(fetchAuditLogs(AuditLogRecordType.SAP, null));
  }, [dispatch]); // eslint-disable-line

  // Error notifications
  useEffect(() => {
    if (auditLogsFetchError != null) {
      dispatch(
        showSnackbar({ message: auditLogsFetchError, severity: "error" })
      );
    }
  }, [auditLogsFetchError, dispatch]);

  return (
    <Box>
      <TextField
        sx={{ minWidth: 500, my: 2 }}
        label={t("filter")}
        placeholder={t("auditLogFilterPlaceholder")}
        size="small"
        variant="outlined"
        value={filterText || ""}
        onChange={(e) => setFilterText(e.target.value)}
      />
      {fetchingAuditLogs === true && <Loader />}
      {auditLogs
        .map((auditLog) => parseAuditLog(t, auditLog))
        .filter((auditLog) =>
          filterAuditLogByEventOrName(t, auditLog, filterText)
        )
        .map((auditLog) => (
          <AuditLogEntry key={auditLog.recordSortKey} auditLog={auditLog} />
        ))}
      <LoadMoreButton
        mt={2}
        visible={cursor != null}
        onClick={() => dispatch(fetchAuditLogs(AuditLogRecordType.SAP, cursor))}
        loading={fetchingAuditLogs}
      />
    </Box>
  );
};

export default AuditLogSap;
