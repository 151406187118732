import { Service } from "../components/common";

export const recipientStrToArray = (recipientsStr) => {
  try {
    return JSON.parse(recipientsStr);
  } catch {
    return [];
  }
};

export const recipientsStrToJson = (recipientsStr) => {
  try {
    return JSON.stringify(
      recipientsStr.split(",").map((value) => value.trim())
    );
  } catch {
    return "";
  }
};

// Filter by account name,account id, owner, service or cost center
export const filterVirtualEmailRecords = (filterText, virtualEmailRecords) => {
  if (filterText == null || filterText.length === 0) {
    return virtualEmailRecords;
  }

  const safeCheck = (value, filterText) => {
    return (value || "").toLocaleLowerCase().includes(filterText);
  };
  return virtualEmailRecords.filter((record) => {
    return (
      safeCheck(record.id, filterText) ||
      safeCheck(record.recipients, filterText) ||
      safeCheck(record.owner, filterText)
    );
  });
};

export const getVirtualEmailDomainsFromConfig = (configuration) => {
  const { VirtualEmailDomainsProd = [], VirtualEmailDomainsTest = [] } =
    configuration;
  return VirtualEmailDomainsProd.concat(VirtualEmailDomainsTest);
};

export const getDomainFromEmailAddress = (emailAddress) => {
  const splitted = emailAddress.split("@");
  return splitted[1];
};

export const getValidationErrorsStr = (invalidPropsMap) => {
  if (invalidPropsMap == null || invalidPropsMap.size === 0) {
    return "";
  }

  let str = null;
  for (const value of invalidPropsMap.values()) {
    if (str == null) {
      str = value;
    } else {
      str = `${str}, ${value}`;
    }
  }
  return str;
};

export const constructVirtualEmailAddress = (service, identifier) => {
  const isProd = process.env.REACT_APP_ENVIRONMENT === "prod";

  switch (service) {
    case Service.AWS:
      return isProd
        ? `${identifier}@accounts.amz.elisa.fi`
        : `${identifier}@ctpoc.amz.elisa.fi`;
    case Service.GCP:
      return isProd
        ? `${identifier}@accounts.gcp.elisa.fi`
        : `${identifier}@ctpoc.amz.elisa.fi`;
    case Service.AZURE:
      return isProd
        ? `${identifier}@accounts.azure.elisa.fi`
        : `${identifier}@ctpoc.amz.elisa.fi`;
    default:
      return "";
  }
};
