import { isEmptyString } from "../../../utils/tilkka";

const GlAccountNumberStr = ({ objectValue }) => {
  // sanity
  if (objectValue == null) {
    return "-";
  }

  const { value, description, fieldStatusGroup } = objectValue;
  if (isEmptyString(description)) {
    return value;
  }
  return `${description} (${value}) (${fieldStatusGroup})`;
};

export default GlAccountNumberStr;
