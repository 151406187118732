import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

const LogEntry = ({ logRecord, ...containerPops }) => {
  const [open, setOpen] = useState(false);

  const onChange = (value) => {
    setOpen(value);
  };

  const { source, message, timestamp } = logRecord;
  return (
    <Box sx={{ ...containerPops }}>
      <Accordion expanded={open} onChange={(_e, value) => onChange(value)}>
        <AccordionSummary
          sx={{
            backgroundColor: open ? "primary.main" : "primary.contrastText",
            color: open ? "primary.contrastText" : "default",
          }}
          expandIcon={
            <ExpandMoreIcon
              sx={{ color: open ? "primary.contrastText" : "default" }}
            />
          }
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ mb: 0.5, display: "flex", alignItems: "center" }}>
              <Typography variant="body2">
                {moment.unix(timestamp / 1000).format("DD.MM.YYYY HH:mm:ss")}
              </Typography>
              <Typography sx={{ ml: 1 }} fontWeight="bold" variant="body2">
                {source.split("/lambda/")[1]}
              </Typography>
            </Box>
            {open === false && (
              <Typography variant="caption">
                {message.substring(0, 200)}
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ overflowX: "scroll" }}>
            <pre>{message}</pre>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LogEntry;
