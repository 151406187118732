import { useDispatch, useSelector } from "react-redux";
import { Amplify } from "aws-amplify";
import { fetchAuthSession, signInWithRedirect } from "aws-amplify/auth";
import { Box } from "@mui/system";
import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setUserData } from "../redux/reducers/userReducer";
import { showSnackbar } from "../redux/reducers/notificationReducer";

const SAML_IDENTITY_PROVIDER_NAME = "Elisa-SSO";
const DEV_SIGNIN_URL = "http://localhost:3000";
const DEV_SIGNOUT_URL = `${DEV_SIGNIN_URL}`;

const isDev = () => {
  return process.env.REACT_APP_LOCAL_DEV === "true";
};

export const getAuthUrls = () => {
  let signIn = [process.env.REACT_APP_CLIENT_URL];
  let signOut = [`${process.env.REACT_APP_CLIENT_URL}`];
  if (isDev()) {
    signIn = [DEV_SIGNIN_URL];
    signOut = [DEV_SIGNOUT_URL];
  }
  return {
    signIn,
    signOut,
  };
};

const configureAuth = () => {
  const urls = getAuthUrls();
  const config = {
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
        region: process.env.REACT_APP_REGION,
        loginWith: {
          // Optional
          oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN_URL.replace(
              "https://",
              "",
            ),
            scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
            redirectSignIn: urls.signIn,
            redirectSignOut: urls.signOut,
            responseType: "token",
          },
          username: false,
          email: false,
          phone: false,
        },
      },
    },
  };
  Amplify.configure(config);
};

const CognitoAuthenticator = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const [authenticating, setAuthenticating] = useState(true);

  useEffect(() => {
    configureAuth();
    fetchAuthSession()
      .then((user) => dispatch(setUserData(user)))
      .catch(() => {
        console.log("User not authenticated");
        setAuthenticating(false);
      });
  }, []); // eslint-disable-line

  const doAuthenticate = () => {
    setAuthenticating(true);
    signInWithRedirect({
      provider: { custom: SAML_IDENTITY_PROVIDER_NAME },
    })
      .then(() => {
        return fetchAuthSession();
      })
      .then((user) => {
        setAuthenticating(false);
        dispatch(setUserData(user));
      })
      .catch((error) => {
        setAuthenticating(false);
        dispatch(
          showSnackbar({
            message: error.message || "unknown error",
            severity: "error",
          }),
        );
      });
  };

  const renderLogin = () => {
    return (
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "30%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box>
            <img
              style={{ maxWidth: "50px", maxheight: "50px" }}
              src={require("../images/Elisa_logo_blue_RGB.png")}
              alt="elisa"
            />
          </Box>
          <Box sx={{ ml: 1 }}>
            <Typography textAlign="center" variant="h5">
              {t("appName")}
            </Typography>
          </Box>
        </Box>

        <Alert sx={{ mb: 2 }} severity="info">
          {t("signinInfo")}
        </Alert>
        <Button
          sx={{}}
          fullWidth
          variant="outlined"
          onClick={() => doAuthenticate()}
          disabled={authenticating === true}
        >
          {SAML_IDENTITY_PROVIDER_NAME}
        </Button>
      </Paper>
    );
  };

  const renderLoader = () => {
    return (
      <CircularProgress sx={{ color: "primary.contrastText" }} size={30} />
    );
  };

  if (userData == null) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "lightgrey",
        }}
      >
        {authenticating === false && renderLogin()}
        {authenticating === true && renderLoader()}
      </Box>
    );
  }

  return children;
};

export default CognitoAuthenticator;
