import axios from "../../utils/axios";

const Types = {
  FETCH_DOCTREE_START: "FETCH_DOCTREE_START",
  FETCH_DOCTREE_SUCCESS: "FETCH_DOCTREE_SUCCESS",
  FETCH_DOCTREE_FAILURE: "FETCH_DOCTREE_FAILURE",
  FETCH_DOC_START: "FETCH_DOC_START",
  FETCH_DOC_SUCCESS: "FETCH_DOC_SUCCESS",
  FETCH_DOC_FAILURE: "FETCH_DOC_FAILURE",
  UPDATE_DOCS: "UPDATE_DOCS",
};

const API_URL = process.env.REACT_APP_API_URL;
const DOCS_API_URL = `${API_URL}docs`;
let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

export const fetchDocumentTree = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCH_DOCTREE_START });

      const url = `${DOCS_API_URL}/tree`;

      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchAvailableDocs", response.data);

      dispatch({
        type: Types.UPDATE_DOCS,
        payload: response.data,
      });

      dispatch({ type: Types.FETCH_DOCTREE_SUCCESS });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCH_DOCTREE_FAILURE,
          payload: error.message,
        });
      }
    }
  };
};

export const fetchDocument = (key) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCH_DOC_START });

      const url = `${DOCS_API_URL}/${key}`;

      console.log("fetchDocument", url);

      const response = await axios.get(url);
      console.log("fetchDoc", response.data);

      dispatch({ type: Types.FETCH_DOC_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOC_FAILURE,
        payload: error.message,
      });
    }
  };
};

const INIT_STATE = {
  documentTree: {},
  fetchingDocumentTree: false,
  documentTreeFetchError: null,
  doc: null,
  fetchingDoc: false,
  docFetchingError: null,
};

export const docsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_DOCTREE_START: {
      return {
        ...state,
        fetchingDocumentTree: true,
        documentTreeFetchError: null,
      };
    }
    case Types.FETCH_DOCTREE_SUCCESS: {
      return { ...state, fetchingDocumentTree: false };
    }
    case Types.FETCH_DOCTREE_FAILURE: {
      return {
        ...state,
        fetchingDocumentTree: false,
        documentTreeFetchError: action.payload,
      };
    }
    case Types.FETCH_DOC_START: {
      return { ...state, fetchingDoc: true, docFetchingError: null };
    }
    case Types.FETCH_DOC_SUCCESS: {
      return { ...state, fetchingDoc: false, doc: action.payload };
    }
    case Types.FETCH_DOC_FAILURE: {
      return {
        ...state,
        fetchingDoc: false,
        docFetchingError: action.payload,
      };
    }
    case Types.UPDATE_DOCS: {
      return { ...state, documentTree: action.payload };
    }
    default:
      return state;
  }
};
