import axios from "axios";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

// set interceptor for refreshing token
axios.interceptors.request.use((config) => {
  return new Promise((resolve) => {
    fetchAuthSession()
      .then((session) => {
        const { tokens } = session;
        var idTokenExpire = tokens.idToken.payload.exp;
        var currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          fetchAuthSession({ forceRefresh: true })
            .then((refreshedSession) => {
              const { tokens } = refreshedSession;
              config.headers.Authorization =
                "Bearer " + tokens.idToken.toString();
              resolve(config);
            })
            .catch((err) => {
              console.log("refresh session error", err);
              signOut();
            });
        } else {
          config.headers.Authorization = "Bearer " + tokens.idToken.toString();
          resolve(config);
        }
      })
      .catch(() => {
        console.log("Session expired, cannot set authorization header");

        // No logged-in user: don't set auth header
        resolve(config);
      });
  });
});

export default axios;
