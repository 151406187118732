import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "../middleware/logger";
import createRootReducer from "../reducers";

const doConfigureStore = (preloadedState) => {
  const rootReducer = createRootReducer();
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(loggerMiddleware, thunkMiddleware))
  );

  // if not prod, reload only changed reducers
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("../reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
};
export default doConfigureStore;
