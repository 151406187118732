import axios from "../../utils/axios";

const Types = {
  FETCHING_AZURE_APPS_START: "FETCHING_AZURE_APPS_START",
  FETCHING_AZURE_APPS_FINISH: "FETCHING_AZURE_APPS_FINISH",
  FETCHING_AZURE_APPS_ERROR: "FETCHING_AZURE_APPS_ERROR",
};

const API_URL = process.env.REACT_APP_API_URL;
const AZURE_APPLICATIONS_API_URL = `${API_URL}azure-app`;

let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

export const fetchAzureApplications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_AZURE_APPS_START });

      const response = await axios.get(AZURE_APPLICATIONS_API_URL, {
        signal: getAbortController().signal,
      });

      console.log("fetchAzureApplications", response.data.items);

      dispatch({
        type: Types.FETCHING_AZURE_APPS_FINISH,
        payload: response.data.items,
      });
      return response.data.items;
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_AZURE_APPS_ERROR,
          payload: error.message,
        });
      }
      return [];
    }
  };
};

const INIT_STATE = {
  azureApplications: [],
  fetchingAzureApplications: false,
  azureApplicationsFetchError: null,
};

export const azureApplicationsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Types.FETCHING_AZURE_APPS_START:
      return {
        ...state,
        fetchingAzureApplications: true,
        azureApplicationsFetchError: null,
      };
    case Types.FETCHING_AZURE_APPS_FINISH:
      return {
        ...state,
        azureApplications: action.payload,
        fetchingAzureApplications: false,
      };
    case Types.FETCHING_AZURE_APPS_ERROR:
      return {
        ...state,
        fetchingAzureApplications: false,
        azureApplicationsFetchError: action.payload,
      };
    default:
      return state;
  }
};
