import { StatusIconStatus } from "../components/billing/statusIcon/statusIcon";

const objectMapper = require("object-mapper");

export const getTilkkaRecordProps = (recordData) => {
  return objectMapper(recordData, {
    accountId: "accountId",
    accountName: "accountName",
    glAccountNumber: "glAccountNumber",
    purchaseGroupNumber: "purchaseGroupNumber",
    taxCode: "taxCode",
    costCenter: "costCenter",
    orderNumber: "orderNumber",
    productCode: "productCode",
    service: "service",
    owner: "owner",
    description: "description",
    accountInactive: "accountInactive",
    accountDeleted: "accountDeleted",
  });
};

// Filter by account name,account id, owner, service or cost center
export const filterTilkkaRecords = (filterText, tilkkaRecords) => {
  if (filterText == null || filterText.length === 0) {
    return tilkkaRecords;
  }

  const safeCheck = (value, filterText) => {
    return (value || "").toLocaleLowerCase().includes(filterText);
  };
  return tilkkaRecords.filter((record) => {
    return (
      safeCheck(record.accountName, filterText) ||
      safeCheck(record.accountId, filterText) ||
      safeCheck(record.owner, filterText) ||
      safeCheck(record.service, filterText) ||
      safeCheck((record.costCenter || {}).value, filterText) ||
      safeCheck((record.glAccountNumber || {}).value, filterText) ||
      safeCheck((record.purchaseGroupNumber || {}).value, filterText) ||
      safeCheck((record.orderNumber || {}).value, filterText) ||
      safeCheck((record.productCode || {}).value, filterText) ||
      safeCheck((record.taxCode || {}).value, filterText)
    );
  });
};

export const isEmptyString = (value) => {
  return value == null || value.length === 0;
};

export const getTaxCodeValue = (taxCode) => {
  if (taxCode == null) {
    return undefined;
  }
  return {
    value: taxCode.value,
    description: taxCode.description,
  };
};

export const getCostCenterValue = (costCenter) => {
  if (costCenter == null) {
    return undefined;
  }
  return {
    value: costCenter.value,
    description: costCenter.description,
    companyCode: costCenter.companyCode,
    validFrom: costCenter.validFrom,
    validTo: costCenter.validTo,
  };
};

export const getPurchaseGroupNumberValue = (purchaseGroupNumber) => {
  if (purchaseGroupNumber == null) {
    return undefined;
  }
  return {
    value: purchaseGroupNumber.value,
    description: purchaseGroupNumber.description,
  };
};

export const getGlAccountNumberValue = (glAccountNumber) => {
  if (glAccountNumber == null) {
    return undefined;
  }
  return {
    value: glAccountNumber.value,
    description: glAccountNumber.description,
    fieldStatusGroup: glAccountNumber.fieldStatusGroup,
    fieldStatusGroupDescription: glAccountNumber.fieldStatusGroupDescription,
  };
};

export const getOrderNumberValue = (orderNumber) => {
  if (orderNumber == null) {
    return undefined;
  }
  return {
    value: orderNumber.value,
    description: orderNumber.description,
  };
};

export const getProductCodeValue = (productCode) => {
  if (productCode == null) {
    return undefined;
  }
  return {
    value: productCode.value,
    description: productCode.description,
    parent: productCode.parent,
    isParent: productCode.isParent,
  };
};

const emptyFieldToUndefined = (field) => {
  if (field == null) {
    return undefined;
  }
  const { value } = field;
  if (value == null || value.length === 0) {
    return undefined;
  }
  return field;
};

export const replaceEmptyBillingDataWithUndefined = (tilkkaRecord) => {
  return {
    ...tilkkaRecord,
    taxCode: emptyFieldToUndefined(tilkkaRecord.taxCode),
    productCode: emptyFieldToUndefined(tilkkaRecord.productCode),
    orderNumber: emptyFieldToUndefined(tilkkaRecord.orderNumber),
    glAccountNumber: emptyFieldToUndefined(tilkkaRecord.glAccountNumber),
    purchaseGroupNumber: emptyFieldToUndefined(
      tilkkaRecord.purchaseGroupNumber
    ),
    costCenter: emptyFieldToUndefined(tilkkaRecord.costCenter),
  };
};

export const tilkkaRecordsToOwnerList = (tilkkaRecords) => {
  const owners = tilkkaRecords
    .map((record) => record.owner)
    .filter((owner) => owner != null && owner.length > 0);
  const noDuplicateOwners = Array.from(new Set(owners));
  noDuplicateOwners.sort((a, b) => a.localeCompare(b));
  return noDuplicateOwners;
};

export const getTilkkaRecordStatus = (record, validationError) => {
  const { accountDeleted, accountInactive } = record;

  let status = StatusIconStatus.Ok;
  if (accountDeleted === true) {
    status = StatusIconStatus.Deleted;
  } else if (accountInactive === true) {
    status = StatusIconStatus.Inactive;
  } else if (validationError != null && validationError.length > 0) {
    status = StatusIconStatus.Warning;
  }
  return status;
};
