import { IconButton, CircularProgress, Tooltip } from "@mui/material";

const TooltipIconButton = ({
  tooltip,
  icon,
  loading,
  onClick,
  ...containerProps
}) => {
  return (
    <Tooltip title={tooltip || "-"}>
      <span>
        <IconButton
          sx={{ ...containerProps }}
          variant="contained"
          onClick={onClick}
          disabled={loading === true}
          color="primary"
        >
          {icon}
          {loading === true && (
            <CircularProgress sx={{ ml: 1, color: "primary.main" }} size={15} />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default TooltipIconButton;
