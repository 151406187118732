import { Box } from "@mui/material";
import AzureApplicationsTable from "./azureApplicationsTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAzureApplications } from "../../../redux/reducers/azureApplicationsReducer";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";

const AzureApplications = () => {
  const {
    azureApplications,
    fetchingAzureApplications,
    azureApplicationsFetchError,
  } = useSelector((state) => state.azureApplication);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAzureApplications());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (azureApplicationsFetchError != null) {
      dispatch(
        showSnackbar({
          message: azureApplicationsFetchError,
          severity: "error",
        }),
      );
    }
  }, [azureApplicationsFetchError, dispatch]);

  return (
    <Box>
      <AzureApplicationsTable
        fetching={fetchingAzureApplications}
        records={azureApplications}
      />
    </Box>
  );
};

export default AzureApplications;
