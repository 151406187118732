import objectPath from "object-path";

export const SortDirection = Object.freeze({
  Ascending: "asc",
  Descending: "desc",
});

const compareNumbers = (a, b) => {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
};

const compareFunc = (a, b, orderByProp) => {
  const aVal = objectPath.get(a, orderByProp);
  const bVal = objectPath.get(b, orderByProp);
  if (isNaN(aVal) === false && isNaN(bVal) === false) {
    return compareNumbers(aVal, bVal);
  }

  const safeA = aVal || "-";
  const safeB = bVal || "-";
  return String(safeA).localeCompare(String(safeB));
};

export const getComparator = (order, orderByProp) => {
  return order === SortDirection.Descending
    ? (a, b) => compareFunc(a, b, orderByProp)
    : (a, b) => -compareFunc(a, b, orderByProp);
};
