import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../loader/loader";
import { SortDirection, getComparator } from "../../../utils/table";
import SortableTableCell from "../../table/sortableTableCell";
import { createAzureApplicationTableRecord } from "../../../utils/azureApplication";

const SortProps = Object.freeze({
  DISPLAY_NAME: "displayName",
  SECRET_ONE_EXPIRE: "secretOneExpiresInDays",
  SECRET_TWO_EXPIRE: "secretTwoExpiresInDays",
});

const AzureApplicationsTable = ({ records, fetching, ...containerPops }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState(SortDirection.Ascending);
  const [orderByProp, setOrderByProp] = useState(SortProps.SECRET_ONE_EXPIRE);
  const [sortedRecords, setSortedRecords] = useState([]);

  useEffect(() => {
    const tableRecords = records.map(createAzureApplicationTableRecord);
    tableRecords.sort(getComparator(order, orderByProp));
    tableRecords.forEach((r) => console.log(r.displayName));
    setSortedRecords(tableRecords);
  }, [records, setSortedRecords]); // eslint-disable-line

  const handleRequestSort = (property) => {
    const isAsc = orderByProp === property && order === SortDirection.Ascending;
    const newOrder = isAsc ? SortDirection.Descending : SortDirection.Ascending;

    setOrder(newOrder);
    setOrderByProp(property);
    setSortedRecords(
      [...sortedRecords].sort(getComparator(newOrder, property)),
    );
  };

  const getName = (row) => {
    const { displayName, applicationId } = row;
    return (
      <Box>
        <Typography variant="body2" fontWeight="700">
          {displayName}
        </Typography>
        <Typography variant="body2">{applicationId}</Typography>
      </Box>
    );
  };

  return (
    <Box>
      <TableContainer component={Paper} sx={{ ...containerPops }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.DISPLAY_NAME}
                label={t("name")}
                onSort={handleRequestSort}
              />
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.SECRET_ONE_EXPIRE}
                label={t("secretOneExpiresInDays")}
                onSort={handleRequestSort}
              />
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.SECRET_TWO_EXPIRE}
                label={t("secretTwoExpiresInDays")}
                onSort={handleRequestSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {fetching === true && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {fetching === false && sortedRecords.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t("itemsNotFound")}
                </TableCell>
              </TableRow>
            )}
            {fetching === false &&
              sortedRecords.map((row, index) => {
                const aboutToExpire =
                  row.secretOneExpiresInDays < 45 ||
                  row.secretTwoExpiresInDays < 45;
                return (
                  <TableRow
                    key={`${row.objectId}_${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor:
                        aboutToExpire === true ? "#FF7961" : null,
                      "&:hover": {
                        backgroundColor: "#fafafa !important",
                      },
                    }}
                  >
                    <TableCell>{getName(row)}</TableCell>
                    <TableCell>{row.secretOneExpiresInDays || "-"}</TableCell>
                    <TableCell>{row.secretTwoExpiresInDays || "-"}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AzureApplicationsTable;
