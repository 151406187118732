const getExpiresInDays = (today, endDateTime) => {
  const expirationDate = new Date(endDateTime);
  return Math.round(
    (expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );
};

export const createAzureApplicationTableRecord = (record) => {
  const { secrets } = record;
  const today = new Date();
  const safeSecrets = secrets || [];

  const tableRecord = {
    ...record,
  };

  if (safeSecrets.length > 0) {
    const secret = safeSecrets[0];
    const expiresInDays = getExpiresInDays(today, secret.endDateTime);
    tableRecord.secretOneExpiresInDays = expiresInDays;
  }

  if (safeSecrets.length > 1) {
    const secret = safeSecrets[1];
    const expiresInDays = getExpiresInDays(today, secret.endDateTime);
    tableRecord.secretTwoExpiresInDays = expiresInDays;
  }

  return tableRecord;
};
