import {
  CurrencyCode,
  NEW_VAT_RATE,
  OLD_VAT_RATE,
  Service,
} from "../components/common";
import moment from "moment";

const Decimal = require("decimal.js");

export const sortRecordsByTime = (records) => {
  records.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  return records;
};

export const sortRecordsByName = (records) => {
  // accountNameCloud is a legacy field
  records.sort((a, b) => {
    const nameA = a.accountNameCloud || a.accountName || a.service;
    const nameB = b.accountNameCloud || b.accountName || b.service;
    return nameA.localeCompare(nameB);
  });
  return records;
};

export const initBillingRecordsMonthlyMap = () => {
  const map = new Map();

  // init 12 months
  for (let monthNumber = 1; monthNumber < 13; monthNumber += 1) {
    map.set(monthNumber, {
      totalCost: 0,
      preTaxTotalCost: 0,
      originalTotalCost: 0,
      preTaxOriginalTotalCost: 0,
      currency: CurrencyCode.EUR,
      originalCurrency: CurrencyCode.EUR,
      records: [],
    });
  }

  return map;
};

export const calculateTotalCosts = (records) => {
  const validRecords = records.filter((record) => record.cost != null);
  return validRecords.reduce(
    (acc, next) => {
      return {
        totalCost: new Decimal(acc.totalCost).plus(next.cost).toNumber(),
        preTaxTotalCost: new Decimal(acc.preTaxTotalCost)
          .plus(next.preTaxCost || next.cost)
          .toNumber(),
        originalTotalCost: new Decimal(acc.originalTotalCost)
          .plus(next.originalCost || next.cost)
          .toNumber(),
        preTaxOriginalTotalCost: new Decimal(acc.preTaxOriginalTotalCost)
          .plus(next.preTaxOriginalCost || next.originalCost || next.cost)
          .toNumber(),
        currency: acc.currency || next.currency,
        originalCurrency: acc.originalCurrency || next.originalCurrency,
      };
    },
    {
      totalCost: 0.0,
      preTaxTotalCost: 0.0,
      originalTotalCost: 0.0,
      preTaxOriginalTotalCost: 0.0,
    },
  );
};

const getVatRateFromRecords = (records) => {
  if (records.length === 0) {
    return "-";
  }

  const { vatRate, year, monthNumber } = records[0];
  return getVatRate(vatRate, year, monthNumber);
};

const getVatRate = (vatRate, year, monthNumber) => {
  if (vatRate != null && vatRate !== 1) {
    return vatRate;
  }

  // In finland VAT rate changed in September 2024
  if (monthNumber >= 9 && year >= 2024) {
    return NEW_VAT_RATE;
  }
  return OLD_VAT_RATE;
};

// TODO: this applies all the records before november 2024
export const calculatePreTaxAndVatCostForRecord = (record) => {
  const updatedRecord = { ...record };
  const { service, cost, originalCost, preTaxCost, year, monthNumber } =
    updatedRecord;

  // make sure that vat rate is found (older records don't have it)
  const vatRate = getVatRate(updatedRecord.vatRate, year, monthNumber);
  updatedRecord.vatRate = vatRate;

  // preTaxCost is not set
  if (preTaxCost == null) {
    const safeCost = cost || 0;
    const safeOriginalCost = originalCost || safeCost;
    if (service === Service.GCP) {
      // with GCP the cost is already without VAT
      updatedRecord.preTaxCost = safeCost;
      updatedRecord.cost = new Decimal(safeCost).mul(vatRate).toNumber();
      updatedRecord.preTaxOriginalCost = safeOriginalCost;
      updatedRecord.originalCost = new Decimal(safeOriginalCost)
        .mul(vatRate)
        .toNumber();
    } else {
      // With AWS and Azure the cost is with VAT
      updatedRecord.preTaxCost = new Decimal(safeCost).div(vatRate).toNumber();
      updatedRecord.preTaxOriginalCost = new Decimal(safeOriginalCost)
        .div(vatRate)
        .toNumber();
    }
  }

  return updatedRecord;
};

export const updateBillingHistoryByAccountRecords = (
  billingHistoryByAccountArray,
) => {
  return billingHistoryByAccountArray.map((billingHistoryByAccount) => {
    const { records, preTaxOverallCost, overallCost } = billingHistoryByAccount;
    const updatedRecords = records.map((record) =>
      calculatePreTaxAndVatCostForRecord(record),
    );

    let overallCostCalculated = overallCost;
    let preTaxOverallCostCalculated = preTaxOverallCost;
    // not provided on old records, calculate
    if (preTaxOverallCost == null || preTaxOverallCost === 0) {
      console.log(
        "PreTax cost not present, calculating for account",
        billingHistoryByAccount.accountName,
      );
      overallCostCalculated = updatedRecords.reduce((acc, next) => {
        return new Decimal(acc).plus(next.cost).toNumber();
      }, 0);
      preTaxOverallCostCalculated = updatedRecords.reduce((acc, next) => {
        return new Decimal(acc).plus(next.preTaxCost).toNumber();
      }, 0);
    }

    return {
      ...billingHistoryByAccount,
      records: updatedRecords,
      overallCost: overallCostCalculated,
      preTaxOverallCost: preTaxOverallCostCalculated,
    };
  });
};

export const updateBillingRecordsMonthlyMap = (map, newBillingRecords) => {
  const updateRecord = (records, newRecord) => {
    const index = records.findIndex((record) => record.id === newRecord.id);
    if (index !== -1) {
      records.splice(index, 1, newRecord);
    } else {
      records.push(newRecord);
    }

    const costs = calculateTotalCosts(records);
    return {
      records: sortRecordsByName(records),
      totalCost: costs.totalCost,
      preTaxTotalCost: costs.preTaxTotalCost,
      originalTotalCost: costs.originalTotalCost,
      preTaxOriginalTotalCost: costs.preTaxOriginalTotalCost,
      currency: costs.currency,
      originalCurrency: costs.originalCurrency,
    };
  };

  const updatedMap = newBillingRecords.reduce((acc, newRecord) => {
    const newRecordWithPreTax = calculatePreTaxAndVatCostForRecord(newRecord);
    const { monthNumber } = newRecordWithPreTax;
    const oldRecords = acc.get(monthNumber).records;
    const {
      records,
      totalCost,
      preTaxTotalCost,
      currency,
      originalTotalCost,
      preTaxOriginalTotalCost,
      originalCurrency,
    } = updateRecord(oldRecords, newRecordWithPreTax);
    acc.get(monthNumber).records = records;
    acc.get(monthNumber).totalCost = totalCost;
    acc.get(monthNumber).preTaxTotalCost = preTaxTotalCost;
    acc.get(monthNumber).originalTotalCost = originalTotalCost;
    acc.get(monthNumber).preTaxOriginalTotalCost = preTaxOriginalTotalCost;
    acc.get(monthNumber).currency = currency;
    acc.get(monthNumber).originalCurrency = originalCurrency;
    return acc;
  }, map);

  console.log("updatedMap", updatedMap);
  return updatedMap;
};

export const calculateSummaryFromMonthlyMap = (
  map,
  excludeZeroCostRecords = false,
) => {
  const result = Array.from(map.keys()).reduce(
    (acc, next) => {
      const monthlyEntry = map.get(next);

      console.log(monthlyEntry);

      return {
        yearlyCost: new Decimal(acc.yearlyCost)
          .plus(monthlyEntry.totalCost)
          .toNumber(),
        preTaxYearlyCost: new Decimal(acc.preTaxYearlyCost)
          .plus(monthlyEntry.preTaxTotalCost)
          .toNumber(),
        originalYearlyCost: new Decimal(acc.originalYearlyCost)
          .plus(monthlyEntry.originalTotalCost)
          .toNumber(),
        preTaxOriginalYearlyCost: new Decimal(acc.preTaxOriginalYearlyCost)
          .plus(monthlyEntry.preTaxOriginalTotalCost)
          .toNumber(),
        records: acc.records.concat(monthlyEntry.records),
        currency: acc.currency || monthlyEntry.currency,
        originalCurrency: acc.originalCurrency || monthlyEntry.originalCurrency,
      };
    },
    {
      yearlyCost: 0,
      preTaxYearlyCost: 0,
      originalYearlyCost: 0,
      preTaxOriginalYearlyCost: 0,
      records: [],
    },
  );

  let records = result.records;
  if (excludeZeroCostRecords === true) {
    records = filterZeroCostRecords(records);
  }
  const accountIds = records.map((r) => r.accountId);
  const uniqueAccountIds = Array.from(new Set(accountIds));

  return {
    yearlyCost: result.yearlyCost,
    preTaxYearlyCost: result.preTaxYearlyCost,
    originalYearlyCost: result.originalYearlyCost,
    preTaxOriginalYearlyCost: result.preTaxOriginalYearlyCost,
    accountCount: uniqueAccountIds.length,
    currency: result.currency,
    originalCurrency: result.originalCurrency,
    vatRate: getVatRateFromRecords(records), // There's weak part here if the VAT rate differs between services
  };
};

export const getCurrencyStr = (currencyCode) => {
  if (currencyCode === CurrencyCode.USD) {
    return "$";
  } else if (currencyCode === CurrencyCode.EUR) {
    return "€";
  }
  return "?";
};

export const getEarliestDateFromRecords = (records) => {
  if (records.length !== 0) {
    const sortedRecords = sortRecordsByTime(records);
    return moment(sortedRecords[sortedRecords.length - 1].createdAt);
  }
  return null;
};

export const getLatestDateFromRecords = (records) => {
  if (records.length !== 0) {
    const sortedRecords = sortRecordsByTime(records);
    return moment(sortedRecords[0].createdAt);
  }
  return null;
};

export const getUpdatedDateStr = (records) => {
  const date = getEarliestDateFromRecords(records);
  if (date != null) {
    return date.format("DD.MM.YYYY HH:mm:ss");
  }
  return "-";
};

export const isCompleteMonth = (year, monthNumber, compareTimeMoment) => {
  if (Number(year) < compareTimeMoment.year()) {
    return true;
  } else if (Number(year) === compareTimeMoment.year()) {
    return Number(monthNumber) < compareTimeMoment.month() + 1;
  }
};

export const isCompleteMonthForBillingAll = (year, monthNumber, records) => {
  const allServices = Object.keys(Service).length === records.length;
  let monthComplete = false;
  if (records.length) {
    monthComplete = isCompleteMonth(
      year,
      monthNumber,
      getEarliestDateFromRecords(records),
    );
  }
  return allServices && monthComplete;
};

export const isCompleteMonthForBillingByService = (
  year,
  monthNumber,
  records,
) => {
  if (records.length) {
    return isCompleteMonth(
      year,
      monthNumber,
      getEarliestDateFromRecords(records),
    );
  }
  return false;
};

export const isCompleteMontForReports = (year, monthNumber, records) => {
  if (records.length) {
    return isCompleteMonth(
      year,
      monthNumber,
      getLatestDateFromRecords(records),
    );
  }
  return false;
};

export const filterZeroCostRecords = (records) => {
  return records.filter(
    (record) => new Decimal(record.cost).toFixed(9) !== "0.000000000",
  );
};

export const isAws = (value) => {
  return value === Service.AWS || value === Service.AWS_OLD;
};
