const log = false;
const logger = (store) => (next) => (action) => {
  log && console.group(action.type);
  log && console.info('dispatching', action);
  let result = next(action);
  log && console.log('next state', store.getState());
  log && console.groupEnd();
  return result;
};

export default logger;
