import { Box, Typography } from "@mui/material";

const PageHeaderText = ({ text, ...containerProps }) => {
  return (
    <Box sx={{ ...containerProps }}>
      <Typography variant="h6">{text}</Typography>
    </Box>
  );
};

export default PageHeaderText;
