import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import YearSelect from "../filters/yearSelect";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDiscountCostByAccount,
  getDiscountCostByAccount,
} from "../../../redux/reducers/billingReducer";
import { Service } from "../../common";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import ServiceSelect from "../filters/serviceSelect";
import MonthSelect, { Months } from "../filters/monthSelect";
import CostByAccountTable from "./costByAccountTable";
import DiscountDetails from "./discountDetails";

const Discounts = ({ ...containerProps }) => {
  const [tableRecords, setTableRecords] = useState([]);
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(
    Months[moment().format("MMMM").toUpperCase()],
  );
  const dispatch = useDispatch();
  const {
    discountCostByAccount,
    discountDetails,
    fetchingDiscountCost,
    discountCostFetchError,
  } = useSelector((state) => state.billing);
  const [service, setService] = useState(Service.AWS);

  useEffect(() => {
    dispatch(clearDiscountCostByAccount());
    dispatch(getDiscountCostByAccount(service, year, month, true));
  }, []); // eslint-disable-line

  useEffect(() => {
    const tableRecords = [...discountCostByAccount];
    tableRecords.sort((a, b) => b.preTaxCost - a.preTaxCost);
    setTableRecords(tableRecords);
  }, [discountCostByAccount, setTableRecords]);

  useEffect(() => {
    if (discountCostFetchError != null) {
      dispatch(
        showSnackbar({
          message: discountCostFetchError,
          severity: "error",
        }),
      );
    }
  }, [discountCostFetchError, dispatch]);

  return (
    <Box sx={{ p: 2, ...containerProps }}>
      <Box sx={{ display: "flex" }}>
        <ServiceSelect
          service={service}
          serviceTypes={{
            [Service.AWS]: Service.AWS,
            [Service.GCP]: Service.GCP,
          }} // support only AWS, GCP for now
          onServiceSelected={(value) => {
            dispatch(clearDiscountCostByAccount());
            dispatch(getDiscountCostByAccount(value, year, month, true));
            setService(value);
          }}
        />
        <YearSelect
          mx={2}
          value={year}
          onSelect={(value) => {
            dispatch(clearDiscountCostByAccount());
            dispatch(getDiscountCostByAccount(service, value, month, true));
            setYear(value);
          }}
          minValue={2024}
        />
        <MonthSelect
          value={month}
          onSelect={(value) => {
            dispatch(clearDiscountCostByAccount());
            dispatch(getDiscountCostByAccount(service, year, value, true));
            setMonth(value);
          }}
          allowEmptyMonth={false}
        />
      </Box>
      <Box sx={{ my: 2 }}>
        <DiscountDetails my={2} discountDetails={discountDetails} />
        <CostByAccountTable
          tableRecords={tableRecords}
          fetching={fetchingDiscountCost}
        />
      </Box>
    </Box>
  );
};

Discounts.propTypes = {};

export default Discounts;
