import axios from "../../utils/axios";

const Types = {
  FETCH_LOG_TARGETS_START: "FETCH_LOG_TARGETS_START",
  FETCH_LOG_TARGETS_SUCCESS: "FETCH_LOG_TARGETS_SUCCESS",
  FETCH_LOG_TARGETS_FAILURE: "FETCH_LOG_TARGETS_FAILURE",
  FETCH_LOGS_START: "FETCH_LOGS_START",
  FETCH_LOGS_SUCCESS: "FETCH_LOGS_SUCCESS",
  FETCH_LOGS_FAILURE: "FETCH_LOGS_FAILURE",
  UPDATE_LOGS: "UPDATE_LOGS",
};

const API_URL = process.env.REACT_APP_API_URL;
const LOGS_API_URL = `${API_URL}admin/log`;
let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

export const fetchLogTargets = (service) => {
  return async (dispatch) => {
    try {
      console.log("fetchLogTargets", service);

      dispatch({ type: Types.FETCH_LOG_TARGETS_START });

      const response = await axios.get(`${LOGS_API_URL}/${service}/targets`, {
        signal: getAbortController().signal,
      });

      console.log("fetchLogTargets", response.data);

      dispatch({
        type: Types.FETCH_LOG_TARGETS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCH_LOG_TARGETS_FAILURE,
          payload: error.message,
        });
      }
    }
  };
};

export const fetchLogsForTarget = (service, target, fromDays) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCH_LOGS_START });

      let url = `${LOGS_API_URL}/${service}/${target}?fromDays=${fromDays}`;
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchLogs", response.data);

      const logRecords = response.data;
      dispatch({
        type: Types.UPDATE_LOGS,
        payload: logRecords,
      });

      dispatch({ type: Types.FETCH_LOGS_SUCCESS });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCH_LOGS_FAILURE,
          payload: error.message,
        });
      }
    }
  };
};

const INIT_STATE = {
  logs: [],
  fetchingLogs: false,
  logsFetchError: null,
  logTargets: [],
  fetchingLogTargets: false,
  logTargetsFetchError: null,
};

export const logsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_LOG_TARGETS_START:
      return {
        ...state,
        fetchingLogTargets: true,
        logTargetsFetchError: null,
      };
    case Types.FETCH_LOG_TARGETS_SUCCESS:
      return {
        ...state,
        fetchingLogTargets: false,
        logTargets: action.payload,
      };
    case Types.FETCH_LOG_TARGETS_FAILURE:
      return {
        ...state,
        fetchingLogTargets: false,
        logTargetsFetchError: action.payload,
      };
    case Types.FETCH_LOGS_START:
      return {
        ...state,
        fetchingLogs: true,
        logsFetchError: null,
      };
    case Types.FETCH_LOGS_SUCCESS:
      return {
        ...state,
        fetchingLogs: false,
      };
    case Types.FETCH_LOGS_FAILURE:
      return {
        ...state,
        fetchingLogs: false,
        logsFetchError: action.payload,
      };
    case Types.UPDATE_LOGS: {
      const logRecords = action.payload;
      return {
        ...state,
        logs: logRecords,
      };
    }
    default:
      return state;
  }
};
