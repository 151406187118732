import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import Authorized from "../../../auth/authorized";
import { Permission } from "../../../auth/permission";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getValidationErrorsStr,
  recipientStrToArray,
} from "../../../utils/virtualEmail";
import StatusIcon, {
  StatusIconStatus,
} from "../../billing/statusIcon/statusIcon";
import { validateVirtualEmailRecord } from "./validateVirtualEmailRecord";
import { getIsoDateStr } from "../../../utils/date";
import { SortDirection, getComparator } from "../../../utils/table";
import SortableTableCell from "../../table/sortableTableCell";
import TooltipIconButton from "../../tooltipIconButton/tooltipIconButton";
import AccountOwner from "../../billing/tilkka/accountOwner";

const SortProps = Object.freeze({
  STATUS: "status",
  VIRTUAL_EMAIL: "id",
  RECIPIENTS: "recipients",
  OWNER: "owner",
  MANAGED: "managed",
  CREATED: "createdAt",
  MODIFIED: "modifiedAt",
});

const VirtualEmailTable = ({
  records,
  onEditRow,
  onDeleteRow,
  disableEdit,
  configuration,
  hideStatus,
  hideActions,
  ...containerPops
}) => {
  const { t } = useTranslation();
  const { userData } = useSelector((state) => state.user);
  const [sortedRecords, setSortedRecords] = useState([]);
  const [order, setOrder] = useState(SortDirection.Descending);
  const [orderByProp, setOrderByProp] = useState(SortProps.ACCOUNT_NAME);

  useEffect(() => {
    const getStatus = (record, validationErrorMap) => {
      if (record.deleted === true) {
        return {
          status: StatusIconStatus.Deleted,
        };
      }
      const status =
        validationErrorMap.size === 0
          ? StatusIconStatus.Ok
          : StatusIconStatus.Warning;
      return {
        status,
        statusError: getValidationErrorsStr(validationErrorMap),
      };
    };

    setSortedRecords(
      records.map((record) => {
        const validationErrorMap = validateVirtualEmailRecord(
          record,
          configuration,
          []
        );
        const result = getStatus(record, validationErrorMap);
        return {
          ...record,
          status: result.status,
          statusError: result.statusError,
        };
      })
    );
  }, [records, configuration, setSortedRecords]);

  const handleRequestSort = (property) => {
    const isAsc = orderByProp === property && order === SortDirection.Ascending;
    const newOrder = isAsc ? SortDirection.Descending : SortDirection.Ascending;
    setOrder(newOrder);
    setOrderByProp(property);
    setSortedRecords(
      [...sortedRecords].sort(getComparator(newOrder, property))
    );
  };

  const getOriginalRecordById = (id) => {
    return records.find((record) => record.id === id);
  };

  return (
    <TableContainer component={Paper} sx={{ ...containerPops }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {hideStatus !== true && (
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.STATUS}
                label={t("status")}
                onSort={handleRequestSort}
              />
            )}
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.VIRTUAL_EMAIL}
              label={t("virtualEmail")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.RECIPIENTS}
              label={t("recipients")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.OWNER}
              label={t("ownerEmail")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.MANAGED}
              label={t("managed")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.MODIFIED}
              label={t("modified")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.CREATED}
              label={t("created")}
              onSort={handleRequestSort}
            />
            {hideActions !== true && (
              <TableCell sx={{ fontWeight: "bold" }}>{t("actions")}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRecords.length === 0 && (
            <TableRow>
              <TableCell colSpan={8} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {(sortedRecords || []).map((row) => {
            const {
              id,
              createdAt,
              modifiedAt,
              managed,
              owner,
              recipients,
              invalidRecipients,
              status,
              statusError,
              deleted,
            } = row;
            const recipientsArray = recipientStrToArray(recipients);
            const invalidRecipientsArray =
              recipientStrToArray(invalidRecipients);
            return (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: deleted === true ? "lightgrey" : null,
                }}
              >
                {hideStatus !== true && (
                  <TableCell>
                    <StatusIcon
                      status={status}
                      okTooltip={t("virtualEmailStatusOk")}
                      warningTooltip={statusError}
                      deletedTooltip={t("virtualEmailDeleted")}
                    />
                  </TableCell>
                )}
                <TableCell component="th" scope="row">
                  {id}
                </TableCell>
                <TableCell>
                  {recipientsArray.map((recipient) => {
                    return (
                      <AccountOwner
                        key={recipient}
                        owner={recipient}
                        ownerInvalid={invalidRecipientsArray.includes(
                          recipient
                        )}
                      />
                    );
                  })}
                </TableCell>
                <TableCell>{owner}</TableCell>
                <TableCell>{String(managed)}</TableCell>
                <TableCell>{getIsoDateStr(modifiedAt)}</TableCell>
                <TableCell>{getIsoDateStr(createdAt)}</TableCell>
                {hideActions !== true && (
                  <TableCell>
                    {deleted !== true && (
                      <>
                        <Authorized
                          user={userData}
                          permission={Permission.VIRTUAL_EMAIL_EDIT}
                        >
                          <TooltipIconButton
                            tooltip={t("edit")}
                            icon={<EditIcon />}
                            onClick={(e) => {
                              e.stopPropagation();
                              onEditRow(getOriginalRecordById(row.id));
                            }}
                            disabled={disableEdit === true}
                          />
                        </Authorized>
                        <Authorized
                          user={userData}
                          permission={Permission.VIRTUAL_EMAIL_DELETE}
                        >
                          <TooltipIconButton
                            tooltip={t("delete")}
                            icon={<DeleteIcon />}
                            onClick={() =>
                              onDeleteRow(getOriginalRecordById(row.id))
                            }
                          />
                        </Authorized>
                      </>
                    )}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

VirtualEmailTable.propTypes = {
  records: PropTypes.array,
  disableEdit: PropTypes.bool,
};

export default VirtualEmailTable;
