import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CurrencyCode } from "../../common";

const CurrencySelect = ({ value, onSelect, disabled, ...containerProps }) => {
  return (
    <ToggleButtonGroup
      sx={{ ...containerProps }}
      size="small"
      value={value}
      exclusive
      onChange={(_e, newValue) => onSelect(newValue)}
      disabled={disabled}
    >
      <ToggleButton value={CurrencyCode.EUR}>{CurrencyCode.EUR}</ToggleButton>
      <ToggleButton value={CurrencyCode.USD}>{CurrencyCode.USD}</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CurrencySelect;
