import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchCostCenters } from "../../../redux/reducers/sapBillingDataReducer";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import moment from "moment";
import LoadMoreButton from "../../loadMoreButton/loadMoreButton";

const CostCenterTable = ({ ...containerPops }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    costCenters,
    fetchingCostCenters,
    costCentersFetchError,
    costCenterCursor,
  } = useSelector((state) => state.sapBilling);

  useEffect(() => {
    dispatch(fetchCostCenters());
  }, [dispatch]);

  // Error notifications
  useEffect(() => {
    if (costCentersFetchError != null) {
      dispatch(
        showSnackbar({ message: costCentersFetchError, severity: "error" })
      );
    }
  }, [costCentersFetchError, dispatch]);

  const records = costCenters || [];
  return (
    <Box>
      <TableContainer component={Paper} sx={{ ...containerPops }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("costCentreNumber")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("companyCode")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("description")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("updated")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchingCostCenters === true && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {fetchingCostCenters === false && records.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t("itemsNotFound")}
                </TableCell>
              </TableRow>
            )}
            {fetchingCostCenters === false &&
              records.map((row, index) => {
                return (
                  <TableRow
                    key={`${row.value}_${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{row.value}</TableCell>
                    <TableCell>{row.companyCode}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadMoreButton
        visible={costCenterCursor != null}
        onClick={() => dispatch(fetchCostCenters(costCenterCursor))}
        loading={fetchingCostCenters}
      />
    </Box>
  );
};

CostCenterTable.propTypes = {};

export default CostCenterTable;
