import { hasPermission } from "./permission";

const Authorized = ({ user, permission, unauthorizedView, children }) => {
  if (hasPermission(user, permission)) {
    return children;
  }
  return unauthorizedView;
};

export default Authorized;
