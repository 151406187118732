import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ServiceSelect from "../../billing/filters/serviceSelect";
import { Service } from "../../common";
import DynamicSelectList from "../../dynamicSelectList/dynamicSelectList";
import {
  fetchBillingAccountNumbers,
  fetchCostCenters,
  fetchInternalOrderNumbers,
  fetchProductCodes,
  fetchPurchaseGroupNumbers,
  fetchTaxCodes,
} from "../../../redux/reducers/sapBillingDataReducer";
import { useDispatch } from "react-redux";
import {
  validateTilkkaRecord,
  TilkkaRecordProps,
} from "./validateTilkkaRecord";
import {
  getCostCenterValue,
  getGlAccountNumberValue,
  getOrderNumberValue,
  getProductCodeValue,
  getPurchaseGroupNumberValue,
  getTaxCodeValue,
  replaceEmptyBillingDataWithUndefined,
} from "../../../utils/tilkka";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";

const EditTilkkaRecordDialog = ({
  record,
  title,
  open,
  onClose,
  onSave,
  saving,
  existingAccountIds,
  ...containerProps
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editedRecord, setEditedRecord] = useState(null);
  const [validationErrorMap, setValidationErrorMap] = useState(new Map());
  const [costCenters, setCostCenters] = useState([]);
  const [internalOrderNumbers, setInternalOrderNumbers] = useState([]);
  const [taxCodes, setTaxCodes] = useState([]);
  const [billingAccountNumbers, setBillingAccountNumbers] = useState([]);
  const [purchaseGroupNumbers, setPurchaseGroupNumbers] = useState([]);
  const [productCodes, setProductCodes] = useState([]);

  useEffect(() => {
    // prune record for validation
    setEditedRecord(replaceEmptyBillingDataWithUndefined(record));
  }, [record, setEditedRecord]);

  if (editedRecord == null) {
    return null;
  }

  const showErrorSnackbar = (message) => {
    dispatch(
      showSnackbar({
        message,
        severity: "error",
      })
    );
  };

  const handleSave = () => {
    const validationErrors = validateTilkkaRecord(
      editedRecord,
      existingAccountIds
    );

    if (validationErrors.size === 0) {
      onSave(editedRecord);
      setValidationErrorMap(new Map());
    } else {
      setValidationErrorMap(validationErrors);
    }
  };

  const handleClose = () => {
    setValidationErrorMap(new Map());
    onClose();
  };

  const getService = () => {
    if (editedRecord.service != null) {
      return editedRecord.service.toUpperCase();
    }
    return "";
  };

  const getCostCenters = async () => {
    let items = costCenters;
    if (items.length === 0) {
      try {
        const restul = await dispatch(fetchCostCenters());
        items = restul.items;
        setCostCenters(items);
      } catch (error) {
        showErrorSnackbar(error.message);
      }
    }

    return items;
  };

  const getInternalOrderNumbers = async () => {
    let items = internalOrderNumbers;
    if (items.length === 0) {
      let result = { cursor: null };
      do {
        try {
          result = await dispatch(fetchInternalOrderNumbers(result.cursor));
          items = [...items, ...result.items];
        } catch (error) {
          showErrorSnackbar(error.message);
          break;
        }
      } while (result.cursor != null);
      console.log("getInternalOrderNumbers - count", items.length);
      setInternalOrderNumbers(items);
    }

    return items;
  };

  const getTaxCodes = async () => {
    let items = taxCodes;
    if (items.length === 0) {
      try {
        const restul = await dispatch(fetchTaxCodes());
        items = restul.items;
        setTaxCodes(items);
      } catch (error) {
        showErrorSnackbar(error.message);
      }
    }

    return items;
  };

  const getBillingAccountNumbers = async () => {
    let items = billingAccountNumbers;
    if (items.length === 0) {
      try {
        const restul = await dispatch(fetchBillingAccountNumbers());
        items = restul.items.filter((item) => item.value != null);
        setBillingAccountNumbers(items);
      } catch (error) {
        showErrorSnackbar(error.message);
      }
    }

    return items;
  };

  const getPurchaseGroupNumbers = async () => {
    let items = purchaseGroupNumbers;
    if (items.length === 0) {
      try {
        const restul = await dispatch(fetchPurchaseGroupNumbers());
        items = restul.items;
        setPurchaseGroupNumbers(items);
      } catch (error) {
        showErrorSnackbar(error.message);
      }
    }

    return items;
  };

  const getProductCodes = async () => {
    let items = productCodes;
    if (items.length === 0) {
      try {
        const restul = await dispatch(fetchProductCodes());
        items = restul.items;
        setProductCodes(items);
      } catch (error) {
        showErrorSnackbar(error.message);
      }
    }
    return items.filter((item) => item.isParent === false);
  };

  const renderOption = (option) => {
    // console.log("option", option);
    if (typeof option === "string") {
      return option;
    }

    const { description, value, fieldStatusGroup } = option;
    if (value != null && value.length === 0) {
      return "";
    }
    let str = `${description} (${value})`;
    if (fieldStatusGroup != null) {
      str = `${str} (${fieldStatusGroup})`;
    }

    return str;
  };

  const isError = (key) => {
    return validationErrorMap.has(key);
  };

  const getErrorText = (key) => {
    return validationErrorMap.get(key);
  };

  return (
    <Box sx={{ ...containerProps }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <ServiceSelect
            mt={2}
            minWidth="100px"
            service={getService()}
            serviceTypes={Service}
            onServiceSelected={(value) =>
              setEditedRecord({ ...editedRecord, service: value })
            }
            disabled={record.accountId != null}
            error={isError(TilkkaRecordProps.SERVICE)}
            helperText={getErrorText(TilkkaRecordProps.SERVICE)}
          />
          <TextField
            sx={{ width: "100%", mt: 2 }}
            margin="dense"
            id="accountId"
            label={t("accountId")}
            variant="standard"
            value={editedRecord.accountId || ""}
            onChange={(e) =>
              setEditedRecord({ ...editedRecord, accountId: e.target.value })
            }
            disabled={record.accountId != null}
            error={isError(TilkkaRecordProps.ACCOUNT_ID)}
            helperText={
              isError(TilkkaRecordProps.ACCOUNT_ID)
                ? `${getErrorText(TilkkaRecordProps.ACCOUNT_ID)} ${t(
                    "orIsAlreadyExisting"
                  ).toLocaleLowerCase()}`
                : null
            }
          />
          <TextField
            sx={{ width: "100%", mt: 2 }}
            autoFocus
            margin="dense"
            id="accountName"
            label={t("accountName")}
            variant="standard"
            value={editedRecord.accountName || ""}
            onChange={(e) =>
              setEditedRecord({ ...editedRecord, accountName: e.target.value })
            }
            error={isError(TilkkaRecordProps.ACCOUNT_NAME)}
            helperText={getErrorText(TilkkaRecordProps.ACCOUNT_NAME)}
          />
          <TextField
            sx={{ width: "100%", mt: 2 }}
            margin="dense"
            id="owner"
            label={t("description")}
            variant="standard"
            value={editedRecord.description || ""}
            onChange={(e) =>
              setEditedRecord({ ...editedRecord, description: e.target.value })
            }
            error={isError(TilkkaRecordProps.DESCRIPTION)}
            helperText={getErrorText(TilkkaRecordProps.DESCRIPTION)}
          />
          <TextField
            sx={{ width: "100%", mt: 2 }}
            margin="dense"
            id="owner"
            label={t("ownerEmail")}
            variant="standard"
            value={editedRecord.owner || ""}
            onChange={(e) =>
              setEditedRecord({ ...editedRecord, owner: e.target.value })
            }
            error={isError(TilkkaRecordProps.OWNER)}
            helperText={getErrorText(TilkkaRecordProps.OWNER)}
          />
          <DynamicSelectList
            mt={4}
            selectedOption={editedRecord.taxCode || ""}
            onOptionSelected={(taxCode) => {
              setEditedRecord({
                ...editedRecord,
                taxCode: getTaxCodeValue(taxCode),
              });
            }}
            onRenderOption={renderOption}
            onLoadOptions={getTaxCodes}
            label={t("taxCode")}
            error={isError(TilkkaRecordProps.TAX_CODE)}
            helperText={getErrorText(TilkkaRecordProps.TAX_CODE)}
          />
          <DynamicSelectList
            mt={4}
            selectedOption={editedRecord.costCenter || ""}
            onOptionSelected={(costCenter) => {
              setEditedRecord({
                ...editedRecord,
                costCenter: getCostCenterValue(costCenter),
              });
            }}
            onRenderOption={renderOption}
            onLoadOptions={getCostCenters}
            label={t("costCenter")}
            error={isError(TilkkaRecordProps.COST_CENTER)}
            helperText={getErrorText(TilkkaRecordProps.COST_CENTER)}
          />
          <DynamicSelectList
            mt={4}
            selectedOption={editedRecord.purchaseGroupNumber || ""}
            onOptionSelected={(purchaseGroupNumber) => {
              setEditedRecord({
                ...editedRecord,
                purchaseGroupNumber:
                  getPurchaseGroupNumberValue(purchaseGroupNumber),
              });
            }}
            onRenderOption={renderOption}
            onLoadOptions={getPurchaseGroupNumbers}
            label={t("purchaseGroupNumber")}
            error={isError(TilkkaRecordProps.PURCHASE_GROUP_NUMBER)}
            helperText={getErrorText(TilkkaRecordProps.PURCHASE_GROUP_NUMBER)}
          />
          <DynamicSelectList
            mt={4}
            selectedOption={editedRecord.glAccountNumber || ""}
            onOptionSelected={(glAccountNumber) => {
              setEditedRecord({
                ...editedRecord,
                glAccountNumber: getGlAccountNumberValue(glAccountNumber),
              });
            }}
            onRenderOption={renderOption}
            onLoadOptions={getBillingAccountNumbers}
            label={t("glAccountNumber")}
            error={isError(TilkkaRecordProps.GL_ACCOUNT_NUMBER)}
            helperText={getErrorText(TilkkaRecordProps.GL_ACCOUNT_NUMBER)}
          />
          <DynamicSelectList
            mt={4}
            selectedOption={editedRecord.orderNumber || ""}
            onOptionSelected={(orderNumber) => {
              setEditedRecord({
                ...editedRecord,
                orderNumber: getOrderNumberValue(orderNumber),
              });
            }}
            onRenderOption={renderOption}
            onLoadOptions={getInternalOrderNumbers}
            label={t("internalOrderNumber")}
            error={isError(TilkkaRecordProps.ORDER_NUMBER)}
            helperText={getErrorText(TilkkaRecordProps.ORDER_NUMBER)}
          />
          <DynamicSelectList
            mt={4}
            selectedOption={editedRecord.productCode || ""}
            onOptionSelected={(productCode) => {
              setEditedRecord({
                ...editedRecord,
                productCode: getProductCodeValue(productCode),
              });
            }}
            onRenderOption={renderOption}
            onLoadOptions={getProductCodes}
            label={t("productCode")}
            error={isError(TilkkaRecordProps.PRODUCT_CODE)}
            helperText={getErrorText(TilkkaRecordProps.PRODUCT_CODE)}
          />
          <Alert severity="info" sx={{ my: 4 }}>
            {t("createTilkkaRecordInfo")}
          </Alert>
        </DialogContent>
        <DialogActions>
          {saving === true && <CircularProgress size={20} sx={{ mr: 1 }} />}
          <Button
            variant="contained"
            disabled={saving === true}
            onClick={() => handleSave()}
          >
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            disabled={saving === true}
            onClick={handleClose}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

EditTilkkaRecordDialog.propTypes = {
  record: PropTypes.object,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
};

EditTilkkaRecordDialog.defaultProps = {
  record: {},
  disabled: false,
  saving: false,
};

export default EditTilkkaRecordDialog;
