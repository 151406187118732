import { Box, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const DocLeaf = ({ entry, ...containerProps }) => {
  return (
    <Paper sx={{ p: 2, ...containerProps }}>
      <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
        {entry.name}
      </Typography>
      {entry.documents.map((doc) => {
        return (
          <Box key={doc.id} sx={{ mb: 1, color: "blue" }}>
            <Link
              to={`/docs/${encodeURIComponent(doc.key)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {doc.name}
            </Link>
          </Box>
        );
      })}
    </Paper>
  );
};

export default DocLeaf;
