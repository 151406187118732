import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const LabelCheckbox = ({
  label,
  value,
  onChange,
  disabled,
  ...containerProps
}) => {
  return (
    <FormGroup sx={{ ...containerProps }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value === true}
            onChange={(e) => onChange(e.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );
};

export default LabelCheckbox;
