import { isEmptyString } from "../../../utils/tilkka";

const CostCenterStr = ({ objectValue }) => {
  // sanity
  if (objectValue == null) {
    return "-";
  }

  const { value, description } = objectValue;
  let valueWithoutZeroes = Number(value || 0);

  if (isEmptyString(description)) {
    return valueWithoutZeroes;
  }
  return `${description} (${valueWithoutZeroes})`;
};

export default CostCenterStr;
