import { Button, CircularProgress, Tooltip } from "@mui/material";

const TooltipButton = ({
  tooltip,
  buttonText,
  loading,
  onClick,
  ...containerProps
}) => {
  return (
    <Tooltip title={tooltip || buttonText}>
      <span>
        <Button
          sx={{ ...containerProps }}
          variant="contained"
          onClick={onClick}
          disabled={loading === true}
        >
          {buttonText}
          {loading === true && (
            <CircularProgress sx={{ ml: 1, color: "primary.main" }} size={15} />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export default TooltipButton;
