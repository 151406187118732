import React, { useEffect, useState } from "react";
import { Alert, Box, Button } from "@mui/material";
import {
  createNewAppClient,
  fetchAppClients,
  fetchAvailableScopes,
  deleteAppClient,
  updateAppClientScopes,
  fetchAppClient,
} from "../../../redux/reducers/adminReducer";
import { useDispatch, useSelector } from "react-redux";
import AppClientEntry from "./appClientEntry";
import { useTranslation } from "react-i18next";
import EditAppClientDialog from "./editAppClientDialog";
import ConfirmDialog from "../../confirmDialog/confirmDialog";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import Loader from "../../loader/loader";

const DEFAULT_CONFIRM_DIALOG_DATA = {
  open: false,
  ClientName: "",
  ClientId: "",
};

const DEFAULT_EDIT_DIALOG_DATA = {
  open: false,
};

const ExternalClients = ({ ...containerPops }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    appClients,
    fetchingAppClientMap,
    sendingAppClient,
    appClientsFetchingError,
    appClientFetchingError,
    appClientSendingError,
    scopeFetchingError,
    fetchingAppClients,
  } = useSelector((state) => state.admin);
  const [editDialogData, setEditDialogData] = useState(
    DEFAULT_EDIT_DIALOG_DATA,
  );
  const [confirmDialogData, setConfirmDialogData] = useState(
    DEFAULT_CONFIRM_DIALOG_DATA,
  );

  useEffect(() => {
    dispatch(fetchAppClients());
    dispatch(fetchAvailableScopes());
  }, []); // eslint-disable-line

  // Error notifications
  useEffect(() => {
    console.log(
      "useEffect",
      appClientsFetchingError,
      appClientFetchingError,
      appClientSendingError,
      scopeFetchingError,
    );

    if (appClientsFetchingError != null) {
      dispatch(
        showSnackbar({ message: appClientsFetchingError, severity: "error" }),
      );
    } else if (appClientFetchingError != null) {
      dispatch(
        showSnackbar({ message: appClientFetchingError, severity: "error" }),
      );
    } else if (appClientSendingError != null) {
      dispatch(
        showSnackbar({ message: appClientSendingError, severity: "error" }),
      );
    } else if (scopeFetchingError != null) {
      dispatch(
        showSnackbar({ message: scopeFetchingError, severity: "error" }),
      );
    }
  }, [
    appClientsFetchingError,
    appClientFetchingError,
    appClientSendingError,
    scopeFetchingError,
    dispatch,
  ]);

  const { open, clientName, clientId, scopes } = editDialogData;

  return (
    <Box
      sx={{
        p: 2,
        ...containerPops,
      }}
    >
      <Alert sx={{ my: 2 }} severity="info">
        {t("externalClientInfo")}
      </Alert>
      <Button
        sx={{ mb: 2 }}
        variant="contained"
        onClick={() => {
          setEditDialogData({
            open: true,
            title: t("createNewClientTitle"),
          });
        }}
      >
        {t("createNewClient")}
      </Button>
      <EditAppClientDialog
        appClientData={{ clientName, clientId, scopes }}
        title={t("createNewClientTitle")}
        saving={sendingAppClient}
        open={open}
        onClose={() => {
          setEditDialogData(DEFAULT_EDIT_DIALOG_DATA);
        }}
        onSave={({ clientName, clientId, scopes }) => {
          // Is this existing client that is being modified?
          if (clientId != null) {
            dispatch(
              updateAppClientScopes(
                clientId,
                scopes.map((sc) => sc.value),
              ),
            ).then(() => {
              setEditDialogData(DEFAULT_EDIT_DIALOG_DATA);
            });
          } else {
            dispatch(
              createNewAppClient(
                clientName,
                scopes.map((sc) => sc.value),
              ),
            ).then(() => {
              setEditDialogData(DEFAULT_EDIT_DIALOG_DATA);
            });
          }
        }}
      />
      <ConfirmDialog
        open={confirmDialogData.open}
        loading={sendingAppClient === true}
        title={`${t("delete")} ${confirmDialogData.ClientName}?`}
        body={t("deleteAppClientInfo")}
        onClose={(result) => {
          if (result === true) {
            dispatch(deleteAppClient(confirmDialogData.ClientId)).then(() => {
              setConfirmDialogData(DEFAULT_CONFIRM_DIALOG_DATA);
            });
          } else {
            setConfirmDialogData(DEFAULT_CONFIRM_DIALOG_DATA);
          }
        }}
      />
      {fetchingAppClients === true && <Loader />}
      {appClients.map((appClient) => {
        const { ClientId } = appClient;
        return (
          <AppClientEntry
            mb={2}
            key={ClientId}
            appClient={appClient}
            fetchingDetails={fetchingAppClientMap.get(ClientId) === true}
            onEdit={(client) => {
              const { ClientId, ClientName } = client;
              // Fetch full details for edit since the list does not have the scopes
              dispatch(fetchAppClient(ClientId)).then((appClientData) => {
                const { AllowedOAuthScopes } = appClientData;
                setEditDialogData({
                  open: true,
                  clientName: ClientName,
                  clientId: ClientId,
                  scopes: AllowedOAuthScopes.map((fullScope) => {
                    const split = fullScope.split("/");
                    return split[1];
                  }),
                });
              });
            }}
            onDelete={({ ClientName, ClientId }) => {
              setConfirmDialogData({
                open: true,
                ClientName: ClientName,
                ClientId: ClientId,
              });
            }}
          />
        );
      })}
    </Box>
  );
};

ExternalClients.propTypes = {};

export default ExternalClients;
