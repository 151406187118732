import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxCodes } from "../../../redux/reducers/sapBillingDataReducer";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import moment from "moment";

const TaxCodeTable = ({ ...containerPops }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { taxCodes, fetchingTaxCodes, taxCodesFetchError } = useSelector(
    (state) => state.sapBilling
  );

  useEffect(() => {
    dispatch(fetchTaxCodes());
  }, [dispatch]);

  // Error notifications
  useEffect(() => {
    if (taxCodesFetchError != null) {
      dispatch(
        showSnackbar({ message: taxCodesFetchError, severity: "error" })
      );
    }
  }, [taxCodesFetchError, dispatch]);

  const records = taxCodes || [];
  return (
    <TableContainer component={Paper} sx={{ ...containerPops }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>{t("taxCode")}</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("description")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>{t("updated")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchingTaxCodes === true && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <Loader />
              </TableCell>
            </TableRow>
          )}
          {fetchingTaxCodes === false && records.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {fetchingTaxCodes === false &&
            records.map((row, index) => {
              const value = row.value || row.taxCode;
              return (
                <TableRow
                  key={`${value}_${index}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{value}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    {moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TaxCodeTable.propTypes = {};

export default TaxCodeTable;
