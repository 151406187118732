import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const ConfirmDialog = ({
  open,
  title,
  body,
  onClose,
  loading,
  ...containerPops
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ ...containerPops }}>
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading === true && (
            <CircularProgress
              sx={{ mr: 1, color: "primary.light" }}
              size={25}
            />
          )}
          <Button
            size="small"
            variant="contained"
            onClick={() => onClose(true)}
            disabled={loading === true}
          >
            {t("yes")}
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => onClose(false)}
            disabled={loading === true}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
};

export default ConfirmDialog;
