import { createTheme } from "@mui/material/styles";
import { PrimaryBlue, White } from "./colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: PrimaryBlue,
      contrastText: White,
    },
    secondary: {
      main: White,
      contrastText: PrimaryBlue,
    },
  },
});

export default theme;
