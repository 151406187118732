import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import Loader from "../../loader/loader";
import {
  deleteVirtualEmail,
  fetchVirtualEmails,
  saveVirtualEmail,
} from "../../../redux/reducers/virtualEmailReducer";
import VirtualEmailTable from "./virtualEmailTable";
import Authorized from "../../../auth/authorized";
import { filterVirtualEmailRecords } from "../../../utils/virtualEmail";
import { Permission } from "../../../auth/permission";
import EditVirtualEmailRecordDialog from "./editVirtualEmailRecordDialog";
import ConfirmDialog from "../../confirmDialog/confirmDialog";
import LabelCheckbox from "../../labelCheckbox/labelCheckbox";

const EMPTY_DIALOG = {
  open: false,
};

const VirtualEmailRecords = ({ ...containerProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const {
    virtualEmails,
    fetchingVirtualEmail,
    virtualEmailFetchingError,
    sendingVirtualEmail,
    virtualEmailSendError,
    virtualEmailConfiguration,
    fetchingVirtualEmailConfiguration,
  } = useSelector((state) => state.virtualEmail);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filterText, setFilterText] = useState(null);
  const [dialogData, setDialogData] = useState(EMPTY_DIALOG);
  const [confirmDialogData, setConfirmDialogData] = useState(EMPTY_DIALOG);
  const [hideDeleted, setHideDeleted] = useState(true);

  useEffect(() => {
    dispatch(fetchVirtualEmails());
  }, []); // eslint-disable-line

  useEffect(() => {
    setFilteredRecords(virtualEmails);
  }, [virtualEmails, setFilteredRecords]);

  useEffect(() => {
    let records = virtualEmails;

    if (hideDeleted) {
      records = records.filter((record) => record.deleted !== true);
    }

    if (filterText != null) {
      records = filterVirtualEmailRecords(filterText, records);
    }

    setFilteredRecords(records);
  }, [filterText, hideDeleted, virtualEmails, setFilteredRecords]);

  // Error notifications
  useEffect(() => {
    if (virtualEmailFetchingError != null) {
      dispatch(
        showSnackbar({
          message: virtualEmailFetchingError,
          severity: "error",
        })
      );
    }
  }, [virtualEmailFetchingError, dispatch]);

  useEffect(() => {
    if (virtualEmailSendError != null) {
      dispatch(
        showSnackbar({
          message: virtualEmailSendError,
          severity: "error",
        })
      );
    }
  }, [virtualEmailSendError, dispatch]);

  const saveRecord = (data = null) => {
    setDialogData({
      open: true,
      record: data || {},
      title: data == null ? t("createNewVirtualEmail") : t("editVirtualEmail"),
      onClose: () => setDialogData(EMPTY_DIALOG),
      onSave: (record) => {
        dispatch(saveVirtualEmail(record))
          .then(() => {
            setDialogData(EMPTY_DIALOG);
          })
          .catch((err) => console.error(err));
      },
      // If creating new one, pass existing to prevent creating new one with existing vmail id
      existingVirtualEmails: data == null ? virtualEmails : [],
    });
  };

  const deleteRecord = (record) => {
    setConfirmDialogData({
      open: true,
      title: t("deleteVirtualEmail"),
      body: `${record.id}`,
      onClose: (result) => {
        if (result === true) {
          dispatch(deleteVirtualEmail(record.id))
            .then(() => {
              setConfirmDialogData(EMPTY_DIALOG);
            })
            .catch((err) => console.log(err));
        } else {
          setConfirmDialogData(EMPTY_DIALOG);
        }
      },
    });
  };

  const disableEdit = fetchingVirtualEmailConfiguration === true;
  return (
    <Box sx={{ py: 2, ...containerProps }}>
      <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
        <Authorized user={userData} permission={Permission.VIRTUAL_EMAIL_EDIT}>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => saveRecord()}
            disabled={disableEdit}
          >
            {t("addNew")}
          </Button>
        </Authorized>
        <TextField
          sx={{ mr: 2, minWidth: 500 }}
          label={t("filter")}
          placeholder={t("tilkkaFilterPlaceholder")}
          size="small"
          variant="outlined"
          value={filterText || ""}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <LabelCheckbox
          value={hideDeleted}
          onChange={setHideDeleted}
          label={t("hideDeleted")}
        />
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography variant="subtitle1">
          {`${filteredRecords.length} ${t("records").toLocaleLowerCase()}`}
        </Typography>
      </Box>
      {fetchingVirtualEmail === true && <Loader />}
      <VirtualEmailTable
        records={filteredRecords}
        onEditRow={saveRecord}
        onDeleteRow={deleteRecord}
        disableEdit={disableEdit}
        configuration={virtualEmailConfiguration}
      />
      <EditVirtualEmailRecordDialog
        {...dialogData}
        saving={sendingVirtualEmail}
        configuration={virtualEmailConfiguration}
      />
      <ConfirmDialog
        {...confirmDialogData}
        loading={sendingVirtualEmail === true}
      />
    </Box>
  );
};

VirtualEmailRecords.propTypes = {};

export default VirtualEmailRecords;
