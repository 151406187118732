import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CircularProgress, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import StatusIcon, { StatusIconStatus } from "../statusIcon/statusIcon";
import { isCompleteMonth } from "../../../utils/billing";
import { getFileReportUrl } from "../../../redux/reducers/reportReducer";

const BillingReportTable = ({ records, ...containerPops }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetchingReportUrlForFilename } = useSelector(
    (state) => state.billing
  );

  const onFilenameClick = (filename) => {
    dispatch(getFileReportUrl(filename)).then((url) => {
      window.open(url, "_blank");
    });
  };

  return (
    <TableContainer component={Paper} sx={{ ...containerPops }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>{t("status")}</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>{t("name")}</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>{t("service")}</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("targetMonth")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>{t("targetYear")}</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>{t("created")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {(records || []).map((row) => {
            return (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="center">
                  <StatusIcon
                    status={
                      isCompleteMonth(
                        row.year,
                        row.monthNumber,
                        moment(row.createdAt)
                      )
                        ? StatusIconStatus.Ok
                        : StatusIconStatus.Warning
                    }
                    okTooltip={t("timePeriodComplete")}
                    warningTooltip={t("timePeriodIncomplete")}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link
                    component="button"
                    onClick={() => onFilenameClick(row.filename)}
                  >
                    {row.filename}
                    {fetchingReportUrlForFilename === row.filename && (
                      <CircularProgress sx={{ ml: 1 }} size={15} />
                    )}
                  </Link>
                </TableCell>
                <TableCell>{row.service}</TableCell>
                <TableCell>{t(`months.${row.monthNumber}`)}</TableCell>
                <TableCell>{row.year}</TableCell>
                <TableCell>
                  {moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BillingReportTable.propTypes = {
  records: PropTypes.array,
};

export default BillingReportTable;
