import axios from "../../utils/axios";

const Types = {
  FETCHING_ACCOUNTS_START: "FETCHING_ACCOUNTS_START",
  FETCHING_ACCOUNTS_FINISH: "FETCHINGACCOUNTS_FINISH",
  FETCHING_ACCOUNTS_ERROR: "FETCHING_ACCOUNTS_ERROR",
  FETCHING_ACCOUNT_DETAILS_START: "FETCHING_ACCOUNT_DETAILS_START",
  FETCHING_ACCOUNT_DETAILS_FINISH: "FETCHING_ACCOUNT_DETAILS_FINISH",
  FETCHING_ACCOUNT_DETAILS_ERROR: "FETCHING_ACCOUNT_DETAILS_ERROR",
  UPDATE_ACCOUNT_DETAILS: "UPDATE_ACCOUNT_DETAILS",
};

const API_URL = process.env.REACT_APP_API_URL;
const ACCOUNTS_API_URL = `${API_URL}account`;
const ACCOUNT_DETAILS_API_URL = `${API_URL}account/details`;
let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

export const fetchAccounts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_ACCOUNTS_START });

      const response = await axios.get(ACCOUNTS_API_URL, {
        signal: getAbortController().signal,
      });

      console.log("getAccounts", response.data.items);

      dispatch({
        type: Types.FETCHING_ACCOUNTS_FINISH,
        payload: response.data.items,
      });
      return response.data.items;
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_ACCOUNTS_ERROR,
          payload: error.message,
        });
      }
      return [];
    }
  };
};

export const fetchAccountDetails = (service, accountId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_ACCOUNT_DETAILS_START });

      const url = `${ACCOUNT_DETAILS_API_URL}?service=${service}&accountId=${accountId}`;
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });

      console.log("fetchAccountDetails", response.data.item);

      dispatch({
        type: Types.UPDATE_ACCOUNT_DETAILS,
        payload: response.data.item,
      });

      dispatch({
        type: Types.FETCHING_ACCOUNT_DETAILS_FINISH,
      });
      return response.data.item;
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_ACCOUNT_DETAILS_ERROR,
          payload: error.message,
        });
      }
      return null;
    }
  };
};

export const clearAccountDetails = () => {
  return {
    type: Types.UPDATE_ACCOUNT_DETAILS,
    payload: null,
  };
};

const INIT_STATE = {
  accounts: [],
  fetchingAccounts: false,
  accountsFetchError: null,
  accountDetails: null,
  fetchingAccountDetails: false,
  accountDetailsFetchError: null,
};

export const accountsReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Types.FETCHING_ACCOUNT_DETAILS_START:
      return {
        ...state,
        fetchingAccountDetails: true,
        accountDetailsFetchError: null,
      };
    case Types.UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.payload,
      };
    case Types.FETCHING_ACCOUNT_DETAILS_FINISH:
      return {
        ...state,

        fetchingAccountDetails: false,
      };
    case Types.FETCHING_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        fetchingAccountDetails: false,
        accountDetailsFetchError: action.payload,
      };
    case Types.FETCHING_ACCOUNTS_START:
      return {
        ...state,
        fetchingAccounts: true,
        accountsFetchError: null,
      };
    case Types.FETCHING_ACCOUNTS_FINISH:
      return { ...state, accounts: action.payload, fetchingAccounts: false };
    case Types.FETCHING_ACCOUNTS_ERROR:
      return {
        ...state,
        fetchingAccounts: false,
        accountsFetchError: action.payload,
      };
    default:
      return state;
  }
};
