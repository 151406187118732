import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import RadioSelection from "../../radioSelection/radioSelection";
import { useSelector } from "react-redux";

const ValidatedProps = Object.freeze({
  CLIENT_NAME: "clientName",
  SCOPES: "scopes",
});

const EditAppClientDialog = ({
  title,
  open,
  onClose,
  onSave,
  saving,
  appClientData,
  ...containerProps
}) => {
  const { t } = useTranslation();
  const [editedRecord, setEditedRecord] = useState(null);
  const [validationErrorMap, setValidationErrorMap] = useState(new Map());
  const { availableScopes } = useSelector((state) => state.admin);
  const [selectedScopes, setSelectedScopes] = useState([]);

  useEffect(() => {
    if (editedRecord == null && open === true) {
      const app = appClientData || {};
      const scopes = (app.scopes || []).map((scope) => ({
        value: scope,
      }));
      setEditedRecord(app);
      setSelectedScopes(scopes);
    } else if (editedRecord != null && open === false) {
      setEditedRecord(null);
      setSelectedScopes([]);
    }
  }, [open, appClientData]); // eslint-disable-line

  const handleSave = (record) => {
    const isValidProp = (validationMap, prop) => {
      if (record[prop] == null || record[prop].length === 0) {
        validationMap.set(prop, true);
      }
    };

    const validationMap = new Map();
    for (const prop of Object.values(ValidatedProps)) {
      isValidProp(validationMap, prop);
    }

    if (validationMap.size === 0) {
      onSave(record);
    } else {
      setValidationErrorMap(validationMap);
    }
  };

  const safeEditedRecord = editedRecord || {};
  return (
    <Box sx={{ ...containerProps }}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2 }}
            margin="dense"
            id="appClientName"
            label={t("appClientName")}
            fullWidth
            variant="standard"
            value={safeEditedRecord.clientName || ""}
            disabled={safeEditedRecord.clientId != null}
            onChange={(e) =>
              setEditedRecord({
                ...safeEditedRecord,
                clientName: e.target.value,
              })
            }
            error={validationErrorMap.get(ValidatedProps.CLIENT_NAME)}
          />
          <RadioSelection
            title={t("scopes")}
            entries={(availableScopes || []).map((scope) => ({
              value: scope,
              label: `${t(`scope.${scope}`)} (${scope})`,
            }))}
            selectedEntries={selectedScopes}
            onSelected={(entries) => {
              setSelectedScopes(entries);
              setEditedRecord({ ...safeEditedRecord, scopes: entries });
            }}
            error={validationErrorMap.get(ValidatedProps.SCOPES)}
          />
        </DialogContent>
        <DialogActions>
          {saving === true && <CircularProgress size={20} sx={{ mr: 1 }} />}
          <Button
            variant="contained"
            disabled={saving === true}
            onClick={() => handleSave(safeEditedRecord)}
          >
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            disabled={saving === true}
            onClick={onClose}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

EditAppClientDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  appClientData: PropTypes.object,
};

EditAppClientDialog.defaultProps = {
  disabled: false,
  saving: false,
};

export default EditAppClientDialog;
