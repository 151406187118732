import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { getCurrencyStr } from "../../../utils/billing";
import { CurrencyCode } from "../../common";
const Decimal = require("decimal.js");

const BillingAggregatedTable = ({ records, ...containerPops }) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} sx={{ ...containerPops }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>{t("service")}</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {`${t("cost")} (${t("preTax")})`}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {`${t("cost")} (${t("withVat")})`}
            </TableCell>

            <TableCell sx={{ fontWeight: "bold" }}>{t("vat")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {(records || []).map((row) => {
            const { preTaxCost, cost, currency, vatRate } = row;
            const currencyStr = getCurrencyStr(currency || CurrencyCode.EUR);
            const preTaxCostStr = `${new Decimal(preTaxCost).toFixed(2)}${currencyStr}`;
            const costStr = `${new Decimal(cost).toFixed(2)}${currencyStr}`;
            const vatStr = `${new Decimal(vatRate * 100 - 100).toFixed(1)}%`;

            return (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {`${row.service}`}
                </TableCell>
                <TableCell>{preTaxCostStr}</TableCell>
                <TableCell>{costStr}</TableCell>
                <TableCell>{vatStr}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BillingAggregatedTable.propTypes = {
  records: PropTypes.array,
};

export default BillingAggregatedTable;
