import axios from "../../utils/axios";

const Types = {
  FETCH_VIRTUAL_EMAIL_START: "FETCH_VIRTUAL_EMAIL_START",
  FETCH_VIRTUAL_EMAIL_SUCCESS: "FETCH_VIRTUAL_EMAIL_SUCCESS",
  FETCH_VIRTUAL_EMAIL_FAILURE: "FETCH_VIRTUAL_EMAIL_FAILURE",
  SEND_VIRTUAL_EMAIL_START: "SEND_VIRTUAL_EMAIL_START",
  SEND_VIRTUAL_EMAIL_SUCCESS: "SEND_VIRTUAL_EMAIL_SUCCESS",
  SEND_VIRTUAL_EMAIL_FAILURE: "SEND_VIRTUAL_EMAIL_FAILURE",
  UPDATE_VIRTUAL_EMAILS: "UPDATE_VIRTUAL_EMAILS",
  FETCH_VIRTUAL_EMAIL_CONFIG_START: "FETCH_VIRTUAL_EMAIL_CONFIG_START",
  FETCH_VIRTUAL_EMAIL_CONFIG_SUCCESS: "FETCH_VIRTUAL_EMAIL_CONFIG_SUCCESS",
  FETCH_VIRTUAL_EMAIL_CONFIG_FAILURE: "FETCH_VIRTUAL_EMAIL_CONFIG_FAILURE",
  UPDATE_VIRTUAL_EMAIL: "UPDATE_VIRTUAL_EMAIL",
};

const API_URL = process.env.REACT_APP_API_URL;
const VIRTUAL_EMAIL_API_URL = `${API_URL}virtual-email`;
let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

export const fetchVirtualEmailConfiguration = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCH_VIRTUAL_EMAIL_CONFIG_START });

      const url = `${VIRTUAL_EMAIL_API_URL}/config`;
      const response = await axios.get(url);
      console.log("fetchVirtualEmailConfiguration", response.data);

      dispatch({
        type: Types.FETCH_VIRTUAL_EMAIL_CONFIG_SUCCESS,
        payload: response.data.configuration,
      });
    } catch (error) {
      dispatch({
        type: Types.FETCH_VIRTUAL_EMAIL_CONFIG_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchVirtualEmails = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCH_VIRTUAL_EMAIL_START });

      const url = `${VIRTUAL_EMAIL_API_URL}/`;
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchVirtualEmails", response.data.items);

      dispatch({
        type: Types.UPDATE_VIRTUAL_EMAILS,
        payload: response.data.items,
      });

      dispatch({ type: Types.FETCH_VIRTUAL_EMAIL_SUCCESS });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCH_VIRTUAL_EMAIL_FAILURE,
          payload: error.message,
        });
      }
    }
  };
};

export const fetchVirtualEmail = (virtualEmail) => {
  return async (dispatch) => {
    try {
      console.log("fetchVirtualEmail", virtualEmail);

      dispatch({ type: Types.FETCH_VIRTUAL_EMAIL_START });

      const url = `${VIRTUAL_EMAIL_API_URL}/id/${virtualEmail}`;
      const response = await axios.get(url);

      console.log("fetchVirtualEmail", response.data.item);

      dispatch({
        type: Types.UPDATE_VIRTUAL_EMAIL,
        payload: response.data.item,
      });

      dispatch({ type: Types.FETCH_VIRTUAL_EMAIL_SUCCESS });

      return response.data.item;
    } catch (error) {
      dispatch({
        type: Types.FETCH_VIRTUAL_EMAIL_FAILURE,
        payload: error.message,
      });
      return null;
    }
  };
};

export const clearVirtualEmail = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.UPDATE_VIRTUAL_EMAIL,
      payload: null,
    });
  };
};

export const saveVirtualEmail = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.SEND_VIRTUAL_EMAIL_START });

      const url = `${VIRTUAL_EMAIL_API_URL}/`;
      const response = await axios.post(url, data);
      console.log("saveVirtualEmail", response.data);

      const savedItem = response.data.item;
      const items = getState().virtualEmail.virtualEmails.slice(0);
      // update
      const index = items.findIndex((item) => item.id === savedItem.id);
      if (index !== -1) {
        items.splice(index, 1, savedItem);
      } else {
        items.unshift(savedItem);
      }

      dispatch({
        type: Types.UPDATE_VIRTUAL_EMAILS,
        payload: items,
      });

      dispatch({ type: Types.SEND_VIRTUAL_EMAIL_SUCCESS });
    } catch (error) {
      dispatch({
        type: Types.SEND_VIRTUAL_EMAIL_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteVirtualEmail = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.SEND_VIRTUAL_EMAIL_START });

      const url = `${VIRTUAL_EMAIL_API_URL}/delete`;
      const response = await axios.post(url, { id });
      console.log("deleteVirtualEmail", response.data);

      const savedItem = response.data.item;
      const items = getState().virtualEmail.virtualEmails.slice(0);
      // update
      const index = items.findIndex((item) => item.id === savedItem.id);
      if (index !== -1) {
        items.splice(index, 1, savedItem);
      }

      dispatch({
        type: Types.UPDATE_VIRTUAL_EMAILS,
        payload: items,
      });

      dispatch({ type: Types.SEND_VIRTUAL_EMAIL_SUCCESS });

      return Promise.resolve(savedItem);
    } catch (error) {
      dispatch({
        type: Types.SEND_VIRTUAL_EMAIL_FAILURE,
        payload: error.message,
      });

      return Promise.reject(error);
    }
  };
};

const INIT_STATE = {
  virtualEmails: [],
  virtualEmail: null,
  fetchingVirtualEmail: false,
  virtualEmailFetchingError: null,
  sendingVirtualEmail: false,
  virtualEmailSendError: null,
  virtualEmailConfiguration: {},
  fetchingVirtualEmailConfiguration: false,
  virtualEmailConfigurationFetchingError: null,
};

export const virtualEmailReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_VIRTUAL_EMAIL_START: {
      return {
        ...state,
        fetchingVirtualEmail: true,
        virtualEmailFetchingError: null,
      };
    }
    case Types.FETCH_VIRTUAL_EMAIL_SUCCESS: {
      return { ...state, fetchingVirtualEmail: false };
    }
    case Types.FETCH_VIRTUAL_EMAIL_FAILURE: {
      return {
        ...state,
        fetchingVirtualEmail: false,
        virtualEmailFetchingError: action.payload,
      };
    }
    case Types.UPDATE_VIRTUAL_EMAILS: {
      return { ...state, virtualEmails: action.payload };
    }
    case Types.UPDATE_VIRTUAL_EMAIL: {
      return { ...state, virtualEmail: action.payload };
    }
    case Types.SEND_VIRTUAL_EMAIL_START:
      return {
        ...state,
        sendingVirtualEmail: true,
        virtualEmailSendError: null,
      };
    case Types.SEND_VIRTUAL_EMAIL_FAILURE:
      return {
        ...state,
        sendingVirtualEmail: false,
        virtualEmailSendError: action.payload,
      };
    case Types.SEND_VIRTUAL_EMAIL_SUCCESS:
      return {
        ...state,
        sendingVirtualEmail: false,
      };
    case Types.FETCH_VIRTUAL_EMAIL_CONFIG_START:
      return {
        ...state,
        fetchingVirtualEmailConfiguration: true,
        virtualEmailConfigurationFetchingError: null,
      };
    case Types.FETCH_VIRTUAL_EMAIL_CONFIG_SUCCESS:
      return {
        ...state,
        fetchingVirtualEmailConfiguration: false,
        virtualEmailConfiguration: action.payload,
      };
    case Types.FETCH_VIRTUAL_EMAIL_CONFIG_FAILURE:
      return {
        ...state,
        fetchingVirtualEmailConfiguration: false,
        virtualEmailConfigurationFetchingError: action.payload,
      };
    default:
      return state;
  }
};
