import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  ScrollRestoration,
  Outlet,
} from "react-router-dom";
import NotFound from "./components/notFound/notFound";
import Integration, {
  IntegrationPaths,
} from "./components/integration/integration";
import { useSelector } from "react-redux";
import Authorized from "./auth/authorized";
import { Permission } from "./auth/permission";
import Unauthorized from "./components/unauthorized/unauthorized";
import CognitoAuthenticator from "./auth/cognitoAuthenticator";
import Docs from "./components/docs/docs";
import ApiDoc from "./components/docs/apiDoc";
import AuditLog, { AuditLogPaths } from "./components/auditLog/auditLog";
import AWS from "./components/infra/aws/aws";
import Azure from "./components/infra/azure/azure";
import GCP from "./components/infra/gcp/gcp";
import { useEffect } from "react";
import VirtualEmail, {
  VirtualEmailPaths,
} from "./components/virtualEmail/virtualEmail";
import Billing, { BillingPaths } from "./components/billing/billing";
import BillingAll from "./components/billing/billingAll/billingAll";
import BillingByService from "./components/billing/billingByService/billingByService";
import { Service } from "./components/common";
import BillingByOwner from "./components/billing/billingByOwner/billingByOwner";
import BillingByAccount from "./components/billing/billingByAccount/billingByAccount";
import Reports from "./components/billing/reports/reports";
import Tilkka from "./components/billing/tilkka/tilkka";
import { AppBarPaths } from "./components/layout/appBar";
import Configuration from "./components/virtualEmail/configuration/configuration";
import VirtualEmailRecords from "./components/virtualEmail/virtualEmailRecords/virtualEmailRecords";
import SapBillingData from "./components/integration/sapBillingData/sapBillingData";
import ExternalClients from "./components/integration/externalClients/externalClients";
import AuditLogBilling from "./components/auditLog/auditLogBilling";
import AuditLogSap from "./components/auditLog/auditLogSap";
import AuditLogVirtualEmail from "./components/auditLog/auditLogVirtualEmail";
import AuditLogAzure from "./components/auditLog/auditLogAzure";
import AuditLogAppClient from "./components/auditLog/auditLogAppClient";
import Accounts, { AccountsPaths } from "./components/accounts/accounts";
import AccountList from "./components/accounts/accountList";
import AccountDetails from "./components/accounts/accountDetails";
import Logs, { LogPaths } from "./components/logs/logs";
import AwsLogs from "./components/logs/awsLogs";
import Discounts from "./components/billing/discounts/discounts";
import Management, {
  ManagementPaths,
} from "./components/management/management";
import AzureApplications from "./components/management/azureApplications/azureApplications";

const AppRoot = () => {
  useEffect(() => {
    const isMainPath = window.location.pathname === "/";
    if (isMainPath) {
      window.location.replace("/docs");
    }
  }, []);

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

const App = () => {
  const { userData } = useSelector((state) => state.user);

  const authorizedRender = (permission, component) => {
    return (
      <Authorized
        user={userData}
        permission={permission}
        unauthorizedView={<Unauthorized />}
      >
        {component}
      </Authorized>
    );
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route exact path={AppBarPaths.MAIN} element={<AppRoot />}>
        <Route exact path={AppBarPaths.DOCS} element={<Docs />} />
        <Route path="/docs/:docId" element={<ApiDoc />} />
        <Route
          path={AppBarPaths.BILLING}
          element={authorizedRender(Permission.BILLING_VIEW, <Billing />)}
        >
          <Route
            path={BillingPaths.ALL}
            element={authorizedRender(Permission.BILLING_VIEW, <BillingAll />)}
          />
          <Route
            path={BillingPaths.AWS}
            element={authorizedRender(
              Permission.BILLING_VIEW,
              <BillingByService service={Service.AWS} />,
            )}
          />
          <Route
            path={BillingPaths.AWS_OLD}
            element={authorizedRender(
              Permission.BILLING_VIEW,
              <BillingByService service={Service.AWS_OLD} />,
            )}
          />
          <Route
            path={BillingPaths.AZURE}
            element={authorizedRender(
              Permission.BILLING_VIEW,
              <BillingByService service={Service.AZURE} />,
            )}
          />
          <Route
            path={BillingPaths.GCP}
            element={authorizedRender(
              Permission.BILLING_VIEW,
              <BillingByService service={Service.GCP} />,
            )}
          />
          <Route
            path={BillingPaths.OWNER}
            element={authorizedRender(
              Permission.BILLING_VIEW,
              <BillingByOwner />,
            )}
          />
          <Route
            path={BillingPaths.ACCOUNT}
            element={authorizedRender(
              Permission.BILLING_VIEW,
              <BillingByAccount />,
            )}
          />
          <Route
            path={BillingPaths.REPORT_HISTORY}
            element={authorizedRender(Permission.BILLING_VIEW, <Reports />)}
          />
          <Route
            path={BillingPaths.TILKKA}
            element={authorizedRender(Permission.BILLING_VIEW, <Tilkka />)}
          />
          <Route
            path={BillingPaths.DISCOUNTS}
            element={authorizedRender(Permission.BILLING_VIEW, <Discounts />)}
          />
        </Route>
        <Route
          exact
          path={AppBarPaths.VIRTUAL_EMAIL}
          element={authorizedRender(
            Permission.VIRTUAL_EMAIL_VIEW,
            <VirtualEmail />,
          )}
        >
          <Route
            path={VirtualEmailPaths.RECORDS}
            element={authorizedRender(
              Permission.VIRTUAL_EMAIL_VIEW,
              <VirtualEmailRecords />,
            )}
          />
          <Route
            path={VirtualEmailPaths.CONFIGURATION}
            element={authorizedRender(Permission.ADMIN, <Configuration />)}
          />
        </Route>
        <Route
          path={AppBarPaths.INTEGRATION}
          element={authorizedRender(
            Permission.INTEGRATION_VIEW,
            <Integration />,
          )}
        >
          <Route
            path={IntegrationPaths.SAP_BILLING_DATA}
            element={authorizedRender(
              Permission.INTEGRATION_VIEW,
              <SapBillingData />,
            )}
          />
          <Route
            path={IntegrationPaths.EXTERNAL_CLIENTS}
            element={authorizedRender(Permission.ADMIN, <ExternalClients />)}
          />
        </Route>
        <Route
          path={AppBarPaths.AWS}
          element={authorizedRender(Permission.INTEGRATION_VIEW, <AWS />)}
        />
        <Route
          path={AppBarPaths.AZURE}
          element={authorizedRender(Permission.INTEGRATION_VIEW, <Azure />)}
        />
        <Route
          path={AppBarPaths.GCP}
          element={authorizedRender(Permission.INTEGRATION_VIEW, <GCP />)}
        />
        <Route
          path={AppBarPaths.AUDIT}
          element={authorizedRender(Permission.ADMIN, <AuditLog />)}
        >
          <Route
            path={AuditLogPaths.BILLING}
            element={authorizedRender(Permission.ADMIN, <AuditLogBilling />)}
          />
          <Route
            path={AuditLogPaths.SAP}
            element={authorizedRender(Permission.ADMIN, <AuditLogSap />)}
          />
          <Route
            path={AuditLogPaths.VIRTUAL_EMAIL}
            element={authorizedRender(
              Permission.ADMIN,
              <AuditLogVirtualEmail />,
            )}
          />
          <Route
            path={AuditLogPaths.AZURE_PROVISIONER}
            element={authorizedRender(Permission.ADMIN, <AuditLogAzure />)}
          />
          <Route
            path={AuditLogPaths.APP_CLIENT}
            element={authorizedRender(Permission.ADMIN, <AuditLogAppClient />)}
          />
        </Route>
        <Route
          path={AppBarPaths.ACCOUNTS}
          element={authorizedRender(Permission.ACCOUNT_VIEW, <Accounts />)}
        >
          <Route
            path={AccountsPaths.LIST}
            element={authorizedRender(Permission.ACCOUNT_VIEW, <AccountList />)}
          />
          <Route
            path={AccountsPaths.ACCOUNT_DETAILS}
            element={authorizedRender(
              Permission.ACCOUNT_VIEW,
              <AccountDetails />,
            )}
          />
        </Route>
        <Route
          path={AppBarPaths.LOGS}
          element={authorizedRender(Permission.ADMIN, <Logs />)}
        >
          <Route
            path={LogPaths.AWS}
            element={authorizedRender(Permission.ADMIN, <AwsLogs />)}
          />
        </Route>
        <Route
          path={AppBarPaths.MANAGEMENT}
          element={authorizedRender(Permission.MANAGEMENT, <Management />)}
        >
          <Route
            path={ManagementPaths.AZURE_APPS}
            element={authorizedRender(
              Permission.MANAGEMENT,
              <AzureApplications />,
            )}
          />
        </Route>
        <Route component={NotFound} />
      </Route>,
    ),
  );

  return (
    <CognitoAuthenticator>
      <RouterProvider router={router} />
    </CognitoAuthenticator>
  );
};

export default App;
