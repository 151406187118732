import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { fetchAccounts } from "../../redux/reducers/accountsReducer";
import AccountTable from "./accountTable";
import { showSnackbar } from "../../redux/reducers/notificationReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AccountList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { accounts, fetchingAccounts, accountsFetchError } = useSelector(
    (state) => state.account
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (accounts == null || accounts.length === 0) {
      dispatch(fetchAccounts());
    }
  }, [dispatch, accounts]);

  // Error notifications
  useEffect(() => {
    if (accountsFetchError != null) {
      dispatch(
        showSnackbar({ message: accountsFetchError, severity: "error" })
      );
    }
  }, [accountsFetchError, dispatch]);

  const handleRowClick = (account) => {
    const { service, accountId } = account;
    navigate(`${service}/${accountId}`);
  };

  return (
    <Box p={2}>
      <Box sx={{}}>
        <Typography variant="subtitle1">
          {`${accounts.length} ${t("accounts").toLocaleLowerCase()}`}
        </Typography>
      </Box>
      <AccountTable
        records={accounts}
        fetchingAccounts={fetchingAccounts}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};

export default AccountList;
