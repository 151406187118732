export const FieldStatus = Object.freeze({
  Mandatory: "mandatory",
  Optional: "optional",
  Forbidden: "forbidden",
});

const FieldStatusByFieldStatusGroup = Object.freeze({
  // 'E400' => 'Kulutili (KP, CO-PA, val. IO), tulos',
  E400: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Mandatory,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
  // 'E403' => 'Kulutili (IO), tulos',
  E403: {
    COST_CENTER: FieldStatus.Forbidden,
    PRODUCT_CODE: FieldStatus.Forbidden,
    INTERNAL_ORDER_NUMBER: FieldStatus.Mandatory,
  },
  // 'E402' => 'Kulutili (KP, val. IO), tulos',
  E402: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Forbidden,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
  // 'E405' => 'Kulutili (KP, val. CO-PA ja IO), tulos',
  E405: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Optional,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
  // 'E404' => 'Kulutili (KP), tulos',
  E404: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Forbidden,
    INTERNAL_ORDER_NUMBER: FieldStatus.Forbidden,
  },
  // 'E415' => 'Kulutili (KP, val COPA)',
  E415: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Optional,
    INTERNAL_ORDER_NUMBER: FieldStatus.Forbidden,
  },
  // 'E414' => 'Kulutili (KP, val IO,COPA)',
  E414: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Optional,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
  // 'E410' => 'Tulostili (ei sisäisen lask. kohd.)',
  E410: {
    COST_CENTER: FieldStatus.Forbidden,
    PRODUCT_CODE: FieldStatus.Forbidden,
    INTERNAL_ORDER_NUMBER: FieldStatus.Forbidden,
  },
  // 'E406' => 'Teletilat (KP, CO-PA, val. IO), tulos',
  E406: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Mandatory,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
  // 'E413' => 'Kulutili (val. PC)',
  E413: {
    COST_CENTER: FieldStatus.Forbidden,
    PRODUCT_CODE: FieldStatus.Optional,
    INTERNAL_ORDER_NUMBER: FieldStatus.Forbidden,
  },
  // 'E401' => 'Kulutili (KP ja IO, val COPA), tulos',
  E401: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Optional,
    INTERNAL_ORDER_NUMBER: FieldStatus.Mandatory,
  },
  // 'E407' => 'Inv. kulutili (IO), tulos',
  E407: {
    COST_CENTER: FieldStatus.Forbidden,
    PRODUCT_CODE: FieldStatus.Forbidden,
    INTERNAL_ORDER_NUMBER: FieldStatus.Mandatory,
  },
  // 'E408' => 'Yhd. liik. (val. CO-PA, KP ja IO), tulos',
  E408: {
    COST_CENTER: FieldStatus.Optional,
    PRODUCT_CODE: FieldStatus.Optional,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
  // 'MM10' => 'MM Käyttötili (val. KP ja IO), tulos',
  MM10: {
    COST_CENTER: FieldStatus.Optional,
    PRODUCT_CODE: FieldStatus.Forbidden,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
  // 'E412' => 'Kulutili (KP, PrtPC val. IO), tulos',
  E412: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Forbidden,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
  // 'E411' => 'Kulutili (KP,PrtPC val. CO-PA,IO), tulos'
  E411: {
    COST_CENTER: FieldStatus.Mandatory,
    PRODUCT_CODE: FieldStatus.Optional,
    INTERNAL_ORDER_NUMBER: FieldStatus.Optional,
  },
});

export const getFieldStatusByFieldStatusGroup = (field) => {
  return FieldStatusByFieldStatusGroup[field];
};

export const getFieldStatusGroupsForField = (field) => {
  const result = {
    mandatory: [],
    optional: [],
    forbidden: [],
  };

  Object.entries(FieldStatusByFieldStatusGroup).forEach(([key, value]) => {
    if (value[field] === FieldStatus.Mandatory) {
      result.mandatory.push(key);
    } else if (value[field] === FieldStatus.Optional) {
      result.optional.push(key);
    } else {
      result.forbidden.push(key);
    }
  });

  return result;
};
