import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { hasPermission, Permission } from "../../auth/permission";
import { useSelector } from "react-redux";
import { White } from "../../theme/colors";
import Account from "./account";
import { BillingPaths } from "../billing/billing";
import { VirtualEmailPaths } from "../virtualEmail/virtualEmail";
import { IntegrationPaths } from "../integration/integration";
import { AuditLogPaths } from "../auditLog/auditLog";
import { LogPaths } from "../logs/logs";
import { ManagementPaths } from "../management/management";

export const AppBarPaths = Object.freeze({
  MAIN: "/",
  DOCS: "/docs",
  BILLING: "/billing",
  VIRTUAL_EMAIL: "/virtual-email",
  INTEGRATION: "/integration",
  AUDIT: "/audit",
  AWS: "/aws",
  AZURE: "/azure",
  GCP: "/gcp",
  ACCOUNTS: "/accounts",
  LOGS: "/logs",
  MANAGEMENT: "/management",
});

const AdminAppBar = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state) => state.user);
  const location = useLocation();

  const billingDisabled =
    hasPermission(userData, Permission.BILLING_VIEW) === false;
  const virtualEmailDisabled =
    hasPermission(userData, Permission.VIRTUAL_EMAIL_VIEW) === false;
  const integrationDisabled =
    hasPermission(userData, Permission.INTEGRATION_VIEW) === false;
  const accountsDisabled =
    hasPermission(userData, Permission.ACCOUNT_VIEW) === false;
  const adminDisabled = hasPermission(userData, Permission.ADMIN) === false;
  const managementDisabled =
    hasPermission(userData, Permission.MANAGEMENT) === false;

  const renderLink = (to, text, disabled = false) => {
    if (disabled) {
      return null;
    }

    const isActive = location.pathname === to;
    return (
      <Box sx={{ ml: 2, fontWeight: isActive ? "bold" : "normal" }}>
        <Link style={{ color: White }} to={to}>
          {text}
        </Link>
      </Box>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography sx={{ ml: 1, mr: 2 }} variant="h6" component="div">
            {t("appName")}
          </Typography>
          {renderLink(AppBarPaths.DOCS, t("docs"))}
          {renderLink(
            `${AppBarPaths.BILLING}/${BillingPaths.ALL}`,
            t("billing"),
            billingDisabled
          )}
          {renderLink(
            `${AppBarPaths.ACCOUNTS}`,
            t("accounts"),
            accountsDisabled
          )}
          {renderLink(
            `${AppBarPaths.VIRTUAL_EMAIL}/${VirtualEmailPaths.RECORDS}`,
            t("virtualEmail"),
            virtualEmailDisabled
          )}
          {renderLink(
            `${AppBarPaths.INTEGRATION}/${IntegrationPaths.SAP_BILLING_DATA}`,
            t("integration"),
            integrationDisabled
          )}
          {renderLink(
            `${AppBarPaths.AUDIT}/${AuditLogPaths.AZURE_PROVISIONER}`,
            t("audit"),
            adminDisabled
          )}
          {renderLink(
            `${AppBarPaths.LOGS}/${LogPaths.AWS}`,
            t("logs"),
            adminDisabled
          )}
          {renderLink(
            `${AppBarPaths.MANAGEMENT}/${ManagementPaths.AZURE_APPS}`,
            t("management"),
            managementDisabled
          )}
          <Box flexGrow={1} />
          <Account userData={userData} />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AdminAppBar;
