import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

const DynamicSelectList = ({
  selectedOption,
  onOptionSelected,
  onLoadOptions,
  onRenderOption,
  onGetOptionDisabled,
  label,
  error,
  helperText,
  disabled,
  ...containerProps
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open === true) {
      setLoading(true);

      // load options
      (async () => {
        const list = await onLoadOptions();
        setOptions(list);
        setLoading(false);
      })();
    }
    return () => setOptions([]);
  }, [open, setOptions, onLoadOptions]);

  return (
    <Box>
      <Autocomplete
        sx={{ ...containerProps }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionDisabled={(option) =>
          onGetOptionDisabled != null ? onGetOptionDisabled(option) : false
        }
        options={options}
        value={selectedOption}
        onChange={(_e, value) => onOptionSelected(value)}
        loading={loading}
        disabled={disabled}
        getOptionLabel={
          onRenderOption != null ? onRenderOption : (option) => option
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              size="small"
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading === true ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
      />
      {error === true && helperText != null && (
        <Typography variant="caption" color="error">
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

DynamicSelectList.propTypes = {
  selectedOption: PropTypes.any,
  onOptionSelected: PropTypes.func,
  onLoadOptions: PropTypes.func,
  onRenderOption: PropTypes.func,
  onGetOptionDisabled: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DynamicSelectList;
