import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Months = Object.freeze({
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
});

const MonthSelect = ({
  value,
  onSelect,
  allowEmptyMonth,
  ...containerProps
}) => {
  const { t } = useTranslation();
  return (
    <FormControl size="small" sx={{ minWidth: "120px", ...containerProps }}>
      <InputLabel>{t("month")}</InputLabel>
      <Select
        value={value}
        label={t("month")}
        onChange={(e) => onSelect(e.target.value)}
      >
        {allowEmptyMonth && <MenuItem value="">{t("months.EMPTY")}</MenuItem>}
        {Object.values(Months).map((month) => {
          return (
            <MenuItem key={month} value={month}>
              {t(`months.${month}`)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MonthSelect;
