import React from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ReportTypeSelect = ({
  disabled,
  reportType,
  reportTypes,
  onReportTypeSelected,
  error,
  helperText,
  ...containerProps
}) => {
  const { t } = useTranslation();
  return (
    <FormControl
      disabled={disabled}
      size="small"
      sx={{ ...containerProps }}
      error={error}
    >
      <InputLabel>{t("reportType")}</InputLabel>
      <Select
        value={reportType}
        label={t("reportType")}
        onChange={(e) => onReportTypeSelected(e.target.value)}
      >
        {Object.values(reportTypes).map((value) => {
          return (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
      {helperText != null && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ReportTypeSelect;
