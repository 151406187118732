import { TableCell, TableSortLabel } from "@mui/material";
import { SortDirection } from "../../utils/table";

const SortableTableCell = ({
  label,
  curretSortedProperty,
  property,
  order,
  onSort,
}) => {
  return (
    <TableCell
      sx={{ fontWeight: "bold" }}
      sortDirection={curretSortedProperty === property ? order : false}
    >
      <TableSortLabel
        active={curretSortedProperty === property}
        direction={
          curretSortedProperty === property ? order : SortDirection.Descending
        }
        onClick={() => onSort(property)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableTableCell;
