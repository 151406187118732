import React, { useEffect, useState } from "react";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../layout/layout";
import PageHeaderText from "../pageHeaderText/pageHeaderText";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const AuditLogPaths = Object.freeze({
  BILLING: "billing",
  SAP: "sap",
  VIRTUAL_EMAIL: "virtual-email",
  AZURE_PROVISIONER: "azure-provisioner",
  APP_CLIENT: "app-client",
});

const AuditLog = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(AuditLogPaths.AZURE_PROVISIONER);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Object.values(AuditLogPaths).forEach((path) => {
      if (location.pathname.includes(path)) {
        setValue(path);
      }
    });
  }, [location.pathname, setValue]);

  const onTabChanged = (newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Layout p={2}>
      <PageHeaderText text={t("auditLog")} />
      <Paper sx={{ p: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(_e, newValue) => onTabChanged(newValue)}
          >
            <Tab
              value={AuditLogPaths.AZURE_PROVISIONER}
              label={t("azureProvisioner")}
            />
            <Tab value={AuditLogPaths.BILLING} label={t("billing")} />
            <Tab value={AuditLogPaths.SAP} label={t("sap")} />
            <Tab
              value={AuditLogPaths.VIRTUAL_EMAIL}
              label={t("virtualEmail")}
            />
            <Tab value={AuditLogPaths.APP_CLIENT} label={t("appClient")} />
          </Tabs>
        </Box>
        <Outlet />
      </Paper>
    </Layout>
  );
};

AuditLog.propTypes = {};

export default AuditLog;
