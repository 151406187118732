import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Alert,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  VirtualEmailValidatedProps,
  validateVirtualEmailRecord,
} from "./validateVirtualEmailRecord";
import {
  getVirtualEmailDomainsFromConfig,
  recipientStrToArray,
  recipientsStrToJson,
} from "../../../utils/virtualEmail";

const EditVirtualEmailRecordDialog = ({
  record,
  title,
  open,
  onClose,
  onSave,
  saving,
  configuration,
  existingVirtualEmails,
  ...containerProps
}) => {
  const { t } = useTranslation();
  const [editedRecord, setEditedRecord] = useState(null);
  const [recipients, setRecipients] = useState("");
  const [validationErrorMap, setValidationErrorMap] = useState(new Map());

  useEffect(() => {
    setEditedRecord({
      ...record,
      managed: record.managed == null ? true : record.managed === true,
    });
    setRecipients(recipientStrToArray(record.recipients).join(", "));
  }, [record, setEditedRecord]);

  if (editedRecord == null) {
    return null;
  }

  const handleSave = () => {
    const record = {
      ...editedRecord,
      recipients: recipientsStrToJson(recipients),
    };

    const validationErrorMap = validateVirtualEmailRecord(
      record,
      configuration,
      existingVirtualEmails
    );

    if (validationErrorMap.size === 0) {
      onSave(record);
      setValidationErrorMap(new Map());
    } else {
      setValidationErrorMap(validationErrorMap);
    }
  };

  const handleClose = () => {
    setValidationErrorMap(new Map());
    onClose();
  };

  const { AllowedRecipientDomains = [], AllowedOwnerDomains = [] } =
    configuration;
  const virtualEmailDomains = getVirtualEmailDomainsFromConfig(configuration);
  const virtualEmailError = validationErrorMap.get(
    VirtualEmailValidatedProps.ID
  );
  const recipientsError = validationErrorMap.get(
    VirtualEmailValidatedProps.RECIPIENTS
  );
  const ownerError = validationErrorMap.get(VirtualEmailValidatedProps.OWNER);
  return (
    <Box sx={{ ...containerProps }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ width: "100%" }}
            margin="dense"
            id="virtualEmail"
            label={t("virtualEmail")}
            variant="standard"
            value={editedRecord.id || ""}
            onChange={(e) =>
              setEditedRecord({ ...editedRecord, id: e.target.value })
            }
            disabled={record.id != null}
            error={virtualEmailError != null}
            helperText={
              virtualEmailError
                ? `${virtualEmailError} ${t(
                    "orIsAlreadyExisting"
                  ).toLocaleLowerCase()}`
                : null
            }
          />
          <Typography variant="caption">
            {`${t("allowedDomains")}: ${virtualEmailDomains.join(", ")}`}
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            multiline
            margin="dense"
            id="recipients"
            label={t("recipients")}
            variant="standard"
            value={recipients}
            onChange={(e) => setRecipients(e.target.value)}
            error={recipientsError != null}
            helperText={recipientsError}
          />
          <Typography variant="caption">
            {`${t("allowedDomains")}: ${AllowedRecipientDomains.join(", ")}`}
          </Typography>
          <TextField
            sx={{ width: "100%", mt: 2 }}
            margin="dense"
            id="owner"
            label={t("ownerEmail")}
            variant="standard"
            value={editedRecord.owner || ""}
            onChange={(e) =>
              setEditedRecord({ ...editedRecord, owner: e.target.value })
            }
            error={ownerError != null}
            helperText={ownerError}
          />
          <Typography variant="caption">
            {`${t("allowedDomains")}: ${AllowedOwnerDomains.join(", ")}`}
          </Typography>
          <FormControlLabel
            sx={{ mt: 2, mx: 0 }}
            value="start"
            control={
              <Checkbox
                checked={editedRecord.managed === true}
                onChange={(e) => {
                  setEditedRecord({
                    ...editedRecord,
                    managed: e.target.checked,
                  });
                }}
              />
            }
            label={t("managed")}
            labelPlacement="start"
          />
          <Alert severity="info" sx={{ my: 2 }}>
            {t("virtualEmailManagedInfo")}
          </Alert>
        </DialogContent>
        <DialogActions>
          {saving === true && <CircularProgress size={20} sx={{ mr: 1 }} />}
          <Button
            variant="contained"
            disabled={saving === true}
            onClick={() => handleSave()}
          >
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            disabled={saving === true}
            onClick={handleClose}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

EditVirtualEmailRecordDialog.propTypes = {
  record: PropTypes.object,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
};

EditVirtualEmailRecordDialog.defaultProps = {
  record: {},
  disabled: false,
  saving: false,
};

export default EditVirtualEmailRecordDialog;
