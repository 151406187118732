import { Service } from "../components/common";

const Decimal = require("decimal.js");

const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const createCategories = (t) => {
  return MONTHS.map((month) => t(`months.${month}`));
};

export const createAggrecatedChartData = (t, billingRecordsMonthlyMap) => {
  // helper function for getting cost
  const getCost = (service, month, records) => {
    const record = records.find((record) => {
      return record.monthNumber === month && record.service === service;
    });
    return record != null ? new Decimal(record.preTaxCost).toFixed(1) : 0;
  };

  const serviceMap = MONTHS.reduce(
    (acc, monthKey) => {
      const monthEntry = billingRecordsMonthlyMap.get(monthKey);
      acc
        .get(Service.AWS)
        .push(getCost(Service.AWS, monthKey, monthEntry.records));
      acc
        .get(Service.AZURE)
        .push(getCost(Service.AZURE, monthKey, monthEntry.records));
      acc
        .get(Service.GCP)
        .push(getCost(Service.GCP, monthKey, monthEntry.records));
      acc
        .get(Service.AWS_OLD)
        .push(getCost(Service.AWS_OLD, monthKey, monthEntry.records));
      return acc;
    },
    new Map([
      [Service.AWS, []],
      [Service.AZURE, []],
      [Service.GCP, []],
      [Service.AWS_OLD, []],
    ]),
  );

  return {
    categories: createCategories(t),
    series: Array.from(serviceMap.keys()).map((key) => {
      const data = serviceMap.get(key);
      return {
        name: key,
        data,
      };
    }),
  };
};

export const createAccountsChartData = (
  t,
  billingRecordsMonthlyMap,
  service,
) => {
  const serie = MONTHS.reduce(
    (acc, monthKey) => {
      const monthEntry = billingRecordsMonthlyMap.get(monthKey);
      acc.data.push(new Decimal(monthEntry.preTaxTotalCost).toFixed(1));
      return acc;
    },
    { name: service, data: [] },
  );

  return {
    categories: createCategories(t),
    series: [serie],
  };
};

export const createSingleAccountChartData = (
  t,
  billingRecordsMonthlyMap,
  accountId,
) => {
  const serie = MONTHS.reduce(
    (acc, monthKey) => {
      const monthEntry = billingRecordsMonthlyMap.get(monthKey);
      const record = monthEntry.records.find(
        (record) => record.accountId === accountId,
      );

      let cost = 0;
      if (record != null) {
        cost = new Decimal(record.preTaxCost).toFixed(0);

        // update name
        if (acc.name == null) {
          acc.name = record.accountName || accountId;
        }
      }

      acc.data.push(cost);
      return acc;
    },
    { name: null, data: [] },
  );

  return {
    categories: createCategories(t),
    series: [serie],
  };
};
