import React from "react";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../layout/layout";
import PageHeaderText from "../pageHeaderText/pageHeaderText";
import { Outlet } from "react-router-dom";

export const AccountsPaths = Object.freeze({
  LIST: "",
  ACCOUNT_DETAILS: ":service/:accountId",
});

const Accounts = () => {
  const { t } = useTranslation();
  return (
    <Layout p={2}>
      <PageHeaderText text={t("accounts")} />
      <Paper>
        <Outlet />
      </Paper>
    </Layout>
  );
};

export default Accounts;
