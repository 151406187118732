import PropTypes from "prop-types";
import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  CircularProgress,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchAppClient } from "../../../redux/reducers/adminReducer";
import { Stack } from "@mui/system";
import moment from "moment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { White } from "../../../theme/colors";
import { Buffer } from "buffer";

const AppClientEntry = ({
  appClient,
  fetchingDetails,
  onDelete,
  onEdit,
  ...containerPops
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showBasicHeader, setShowBasicHeader] = useState(false);

  const onChange = (value) => {
    // Opened and fetched?
    if (value === true) {
      const fetchedAlready =
        appClient.ClientId !== null && appClient.ClientSecret != null;
      if (fetchedAlready === false) {
        dispatch(fetchAppClient(appClient.ClientId));
      }
    } else {
      setShowPassword(false);
    }

    setOpen(value);
  };

  const getScopeNames = () => {
    if (appClient != null && appClient.AllowedOAuthScopes != null) {
      return appClient.AllowedOAuthScopes.map((fullScope) => {
        const split = fullScope.split("/");
        return split[1];
      }).join(", ");
    }
    return [];
  };

  const { ClientId, ClientSecret, CreationDate } = appClient;
  const basicHeaderValue = `Basic ${Buffer.from(
    `${ClientId}:${ClientSecret}`,
  ).toString("base64")}`;
  return (
    <Box sx={{ ...containerPops }}>
      <Accordion expanded={open} onChange={(_e, value) => onChange(value)}>
        <AccordionSummary
          sx={{
            backgroundColor: open ? "primary.main" : "primary.contrastText",
            color: open ? "primary.contrastText" : "default",
          }}
          expandIcon={
            <ExpandMoreIcon
              sx={{ color: open ? "primary.contrastText" : "default" }}
            />
          }
        >
          <Typography sx={{ minWidth: 100 }} variant="subtitle1">
            {appClient.ClientName}
          </Typography>
          {fetchingDetails === true && (
            <CircularProgress
              sx={{ ml: 1, color: "primary.light" }}
              size={25}
            />
          )}
          <Box flexGrow={1} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              px: 1,
              backgroundColor: White,
              borderRadius: 2,
            }}
          >
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(appClient);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("delete")}>
              <span>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(appClient);
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{`${t("clientId")}:`}</Typography>
              <Typography sx={{ ml: 1, fontWeight: "700" }}>
                {ClientId}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{`${t("clientSecret")}:`}</Typography>
              <TextField
                sx={{ ml: 1, fontWeight: "700", width: "50%" }}
                value={ClientSecret || ""}
                type={showPassword === true ? "text" : "password"}
                placeholder="password"
                size="small"
                variant="standard"
                disabled
              />
              <IconButton
                color="primary"
                onClick={() => setShowPassword((s) => !s)}
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
            <Box>
              <Typography>{`${t("basicAuthHeader")}:`}</Typography>
              <TextField
                sx={{ ml: 1, fontWeight: "700", width: "50%" }}
                value={basicHeaderValue}
                type={showBasicHeader === true ? "text" : "password"}
                placeholder="password"
                size="small"
                variant="standard"
                disabled
              />
              <IconButton
                color="primary"
                onClick={() => setShowBasicHeader((s) => !s)}
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{`${t("created")}:`}</Typography>
              <Typography sx={{ ml: 1, fontWeight: "700" }}>
                {CreationDate
                  ? moment(CreationDate).format("DD.MM.YYYY HH:mm:ss")
                  : "-"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{`${t("scopes")}:`}</Typography>
              <Typography sx={{ ml: 1, fontWeight: "700" }}>
                {getScopeNames()}
              </Typography>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

AppClientEntry.propTypes = {
  appClient: PropTypes.object,
  fetchingDetails: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AppClientEntry;
