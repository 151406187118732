import React, { useEffect, useState } from "react";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../layout/layout";
import PageHeaderText from "../pageHeaderText/pageHeaderText";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const LogSource = Object.freeze({
  AWS: "aws",
  AZURE: "azure",
  GCP: "gcp",
});
export const LogPaths = LogSource;

const Logs = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(LogPaths.AWS);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    Object.values(LogPaths).forEach((path) => {
      if (location.pathname.includes(path)) {
        setValue(path);
      }
    });
  }, [location.pathname, setValue]);

  const onTabChanged = (newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Layout p={2}>
      <PageHeaderText text={t("errorLogs")} />
      <Paper sx={{ p: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(_e, newValue) => onTabChanged(newValue)}
          >
            <Tab value={LogPaths.AWS} label={t("aws")} />
            <Tab disabled value={LogPaths.AZURE} label={t("azure")} />
            <Tab disabled value={LogPaths.GCP} label={t("gcp")} />
          </Tabs>
        </Box>
        <Outlet />
      </Paper>
    </Layout>
  );
};

export default Logs;
