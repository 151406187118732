import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import SortableTableCell from "../../table/sortableTableCell";
import { SortDirection, getComparator } from "../../../utils/table";
import CostCenterStr from "../tilkka/costCenterStr";
import Decimal from "decimal.js";
import { getCurrencyStr } from "../../../utils/billing";
import AccountInfoPopover from "./accountInfoPopover";

const SortProps = Object.freeze({
  ACCOUNT_NAME: "accountName",
  COST: "cost",
  PRE_TAX_COST: "preTaxCost",
  RESOURCE_COST: "resourceCost",
  DISCOUNT_COST: "discountCost",
  COST_CENTER: "costCenter.value",
});

const BillingDataTable = ({ records, ...containerPops }) => {
  const { t } = useTranslation();
  const [sortedRecords, setSortedRecords] = useState([]);
  const [order, setOrder] = useState(SortDirection.Descending);
  const [orderByProp, setOrderByProp] = useState(SortProps.ACCOUNT_NAME);

  useEffect(() => {
    setSortedRecords(
      records.map((record) => {
        const {
          preTaxCost,
          discountCost,
          savingsPlanCost,
          accountNameCloud,
          accountName,
          savingsPlanCoverage,
          discountCoverage,
        } = record;

        // There are some legacy props that needs to be handled
        return {
          ...record,
          accountName: accountNameCloud || accountName,
          resourceCost: new Decimal(preTaxCost || 0)
            .minus(savingsPlanCost || discountCost || 0)
            .toFixed(2),
          discountCoverage: new Decimal(
            savingsPlanCoverage || discountCoverage || 0,
          ).toFixed(2),
          discountCost: new Decimal(
            savingsPlanCost || discountCost || 0,
          ).toFixed(2),
        };
      }),
    );
  }, [records, setSortedRecords]); // eslint-disable-line

  const handleRequestSort = (property) => {
    const isAsc = orderByProp === property && order === SortDirection.Ascending;
    const newOrder = isAsc ? SortDirection.Descending : SortDirection.Ascending;
    setOrder(newOrder);
    setOrderByProp(property);
    setSortedRecords(
      [...sortedRecords].sort(getComparator(newOrder, property)),
    );
  };

  const getAccountName = (row) => {
    const { accountName, accountId } = row;
    return (
      <Box>
        <Typography variant="body1" fontWeight="600">
          {accountName}
        </Typography>
        <Typography variant="body2">{accountId}</Typography>
      </Box>
    );
  };

  const getCostValue = (cost, currency) => {
    const currencyStr = getCurrencyStr(currency);
    const costDecimal = new Decimal(cost);
    if (costDecimal.greaterThan(0.99) || costDecimal.equals(0)) {
      return `${costDecimal.toFixed(2)}${currencyStr}`;
    }
    return `${costDecimal.toFixed(9)}${currencyStr}`; // GCP has the smallest unit of 0.000000001
  };

  return (
    <>
      <Alert severity="info" sx={{ my: 2 }}>
        {t("discountInfo")}
      </Alert>
      <TableContainer component={Paper} sx={{ ...containerPops }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.ACCOUNT_NAME}
                label={t("name")}
                onSort={handleRequestSort}
              />
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.PRE_TAX_COST}
                label={`${t("overallCost")} (${t("preTax")})`}
                onSort={handleRequestSort}
              />
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.COST}
                label={`${t("overallCost")} (${t("withVat")})`}
                onSort={handleRequestSort}
              />
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.RESOURCE_COST}
                label={`${t("resourceCost")} (${t("includedDiscount")}) (${t("preTax")})`}
                onSort={handleRequestSort}
              />
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.DISCOUNT_COST}
                label={`${t("discountCost")} (${t("preTax")})`}
                onSort={handleRequestSort}
              />
              <SortableTableCell
                curretSortedProperty={orderByProp}
                order={order}
                property={SortProps.COST_CENTER}
                label={t("costCenter")}
                onSort={handleRequestSort}
              />
              <TableCell sx={{ fontWeight: "bold" }}>{t("info")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRecords.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {t("itemsNotFound")}
                </TableCell>
              </TableRow>
            )}
            {(sortedRecords || []).map((record) => {
              const {
                costCenter,
                cost,
                preTaxCost,
                currency,
                discountCost,
                discountCoverage,
                resourceCost,
                id,
              } = record;

              return (
                <TableRow
                  key={id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {getAccountName(record)}
                  </TableCell>
                  <TableCell>{getCostValue(preTaxCost, currency)}</TableCell>
                  <TableCell>{getCostValue(cost, currency)}</TableCell>
                  <TableCell>{`${getCostValue(resourceCost, currency)} (${getCostValue(discountCoverage, currency)})`}</TableCell>
                  <TableCell>{getCostValue(discountCost, currency)}</TableCell>
                  <TableCell>
                    <CostCenterStr objectValue={costCenter} />
                  </TableCell>
                  <TableCell>
                    <AccountInfoPopover record={record} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

BillingDataTable.propTypes = {
  records: PropTypes.array,
};

export default BillingDataTable;
