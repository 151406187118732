import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { createAggrecatedChartData } from "../../../utils/chart";

const BillingAggregatedChart = ({ billingRecordsMonthlyMap }) => {
  const { t } = useTranslation();

  const chartData = createAggrecatedChartData(t, billingRecordsMonthlyMap);
  const data = {
    options: {
      chart: {
        id: "all-clouds-year-chart",
        stacked: true,
      },
      xaxis: {
        categories: chartData.categories,
      },
    },
    series: chartData.series,
  };

  return (
    <Box>
      <Chart options={data.options} series={data.series} type="bar" />
    </Box>
  );
};

BillingAggregatedChart.propTypes = {
  billingRecordsMonthlyMap: PropTypes.object,
};

export default BillingAggregatedChart;
