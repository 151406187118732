import axios from "../../utils/axios";

const Types = {
  CREATING_REPORT_START: "CREATING_REPORT_START",
  CREATING_REPORT_FINISH: "CREATING_REPORT_FINISH",
  CREATING_REPORT_ERROR: "CREATING_REPORT_ERROR",
  FETCHING_REPORT_URL_START: "FETCHING_REPORT_URL_START",
  FETCHING_REPORT_URL_FINISH: "FETCHING_REPORT_URL_FINISH",
  FETCHING_REPORT_URL_ERROR: "FETCHING_REPORT_URL_ERROR",
};

const API_URL = process.env.REACT_APP_API_URL;
const REPORT_API_URL = `${API_URL}report`;

export const createFileReport = (
  reportType,
  service,
  year,
  monthNumber,
  currency,
  useStoredData = false
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.CREATING_REPORT_START, payload: monthNumber });

      const body = {
        reportType,
        service,
        year,
        monthNumber,
        currency,
        useStoredData,
      };
      const url = `${REPORT_API_URL}/create`;
      const response = await axios.post(url, body);
      console.log("createFileReport", response.data);

      dispatch({ type: Types.CREATING_REPORT_FINISH, payload: monthNumber });

      return response.data;
    } catch (error) {
      dispatch({
        type: Types.CREATING_REPORT_ERROR,
        payload: { error, monthNumber },
      });
    }
  };
};

export const getFileReportUrl = (filename) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_REPORT_URL_START, payload: filename });

      const url = `${REPORT_API_URL}/url?filename=${filename}`;
      const response = await axios.get(url);
      console.log("getFileReportUrl", response.data);

      dispatch({ type: Types.FETCHING_REPORT_URL_FINISH });
      return response.data.url;
    } catch (error) {
      dispatch({
        type: Types.FETCHING_REPORT_URL_ERROR,
        payload: error,
      });
      return null;
    }
  };
};

const INIT_STATE = {
  creatingReportDataMap: new Map(),
  createReportError: null,
  fetchingReportUrlForFilename: false,
  fetchReportUrlError: null,
};

export const reportReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Types.CREATING_REPORT_START: {
      const { creatingReportDataMap } = state;
      creatingReportDataMap.set(action.payload, true);
      return {
        ...state,
        creatingReportDataMap: new Map(creatingReportDataMap),
        createReportError: null,
      };
    }
    case Types.CREATING_REPORT_FINISH: {
      const { creatingReportDataMap } = state;
      creatingReportDataMap.set(action.payload, false);
      return {
        ...state,
        creatingReportDataMap: new Map(creatingReportDataMap),
      };
    }
    case Types.CREATING_REPORT_ERROR: {
      const { creatingReportDataMap } = state;
      const { error, monthNumber } = action.payload;
      creatingReportDataMap.set(monthNumber, false);
      return {
        ...state,
        creatingReportDataMap: new Map(creatingReportDataMap),
        createReportError: error,
      };
    }
    case Types.FETCHING_REPORT_URL_START:
      return {
        ...state,
        fetchingReportUrlForFilename: action.payload,
        fetchReportUrlError: null,
      };
    case Types.FETCHING_REPORT_URL_FINISH:
      return { ...state, fetchingReportUrlForFilename: null };
    case Types.FETCHING_REPORT_URL_ERROR:
      return {
        ...state,
        fetchingReportUrlForFilename: null,
        fetchReportUrlError: action.payload,
      };
    default:
      return state;
  }
};
