import { SapBillingDataType } from "../../components/common";
import axios from "../../utils/axios";

const Types = {
  FETCHING_TAXCODES_START: "FETCHING_TAXCODES_START",
  FETCHING_TAXCODES_FINISH: "FETCHING_TAXCODES_FINISH",
  FETCHING_TAXCODES_ERROR: "FETCHING_TAXCODES_ERROR",
  FETCHING_COSTCENTERS_START: "FETCHING_COSTCENTERS_START",
  FETCHING_COSTCENTERS_FINISH: "FETCHING_COSTCENTERS_FINISH",
  FETCHING_COSTCENTERS_ERROR: "FETCHING_COSTCENTERS_ERROR",
  FETCHING_BILLINGACCOUNTNUMBERS_START: "FETCHING_BILLINGACCOUNTNUMBERS_START",
  FETCHING_BILLINGACCOUNTNUMBERS_FINISH:
    "FETCHING_BILLINGACCOUNTNUMBERS_FINISH",
  FETCHING_BILLINGACCOUNTNUMBERS_ERROR: "FETCHING_BILLINGACCOUNTNUMBERS_ERROR",
  FETCHING_INTERNALORDERNUMBERS_START: "FETCHING_INTERNALORDERNUMBERS_START",
  FETCHING_INTERNALORDERNUMBERS_FINISH: "FETCHING_INTERNALORDERNUMBERS_FINISH",
  FETCHING_INTERNALORDERNUMBERS_ERROR: "FETCHING_INTERNALORDERNUMBERS_ERROR",
  FETCHING_PRODUCTCODES_START: "FETCHING_PRODUCTCODES_START",
  FETCHING_PRODUCTCODES_FINISH: "FETCHING_PRODUCTCODES_FINISH",
  FETCHING_PRODUCTCODES_ERROR: "FETCHING_PRODUCTCODES_ERROR",
  FETCHING_PURCHASEGROUPNUMBERS_START: "FETCHING_PURCHASEGROUPNUMBERS_START",
  FETCHING_PURCHASEGROUPNUMBERS_FINISH: "FETCHING_PURCHASEGROUPNUMBERS_FINISH",
  FETCHING_PURCHASEGROUPNUMBERS_ERROR: "FETCHING_PURCHASEGROUPNUMBERS_ERROR",
};

const API_URL = process.env.REACT_APP_API_URL;
const SAP_BILLING_DATA_API_URL = `${API_URL}sap-billing-data`;

let abortController;

const abortCall = () => {
  if (abortController != null) {
    abortController.abort();
  }
};

const getAbortController = () => {
  abortCall();
  abortController = new AbortController();
  return abortController;
};

const getUrl = (type, cursor) => {
  let url = `${SAP_BILLING_DATA_API_URL}/${type}`;
  if (cursor != null) {
    url = `${url}?cursor=${cursor}`;
  }
  return url;
};

export const fetchTaxCodes = (cursor = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCHING_TAXCODES_START });

      const url = getUrl(SapBillingDataType.TaxCode, cursor);
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      const result = response.data;
      console.log("fetchTaxCodes", result);

      const existingItems = getState().sapBilling.taxCodes;
      dispatch({
        type: Types.FETCHING_TAXCODES_FINISH,
        payload: {
          items: [...existingItems, ...result.items],
          cursor: result.cursor,
        },
      });

      return Promise.resolve(result);
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_TAXCODES_ERROR,
          payload: error.message,
        });
        return Promise.reject(error);
      }
    }
  };
};

export const fetchBillingAccountNumbers = (cursor = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCHING_BILLINGACCOUNTNUMBERS_START });

      const url = getUrl(SapBillingDataType.BillingAccountNumber, cursor);
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      const result = response.data;
      console.log("fetchBillingAccountNumbers", result);

      const existingItems = getState().sapBilling.billingAccountNumbers;
      dispatch({
        type: Types.FETCHING_BILLINGACCOUNTNUMBERS_FINISH,
        payload: {
          items: [...existingItems, ...result.items],
          cursor: result.cursor,
        },
      });

      return Promise.resolve(result);
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_BILLINGACCOUNTNUMBERS_ERROR,
          payload: error.message,
        });
        return Promise.reject(error);
      }
    }
  };
};

export const fetchInternalOrderNumbers = (cursor = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCHING_INTERNALORDERNUMBERS_START });

      const url = getUrl(SapBillingDataType.InternalOrderNumber, cursor);
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      const result = response.data;
      console.log("fetchInternalOrderNumbers", result);

      const existingItems = getState().sapBilling.internalOrderNumbers;
      dispatch({
        type: Types.FETCHING_INTERNALORDERNUMBERS_FINISH,
        payload: {
          items: [...existingItems, ...result.items],
          cursor: result.cursor,
        },
      });

      return Promise.resolve(result);
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_INTERNALORDERNUMBERS_ERROR,
          payload: error.message,
        });
        return Promise.reject(error);
      }
    }
  };
};

export const fetchCostCenters = (cursor = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCHING_COSTCENTERS_START });

      const url = getUrl(SapBillingDataType.CostCenter, cursor);
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      const result = response.data;
      console.log("fetchCostCenters", result);

      const existingItems = getState().sapBilling.costCenters;
      dispatch({
        type: Types.FETCHING_COSTCENTERS_FINISH,
        payload: {
          items: [...existingItems, ...result.items],
          cursor: result.cursor,
        },
      });

      return Promise.resolve(result);
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_COSTCENTERS_ERROR,
          payload: error.message,
        });
        return Promise.reject(error);
      }
    }
  };
};

export const fetchProductCodes = (cursor = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCHING_PRODUCTCODES_START });

      const url = getUrl(SapBillingDataType.ProductCode, cursor);
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      const result = response.data;
      console.log("fetchProductCodes", result);

      const existingItems = getState().sapBilling.productCodes;
      dispatch({
        type: Types.FETCHING_PRODUCTCODES_FINISH,
        payload: {
          items: [...existingItems, ...result.items],
          cursor: result.cursor,
        },
      });

      return Promise.resolve(result);
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_PRODUCTCODES_ERROR,
          payload: error.message,
        });

        return Promise.reject(error);
      }
    }
  };
};

export const fetchPurchaseGroupNumbers = (cursor = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCHING_PURCHASEGROUPNUMBERS_START });

      const url = getUrl(SapBillingDataType.PurchaseGroupNumber, cursor);
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      const result = response.data;
      console.log("fetchPurchaseGroupNumbers", result);

      const existingItems = getState().sapBilling.purchaseGroupNumbers;
      dispatch({
        type: Types.FETCHING_PURCHASEGROUPNUMBERS_FINISH,
        payload: {
          items: [...existingItems, ...result.items],
          cursor: result.cursor,
        },
      });

      return Promise.resolve(result);
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_PURCHASEGROUPNUMBERS_ERROR,
          payload: error.message,
        });

        return Promise.reject(error);
      }
    }
  };
};

const INIT_STATE = {
  taxCodes: [],
  fetchingTaxCodes: false,
  taxCodesFetchError: null,
  taxCodesCursor: null,
  costCenters: [],
  fetchingCostCenters: false,
  costCentersFetchError: null,
  costCenterCursor: null,
  billingAccountNumbers: [],
  fetchingBillingAccountNumbers: false,
  billingAccountNumbersFetchError: null,
  billingAccountNumbersCursor: null,
  internalOrderNumbers: [],
  fetchingInternalOrderNumbers: false,
  internalOrderNumbersFetchError: null,
  internalOrderNumbersCursor: null,
  productCodes: [],
  fetchingProductCodes: false,
  productCodesFetchError: null,
  productCodesCursor: null,
  purchaseGroupNumbers: [],
  purchaseGroupFetchError: null,
  fetchingPurchaseGroupNumbers: false,
  purchaseGroupNumbersCursor: null,
};

export const sapBillingDataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.FETCHING_TAXCODES_START:
      return { ...state, fetchingTaxCodes: true, taxCodesFetchError: null };
    case Types.FETCHING_TAXCODES_FINISH: {
      const { items, cursor } = action.payload;
      return {
        ...state,
        fetchingTaxCodes: false,
        taxCodes: items,
        taxCodesCursor: cursor,
      };
    }
    case Types.FETCHING_TAXCODES_ERROR:
      return {
        ...state,
        fetchingTaxCodes: false,
        taxCodesFetchError: action.payload,
      };
    case Types.FETCHING_COSTCENTERS_START:
      return {
        ...state,
        fetchingCostCenters: true,
        costCentersFetchError: null,
      };
    case Types.FETCHING_COSTCENTERS_FINISH: {
      const { items, cursor } = action.payload;
      return {
        ...state,
        fetchingCostCenters: false,
        costCenters: items,
        costCenterCursor: cursor,
      };
    }
    case Types.FETCHING_COSTCENTERS_ERROR:
      return {
        ...state,
        fetchingCostCenters: false,
        costCentersFetchError: action.payload,
      };
    case Types.FETCHING_BILLINGACCOUNTNUMBERS_START:
      return {
        ...state,
        fetchingBillingAccountNumbers: true,
        billingAccountNumbersFetchError: null,
      };
    case Types.FETCHING_BILLINGACCOUNTNUMBERS_FINISH: {
      const { items, cursor } = action.payload;
      return {
        ...state,
        fetchingBillingAccountNumbers: false,
        billingAccountNumbers: items,
        billingAccountNumbersCursor: cursor,
      };
    }
    case Types.FETCHING_BILLINGACCOUNTNUMBERS_ERROR:
      return {
        ...state,
        fetchingBillingAccountNumbers: false,
        billingAccountNumbersFetchError: action.payload,
      };
    case Types.FETCHING_INTERNALORDERNUMBERS_START:
      return {
        ...state,
        fetchingInternalOrderNumbers: true,
        internalOrderNumbersFetchError: null,
      };
    case Types.FETCHING_INTERNALORDERNUMBERS_FINISH: {
      const { items, cursor } = action.payload;
      return {
        ...state,
        fetchingInternalOrderNumbers: false,
        internalOrderNumbers: items,
        internalOrderNumbersCursor: cursor,
      };
    }
    case Types.FETCHING_INTERNALORDERNUMBERS_ERROR:
      return {
        ...state,
        fetchingInternalOrderNumbers: false,
        internalOrderNumbersFetchError: action.payload,
      };
    case Types.FETCHING_PRODUCTCODES_START:
      return {
        ...state,
        fetchingProductCodes: true,
        productCodesFetchError: null,
      };
    case Types.FETCHING_PRODUCTCODES_FINISH: {
      const { items, cursor } = action.payload;
      return {
        ...state,
        fetchingProductCodes: false,
        productCodes: items,
        productCodesCursor: cursor,
      };
    }
    case Types.FETCHING_PRODUCTCODES_ERROR:
      return {
        ...state,
        fetchingProductCodes: false,
        productCodesFetchError: action.payload,
      };
    case Types.FETCHING_PURCHASEGROUPNUMBERS_START:
      return {
        ...state,
        fetchingPurchaseGroupNumbers: true,
        purchaseGroupFetchError: null,
      };
    case Types.FETCHING_PURCHASEGROUPNUMBERS_FINISH: {
      const { items, cursor } = action.payload;
      return {
        ...state,
        fetchingPurchaseGroupNumbers: false,
        purchaseGroupNumbers: items,
        purchaseGroupNumbersCursor: cursor,
      };
    }
    case Types.FETCHING_PURCHASEGROUPNUMBERS_ERROR:
      return {
        ...state,
        fetchingPurchaseGroupNumbers: false,
        purchaseGroupFetchError: action.payload,
      };
    default:
      return state;
  }
};
