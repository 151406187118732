import { Box, Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LoadMoreButton = ({ onClick, visible, loading, ...containerProps }) => {
  const { t } = useTranslation();
  if (visible === false) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...containerProps,
      }}
    >
      {loading === false && (
        <Button size="small" variant="outlined" onClick={onClick}>
          {t("loadMore")}
        </Button>
      )}
      {loading === true && <CircularProgress size={25} />}
    </Box>
  );
};

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LoadMoreButton;
