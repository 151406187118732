import { combineReducers } from "redux";
import { adminReducer } from "./adminReducer";
import { billingReducer } from "./billingReducer";
import { notificationReducer } from "./notificationReducer";
import { tilkkaReducer } from "./tilkkaReducer";
import { userReducer } from "./userReducer";
import { sapBillingDataReducer } from "./sapBillingDataReducer";
import { docsReducer } from "./docsReducer";
import { auditLogReducer } from "./auditLogReducer";
import { virtualEmailReducer } from "./virtualEmailReducer";
import { reportReducer } from "./reportReducer";
import { accountsReducer } from "./accountsReducer";
import { logsReducer } from "./logsReducer";
import { azureApplicationsReducer } from "./azureApplicationsReducer";

const createRootReducer = () =>
  combineReducers({
    billing: billingReducer,
    tilkka: tilkkaReducer,
    user: userReducer,
    notification: notificationReducer,
    admin: adminReducer,
    sapBilling: sapBillingDataReducer,
    docs: docsReducer,
    auditLog: auditLogReducer,
    virtualEmail: virtualEmailReducer,
    report: reportReducer,
    account: accountsReducer,
    log: logsReducer,
    azureApplication: azureApplicationsReducer,
  });

export default createRootReducer;
