import { sortAppClientsByName } from "../../utils/appClient";
import axios from "../../utils/axios";

const Types = {
  FETCHING_APP_CLIENTS_START: "FETCHING_APP_CLIENTS_START",
  FETCHING_APP_CLIENTS_FINISH: "FETCHING_APP_CLIENTS_FINISH",
  FETCHING_APP_CLIENTS_ERROR: "FETCHING_APP_CLIENTS_ERROR",
  FETCHING_APP_CLIENT_START: "FETCHING_APP_CLIENT_START",
  FETCHING_APP_CLIENT_FINISH: "FETCHING_APP_CLIENT_FINISH",
  FETCHING_APP_CLIENT_ERROR: "FETCHING_APP_CLIENT_ERROR",
  UPDATE_APP_CLIENTS: "UPDATE_APP_CLIENTS",
  SENDING_APP_CLIENT_START: "SENDING_APP_CLIENT_START",
  SENDING_APP_CLIENT_FINISH: "SENDING_APP_CLIENT_FINISH",
  SENDING_APP_CLIENT_ERROR: "SENDING_APP_CLIENT_ERROR",
  FETCHING_SCOPES_START: "FETCHING_SCOPES_START",
  FETCHING_SCOPES_FINISH: "FETCHING_SCOPES_FINISH",
  FETCHING_SCOPES_ERROR: "FETCHING_SCOPES_ERROR",
};

const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = `${API_URL}admin`;
let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

export const createNewAppClient = (name, scopes) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.SENDING_APP_CLIENT_START });

      const url = `${ADMIN_API_URL}/app-client`;
      const response = await axios.post(url, {
        name,
        scopes,
      });
      console.log("createNewAppClient", response.data);

      const appClients = getState().admin.appClients.slice(0);
      appClients.push(response.data);

      dispatch({
        type: Types.UPDATE_APP_CLIENTS,
        payload: sortAppClientsByName(appClients),
      });

      dispatch({ type: Types.SENDING_APP_CLIENT_FINISH });

      return Promise.resolve(response.data);
    } catch (error) {
      dispatch({
        type: Types.SENDING_APP_CLIENT_ERROR,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

export const updateAppClientScopes = (appClientId, scopes) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.SENDING_APP_CLIENT_START });

      const url = `${ADMIN_API_URL}/app-client/scopes`;
      const response = await axios.post(url, {
        appClientId,
        scopes,
      });
      console.log("updateAppClientScopes", response.data);

      const appClients = getState().admin.appClients.slice(0);
      const updatedAppClient = response.data;
      const index = appClients.findIndex(
        (appClient) => appClient.ClientId === appClientId,
      );
      if (index !== -1) {
        appClients.splice(index, 1, updatedAppClient);
      }

      dispatch({
        type: Types.UPDATE_APP_CLIENTS,
        payload: sortAppClientsByName(appClients),
      });

      dispatch({ type: Types.SENDING_APP_CLIENT_FINISH });

      return Promise.resolve(updatedAppClient);
    } catch (error) {
      dispatch({
        type: Types.SENDING_APP_CLIENT_ERROR,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

export const deleteAppClient = (clientId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.SENDING_APP_CLIENT_START });

      const url = `${ADMIN_API_URL}/app-client/${clientId}`;
      const response = await axios.delete(url);
      console.log("deleteAppClient", response.data);

      const appClients = getState().admin.appClients.slice(0);
      const index = appClients.findIndex((ac) => ac.ClientId === clientId);
      appClients.splice(index, 1);

      dispatch({
        type: Types.UPDATE_APP_CLIENTS,
        payload: sortAppClientsByName(appClients),
      });

      dispatch({ type: Types.SENDING_APP_CLIENT_FINISH });

      return Promise.resolve(response.data);
    } catch (error) {
      dispatch({
        type: Types.SENDING_APP_CLIENT_ERROR,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

export const fetchAppClients = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_APP_CLIENTS_START });

      const url = `${ADMIN_API_URL}/app-client`;

      const response = await axios.get(url);
      console.log("fetchAppClients", response.data);

      dispatch({
        type: Types.UPDATE_APP_CLIENTS,
        payload: sortAppClientsByName(response.data),
      });

      dispatch({ type: Types.FETCHING_APP_CLIENTS_FINISH });
    } catch (error) {
      dispatch({
        type: Types.FETCHING_APP_CLIENTS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const fetchAvailableScopes = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_SCOPES_START });

      const url = `${ADMIN_API_URL}/integration-scopes`;
      const response = await axios.get(url);
      console.log("fetchAvailableScopes", response.data);

      dispatch({ type: Types.FETCHING_SCOPES_FINISH, payload: response.data });
    } catch (error) {
      dispatch({
        type: Types.FETCHING_APP_CLIENTS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const fetchAppClient = (clientId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCHING_APP_CLIENT_START, payload: clientId });

      const url = `${ADMIN_API_URL}/app-client/${clientId}`;
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchAppClient", response.data);

      const fullAppClient = response.data;
      const appClients = getState().admin.appClients.slice(0);
      const index = appClients.findIndex(
        (ac) => ac.ClientId === fullAppClient.ClientId,
      );
      appClients.splice(index, 1, fullAppClient);

      dispatch({
        type: Types.UPDATE_APP_CLIENTS,
        payload: sortAppClientsByName(appClients),
      });

      dispatch({ type: Types.FETCHING_APP_CLIENT_FINISH, payload: clientId });

      return Promise.resolve(fullAppClient);
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_APP_CLIENT_ERROR,
          payload: { id: clientId, error: error.message },
        });
        return Promise.reject(error);
      }
    }
  };
};

const INIT_STATE = {
  appClients: [],
  fetchingAppClients: false,
  appClientsFetchingError: null,
  fetchingAppClientMap: new Map(),
  appClientFetchingError: null,
  sendingAppClient: false,
  appClientSendingError: null,
  availableScopes: [],
  fetchingScopes: false,
  scopeFetchingError: null,
};

export const adminReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.FETCHING_APP_CLIENTS_START:
      return {
        ...state,
        fetchingAppClients: true,
        appClientsFetchingError: null,
      };
    case Types.FETCHING_APP_CLIENTS_FINISH:
      return {
        ...state,
        fetchingAppClients: false,
      };
    case Types.FETCHING_APP_CLIENTS_ERROR:
      return {
        ...state,
        fetchingAppClients: false,
        appClientsFetchingError: action.payload,
      };
    case Types.FETCHING_APP_CLIENT_START: {
      const { fetchingAppClientMap } = state;
      fetchingAppClientMap.set(action.payload, true);
      return {
        ...state,
        fetchingAppClientMap: new Map(fetchingAppClientMap),
        appClientFetchingError: null,
      };
    }
    case Types.FETCHING_APP_CLIENT_FINISH: {
      const { fetchingAppClientMap } = state;
      fetchingAppClientMap.set(action.payload, false);
      return {
        ...state,
        fetchingAppClientMap: new Map(fetchingAppClientMap),
      };
    }
    case Types.FETCHING_APP_CLIENT_ERROR: {
      const { fetchingAppClientMap } = state;
      const { id, error } = action.payload;
      fetchingAppClientMap.set(id, false);
      return {
        ...state,
        fetchingAppClientMap: new Map(fetchingAppClientMap),
        appClientFetchingError: error,
      };
    }
    case Types.UPDATE_APP_CLIENTS:
      return { ...state, appClients: action.payload };
    case Types.SENDING_APP_CLIENT_START:
      return {
        ...state,
        sendingAppClient: true,
        appClientSendingError: null,
      };
    case Types.SENDING_APP_CLIENT_FINISH:
      return { ...state, sendingAppClient: false };
    case Types.SENDING_APP_CLIENT_ERROR:
      return {
        ...state,
        sendingAppClient: false,
        appClientSendingError: action.payload,
      };
    case Types.FETCHING_SCOPES_START:
      return { ...state, fetchingScopes: true, scopeFetchingError: null };
    case Types.FETCHING_SCOPES_FINISH:
      return {
        ...state,
        fetchingScopes: false,
        availableScopes: action.payload,
      };
    case Types.ETCHING_SCOPES_ERROR:
      return {
        ...state,
        fetchingScopes: false,
        scopeFetchingError: action.payload,
      };
    default:
      return state;
  }
};
