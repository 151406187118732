import { Roles } from "./roles";

export const Permission = Object.freeze({
  BILLING_VIEW: "BILLING_VIEW",
  BILLING_EDIT: "BILLING_EDIT",
  TILKKA_VIEW: "TILKKA_VIEW",
  TILKKA_EDIT: "TILKKA_EDIT",
  INTEGRATION_VIEW: "INTEGRATION_VIEW",
  INTEGRATION_EDIT: "INTEGRATION_EDIT",
  ADMIN: "ADMIN",
  VIRTUAL_EMAIL_VIEW: "VIRTUAL_EMAIL_VIEW",
  VIRTUAL_EMAIL_EDIT: "VIRTUAL_EMAIL_EDIT",
  VIRTUAL_EMAIL_DELETE: "VIRTUAL_EMAIL_DELETE",
  ACCOUNT_VIEW: "ACCOUNT_VIEW",
  MANAGEMENT: "MANAGEMENT",
});

export const permissionToRoles = (permission) => {
  switch (permission) {
    case Permission.BILLING_VIEW:
      return [Roles.ADMIN, Roles.USER, Roles.VIEWER];
    case Permission.BILLING_EDIT:
      return [Roles.ADMIN, Roles.USER];
    case Permission.TILKKA_VIEW:
      return [Roles.ADMIN, Roles.USER, Roles.VIEWER];
    case Permission.TILKKA_EDIT:
      return [Roles.ADMIN, Roles.USER];
    case Permission.INTEGRATION_VIEW:
      return [Roles.ADMIN, Roles.USER, Roles.VIEWER];
    case Permission.INTEGRATION_EDIT:
      return [Roles.ADMIN];
    case Permission.VIRTUAL_EMAIL_VIEW:
      return [Roles.ADMIN, Roles.USER, Roles.VIEWER];
    case Permission.VIRTUAL_EMAIL_EDIT:
      return [Roles.ADMIN, Roles.USER];
    case Permission.VIRTUAL_EMAIL_DELETE:
      return [Roles.ADMIN];
    case Permission.ACCOUNT_VIEW:
      return [Roles.ADMIN, Roles.USER, Roles.VIEWER];
    case Permission.ADMIN:
      return [Roles.ADMIN];
    case Permission.MANAGEMENT:
      return [Roles.ADMIN, Roles.USER, Roles.VIEWER];
    default:
      return [];
  }
};

export const hasPermission = (user, permission) => {
  if (user == null) {
    return false;
  }

  const userRoles = user.roles || [];
  const permiossionRoles = permissionToRoles(permission);

  /*
  console.log("user", user);
  console.log("userRoles", userRoles);
  console.log("permission", permission, "permiossionRoles", permiossionRoles);
  */

  for (const permissionRole of permiossionRoles) {
    if (userRoles.includes(permissionRole)) {
      return true;
    }
  }
  return false;
};
