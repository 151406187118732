import React from "react";
import PropTypes from "prop-types";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ServiceSelect = ({
  disabled,
  service,
  serviceTypes,
  onServiceSelected,
  error,
  helperText,
  ...containerProps
}) => {
  const { t } = useTranslation();
  return (
    <FormControl
      disabled={disabled}
      size="small"
      sx={{ ...containerProps }}
      error={error}
    >
      <InputLabel>{t("service")}</InputLabel>
      <Select
        value={service}
        label={t("service")}
        onChange={(e) => onServiceSelected(e.target.value)}
      >
        {Object.values(serviceTypes).map((value) => {
          return (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
      {helperText != null && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

ServiceSelect.propTypes = {
  disabled: PropTypes.bool,
  service: PropTypes.any,
  serviceTypes: PropTypes.object,
  onServiceSelected: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default ServiceSelect;
