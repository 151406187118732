import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ToggleValues = Object.freeze({
  DETAILS: "details",
  CHART: "chart",
});

const ChartToggle = ({ value, onToggle, ...containerProps }) => {
  const { t } = useTranslation();
  return (
    <ToggleButtonGroup
      sx={{ ...containerProps }}
      color="primary"
      value={value}
      exclusive
      onChange={(_e, value) => onToggle(value)}
      size="small"
    >
      <ToggleButton value={ToggleValues.DETAILS}>{t("details")}</ToggleButton>
      <ToggleButton value={ToggleValues.CHART}>{t("chart")}</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ChartToggle;
