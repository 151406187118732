import { Alert, Box, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideSnackbar } from "../../redux/reducers/notificationReducer";
import AdminAppBar from "./appBar";
import { useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";

const Layout = ({ children, ...containerProps }) => {
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state) => state.notification);

  useEffect(() => {
    fetchAuthSession()
      .then(() => {})
      .catch(() => {
        console.log("Session expired");
        window.location.reload();
      });
  }, []);

  return (
    <Box sx={{ height: "100vh", flexGrow: 1, backgroundColor: "grey.100" }}>
      <AdminAppBar />
      <Box sx={{ flexGrow: 1, ...containerProps }}>{children}</Box>
      <Snackbar
        open={snackbar.open}
        onClose={() => dispatch(hideSnackbar())}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => dispatch(hideSnackbar())}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Layout;
