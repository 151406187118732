import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Layout from "../layout/layout";
import { Tabs, Tab, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearBillingHistory } from "../../redux/reducers/billingReducer";
import PageHeaderText from "../pageHeaderText/pageHeaderText";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const ManagementPaths = Object.freeze({
  AZURE_APPS: "azure-apps",
});

const Management = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(ManagementPaths.AZURE_APPS);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Object.values(ManagementPaths).forEach((path) => {
      if (location.pathname.includes(path)) {
        setValue(path);
      }
    });
  }, [location.pathname, setValue]);

  const onTabChanged = (newValue) => {
    dispatch(clearBillingHistory());
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Layout p={2}>
      <PageHeaderText text={t("management")} />
      <Paper sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(_e, newValue) => onTabChanged(newValue)}
          >
            <Tab
              value={ManagementPaths.AZURE_APPS}
              label={t("azureApplications")}
            />
          </Tabs>
          <Outlet />
        </Box>
      </Paper>
    </Layout>
  );
};

export default Management;
