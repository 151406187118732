import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AccountOwner = ({ owner, ownerInvalid, ...containerProps }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={ownerInvalid === true ? t("invalidEmail") : t("validEmail")}
      sx={{ ...containerProps }}
    >
      <Typography
        variant="body2"
        color={ownerInvalid === true ? "error" : "inherited"}
      >
        {owner || "-"}
      </Typography>
    </Tooltip>
  );
};

export default AccountOwner;
