import { Paper } from "@mui/material";
import Layout from "../layout/layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocumentTree } from "../../redux/reducers/docsReducer";
import DocLeaf from "./docLeaf";
import Loader from "../loader/loader";
import { showSnackbar } from "../../redux/reducers/notificationReducer";
import PageHeaderText from "../pageHeaderText/pageHeaderText";
import { useTranslation } from "react-i18next";

const Docs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { documentTree, fetchingDocumentTree, documentTreeFetchError } =
    useSelector((state) => state.docs);

  useEffect(() => {
    dispatch(fetchDocumentTree());
  }, [dispatch]);

  useEffect(() => {
    if (documentTreeFetchError != null) {
      dispatch(
        showSnackbar({
          message: documentTreeFetchError,
          severity: "error",
        })
      );
    }
  }, [documentTreeFetchError, dispatch]);

  return (
    <Layout p={2}>
      <PageHeaderText text={t("docs")} />
      <Paper sx={{ p: 2 }}>
        {fetchingDocumentTree === true && <Loader />}
        {fetchingDocumentTree === false &&
          Object.keys(documentTree).map((key) => {
            const entry = documentTree[key];
            return <DocLeaf mb={2} key={key} entry={entry} />;
          })}
      </Paper>
    </Layout>
  );
};

export default Docs;
