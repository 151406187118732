import { Box, Button, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAccountDetails,
  fetchAccountDetails,
} from "../../redux/reducers/accountsReducer";
import { showSnackbar } from "../../redux/reducers/notificationReducer";
import Loader from "../loader/loader";
import { useTranslation } from "react-i18next";
import DescriptionValue from "./descriptionValue";
import CostCenterStr from "../billing/tilkka/costCenterStr";
import OrderNumberStr from "../billing/tilkka/orderNumberStr";
import ProductCodeStr from "../billing/tilkka/productCodeStr";
import TaxCodeStr from "../billing/tilkka/taxCodeStr";
import PurchaseGroupNumberStr from "../billing/tilkka/purchaseGroupNumberStr";
import GlAccountNumberStr from "../billing/tilkka/glAccountNumberStr";
import AccountOwner from "../billing/tilkka/accountOwner";
import BillingByAccountTable from "../billing/billingByAccount/billingByAccountTable";
import VirtualEmailTable from "../virtualEmail/virtualEmailRecords/virtualEmailTable";
import {
  fetchVirtualEmail,
  clearVirtualEmail,
} from "../../redux/reducers/virtualEmailReducer";
import { Service } from "../common";
import {
  constructVirtualEmailAddress,
  getValidationErrorsStr,
} from "../../utils/virtualEmail";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getTilkkaRecordStatus } from "../../utils/tilkka";
import { validateTilkkaRecord } from "../billing/tilkka/validateTilkkaRecord";

const AccountDetails = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const { accountDetails, fetchingAccountDetails, accountDetailsFetchError } =
    useSelector((state) => state.account);
  const { virtualEmail, fetchingVirtualEmail } = useSelector(
    (state) => state.virtualEmail
  );
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(clearAccountDetails());
      dispatch(clearVirtualEmail());
    };
  }, [dispatch]);

  useEffect(() => {
    const { service, accountId } = params;
    if (
      accountDetails == null ||
      accountDetails.account.accountId !== accountId
    ) {
      dispatch(fetchAccountDetails(service, accountId));
    }
  }, [dispatch, params, accountDetails]);

  useEffect(() => {
    console.log("useEffect - accountDetails", accountDetails);

    if (accountDetails != null) {
      const { service, accountId, account } = accountDetails;
      // Start fetching virtual email
      if (service === Service.GCP) {
        const projectNumber = account.name.replace("projects/", "");
        dispatch(
          fetchVirtualEmail(
            constructVirtualEmailAddress(service, projectNumber)
          )
        );
      } else {
        dispatch(
          fetchVirtualEmail(constructVirtualEmailAddress(service, accountId))
        );
      }
    }
  }, [accountDetails, dispatch]);

  // Error notifications
  useEffect(() => {
    if (accountDetailsFetchError != null) {
      dispatch(
        showSnackbar({
          message: accountDetailsFetchError,
          severity: "error",
        })
      );
    }
  }, [accountDetailsFetchError, dispatch]);

  // Loader
  if (fetchingAccountDetails === true) {
    return <Loader p={1} />;
  }

  // Sanity
  if (accountDetails == null) {
    return null;
  }

  const { account, billingDataByAccountRecords } = accountDetails;
  let tilkka = accountDetails.tilkka;
  let tilkkaStatus = "-";
  if (tilkka != null) {
    const validationErrorMap = validateTilkkaRecord(tilkka);
    const statusError = getValidationErrorsStr(validationErrorMap);
    tilkkaStatus = getTilkkaRecordStatus(tilkka, statusError);
  }
  // safe tilkka
  tilkka = tilkka || {};

  return (
    <Box>
      <Button
        sx={{ m: 0.5 }}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
      >
        {t("back")}
      </Button>
      <Paper sx={{ my: 2, mx: 2, p: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle">{t("accountDetails")}</Typography>
        </Box>
        <DescriptionValue
          mb={0.5}
          description={t("cloud")}
          value={account.service}
        />
        <DescriptionValue
          mb={0.5}
          description={t("accountNameTilkka")}
          value={tilkka.accountName || "-"}
        />
        <DescriptionValue
          mb={0.5}
          description={t("accountNameCloud")}
          value={account.accountName}
        />
        <DescriptionValue
          mb={0.5}
          description={t("accountId")}
          value={account.accountId}
        />
        <DescriptionValue
          mb={0.5}
          description={t("tenant")}
          value={account.tenantId || "-"}
        />
        <DescriptionValue
          mb={0.5}
          description={t("createdTilkka")}
          value={tilkka.createdAt || "-"}
        />
        <DescriptionValue
          mb={0.5}
          description={t("createdCloud")}
          value={account.createdAt || "-"}
        />
        <DescriptionValue
          mb={0.5}
          description={t("ownerEmailTilkka")}
          renderValueAsComponent={true}
          value={
            <AccountOwner
              owner={tilkka.owner || "-"}
              ownerInvalid={tilkka.ownerInvalid || "-"}
            />
          }
        />
        <DescriptionValue
          mb={0.5}
          description={t("ownerEmailCloud")}
          value={account.email || "-"}
        />
        <DescriptionValue
          mb={0.5}
          description={t("statusTilkka")}
          value={tilkkaStatus}
        />
        <DescriptionValue
          mb={0.5}
          description={t("statusCloud")}
          value={account.status}
        />
        <DescriptionValue
          mb={0.5}
          description={t("costCenter")}
          value={<CostCenterStr objectValue={tilkka.costCenter} />}
        />
        <DescriptionValue
          mb={0.5}
          description={t("taxCode")}
          value={<TaxCodeStr objectValue={tilkka.taxCode} />}
        />
        <DescriptionValue
          mb={0.5}
          description={t("purchaseGroupNumber")}
          value={
            <PurchaseGroupNumberStr objectValue={tilkka.purchaseGroupNumber} />
          }
        />
        <DescriptionValue
          mb={0.5}
          description={t("glAccountNumber")}
          value={<GlAccountNumberStr objectValue={tilkka.glAccountNumber} />}
        />
        <DescriptionValue
          mb={0.5}
          description={t("internalOrderNumber")}
          value={<OrderNumberStr objectValue={tilkka.internalOrderNumber} />}
        />
        <DescriptionValue
          mb={0.5}
          description={t("productCode")}
          value={<ProductCodeStr objectValue={tilkka.productCode} />}
        />
      </Paper>
      <Paper sx={{ mx: 2, p: 2, mt: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle">
            {`${t("expenditure")} ${moment().year()} - ${moment().year() - 1}`}
          </Typography>
        </Box>
        <BillingByAccountTable
          tableRecords={billingDataByAccountRecords}
          fetching={false}
          hideAccount
          hideService
        />
      </Paper>
      <Paper sx={{ mx: 2, p: 2, mt: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle">{t("virtualEmail")}</Typography>
        </Box>
        {fetchingVirtualEmail === false && (
          <VirtualEmailTable
            records={virtualEmail ? [virtualEmail] : []}
            hideActions
            hideStatus
          />
        )}
        {fetchingVirtualEmail === true && <Loader p={1} />}
      </Paper>
    </Box>
  );
};

export default AccountDetails;
