import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";

const RadioSelection = ({
  title,
  entries,
  selectedEntries,
  onSelected,
  error,
  ...containerProps
}) => {
  return (
    <FormControl
      sx={{ my: 1, ...containerProps }}
      component="fieldset"
      variant="standard"
      error={error}
    >
      <FormLabel component="legend">{title}</FormLabel>
      <FormGroup>
        {entries.map((entry) => {
          return (
            <FormControlLabel
              key={entry.value}
              control={
                <Checkbox
                  checked={
                    selectedEntries.find((e) => e.value === entry.value) != null
                  }
                  onChange={(_e, value) => {
                    if (value === false) {
                      const index = selectedEntries.findIndex(
                        (selEntry) => selEntry.value === entry.value
                      );
                      selectedEntries.splice(index, 1);
                    } else {
                      selectedEntries.push(entry);
                    }
                    onSelected(selectedEntries);
                  }}
                  name={entry.value}
                />
              }
              label={entry.label}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

RadioSelection.propTypes = {
  title: PropTypes.string.isRequired,
  entries: PropTypes.array.isRequired,
  selectedEntries: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default RadioSelection;
