import React, { useState, useEffect } from "react";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../layout/layout";
import PageHeaderText from "../pageHeaderText/pageHeaderText";
import { fetchVirtualEmailConfiguration } from "../../redux/reducers/virtualEmailReducer";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Permission, hasPermission } from "../../auth/permission";

export const VirtualEmailPaths = Object.freeze({
  RECORDS: "records",
  CONFIGURATION: "configuration",
});

const VirtualEmail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(VirtualEmailPaths.RECORDS);
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useSelector((state) => state.user);

  useEffect(() => {
    Object.values(VirtualEmailPaths).forEach((path) => {
      if (location.pathname.includes(path)) {
        setValue(path);
      }
    });
  }, [location.pathname, setValue]);

  // fetch configuration aready here
  useEffect(() => {
    dispatch(fetchVirtualEmailConfiguration());
  }, []); // eslint-disable-line

  const onTabChanged = (newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const showConfiguration = hasPermission(userData, Permission.ADMIN);
  return (
    <Layout p={2}>
      <PageHeaderText text={t("virtualEmail")} />
      <Paper sx={{ p: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(_e, newValue) => onTabChanged(newValue)}
          >
            <Tab value={VirtualEmailPaths.RECORDS} label={t("records")} />
            {showConfiguration === true && (
              <Tab
                value={VirtualEmailPaths.CONFIGURATION}
                label="configuration"
              />
            )}
          </Tabs>
        </Box>
        <Outlet />
      </Paper>
    </Layout>
  );
};

VirtualEmail.propTypes = {};

export default VirtualEmail;
