import axios from "../../utils/axios";
import {
  initBillingRecordsMonthlyMap,
  updateBillingRecordsMonthlyMap,
  updateBillingHistoryByAccountRecords,
} from "../../utils/billing";

const Types = {
  FETCHING_HISTORY_START: "FETCHING_HISTORY_START",
  FETCHING_HISTORY_FINISH: "FETCHING_HISTORY_FINISH",
  FETCHING_HISTORY_ERROR: "FETCHING_HISTORY_ERROR",
  UPDATE_BILLING_RECORDS: "UPDATE_BILLING_RECORD",
  FETCHING_BILLING_DATA_START: "FETCHING_BILLING_DATA_START",
  FETCHING_BILLING_DATA_FINISH: "FETCHING_BILLING_DATA_FINISH",
  FETCHING_BILLING_DATA_ERROR: "FETCHING_BILLING_DATA_ERROR",
  FETCHING_DISCOUNT_COST_START: "FETCHING_DISCOUNT_COST_START",
  FETCHING_DISCOUNT_COST_FINISH: "FETCHING_DISCOUNT_COST_FINISH",
  FETCHING_DISCOUNT_COST_ERROR: "FETCHING_DISCOUNT_COST_ERROR",
  UPDATE_DISCOUNT_COST: "UPDATE_DISCOUNT_COST",
};

const API_URL = process.env.REACT_APP_API_URL;
const BILLING_API_URL = `${API_URL}billing`;
const DISCOUNT_API_URL = `${API_URL}billing/discount/cost-by-account`;
let abortController;

const getAbortController = () => {
  if (abortController != null) {
    abortController.abort();
  }
  abortController = new AbortController();
  return abortController;
};

const constructGetBillingHistoryUrl = (type, service, year, monthNumber) => {
  let url = `${BILLING_API_URL}/history?type=${type}`;
  if (service != null) {
    url = `${url}&service=${service}`;
  }
  if (year != null) {
    url = `${url}&year=${year}`;
  }
  if (monthNumber != null) {
    url = `${url}&monthNumber=${monthNumber}`;
  }
  return url;
};

export const fetchBillingHistory = (
  type,
  service = null,
  year = null,
  monthNumber = null,
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: Types.FETCHING_HISTORY_START });

      const url = constructGetBillingHistoryUrl(
        type,
        service,
        year,
        monthNumber,
      );
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchBillingHistory", response.data);

      const { billingRecordsMonthlyMap } = getState().billing;
      dispatch({
        type: Types.UPDATE_BILLING_RECORDS,
        payload: {
          map: updateBillingRecordsMonthlyMap(
            billingRecordsMonthlyMap,
            response.data.items,
          ),
        },
      });
      dispatch({ type: Types.FETCHING_HISTORY_FINISH });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_HISTORY_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

const constructGetHistoryByOwnerUrl = (owner, year, monthNumber) => {
  let url = `${BILLING_API_URL}/history/owner/${owner}?year=${year}`;
  if (monthNumber != null) {
    url = `${url}&monthNumber=${monthNumber}`;
  }
  return url;
};

export const fetchBillingHistoryByOwner = (owner, year, monthNumber = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_HISTORY_START });

      const url = constructGetHistoryByOwnerUrl(owner, year, monthNumber);
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchBillingHistoryByOwner", response.data);

      dispatch({
        type: Types.UPDATE_BILLING_RECORDS,
        payload: {
          billingRecords: response.data.items,
        },
      });
      dispatch({ type: Types.FETCHING_HISTORY_FINISH });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_HISTORY_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const fetchBillingHistoryByAccount = (service, year) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Types.FETCHING_HISTORY_START });

      let url = `${BILLING_API_URL}/history/account?year=${year}&service=${service}`;
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("fetchBillingHistoryByAccount", response.data.items);

      dispatch({
        type: Types.UPDATE_BILLING_RECORDS,
        payload: {
          billingRecords: updateBillingHistoryByAccountRecords(
            response.data.items,
          ),
        },
      });
      dispatch({ type: Types.FETCHING_HISTORY_FINISH });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_HISTORY_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const clearBillingHistory = () => {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_BILLING_RECORDS,
      payload: { map: initBillingRecordsMonthlyMap(), billingRecords: [] },
    });
  };
};

export const getBillingData = (service, year, monthNumber) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: Types.FETCHING_BILLING_DATA_START,
        payload: monthNumber,
      });

      const url = `${BILLING_API_URL}/?service=${service}&year=${year}&monthNumber=${monthNumber}`;
      const response = await axios.get(url, {
        signal: getAbortController().signal,
      });
      console.log("getBillingData", response.data);

      const { billingRecordsMonthlyMap } = getState().billing;
      dispatch({
        type: Types.UPDATE_BILLING_RECORDS,
        payload: {
          map: updateBillingRecordsMonthlyMap(
            billingRecordsMonthlyMap,
            response.data.items,
          ),
        },
      });

      dispatch({
        type: Types.FETCHING_BILLING_DATA_FINISH,
        payload: monthNumber,
      });
    } catch (error) {
      dispatch({
        type: Types.FETCHING_BILLING_DATA_ERROR,
        payload: {
          monthNumber,
          error: error.message,
        },
      });
    }
  };
};

export const clearDiscountCostByAccount = () => {
  return (dispatch) => {
    dispatch({
      type: Types.UPDATE_DISCOUNT_COST,
      payload: {
        items: [],
        discountDetails: {},
      },
    });
  };
};

export const getDiscountCostByAccount = (
  service,
  year,
  monthNumber,
  useStoredData,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: Types.FETCHING_DISCOUNT_COST_START,
        payload: monthNumber,
      });

      const url = `${DISCOUNT_API_URL}/?service=${service}&year=${year}&monthNumber=${monthNumber}&useStoredData=${useStoredData}`;
      const response = await axios.get(url);
      const { data } = response;

      console.log("getDiscountCostByAccount", data);

      dispatch({
        type: Types.UPDATE_DISCOUNT_COST,
        payload: data,
      });

      dispatch({
        type: Types.FETCHING_DISCOUNT_COST_FINISH,
      });
    } catch (error) {
      if (axios.isCancel(error) === false) {
        dispatch({
          type: Types.FETCHING_DISCOUNT_COST_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

const INIT_STATE = {
  billingRecordsMonthlyMap: initBillingRecordsMonthlyMap(),
  billingRecords: [],
  fetchingHistory: false,
  historyFetchError: null,
  fetchingBillingDataMap: new Map(),
  billingDataFetchError: null,
  discountCostByAccount: [],
  discountDetails: {},
  fetchingDiscountCost: false,
  discountCostFetchError: null,
};

export const billingReducer = (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case Types.FETCHING_DISCOUNT_COST_START:
      return {
        ...state,
        fetchingDiscountCost: true,
        discountCostFetchError: null,
      };
    case Types.FETCHING_DISCOUNT_COST_FINISH:
      return {
        ...state,
        fetchingDiscountCost: false,
      };
    case Types.UPDATE_DISCOUNT_COST: {
      const { items, discountDetails } = action.payload;
      return {
        ...state,
        discountCostByAccount: items,
        discountDetails,
      };
    }
    case Types.FETCHING_DISCOUNT_COST_ERROR:
      return {
        ...state,
        fetchingDiscountCost: false,
        discountCostFetchError: action.payload,
      };
    case Types.FETCHING_HISTORY_START:
      return { ...state, fetchingHistory: true, historyFetchError: null };
    case Types.FETCHING_HISTORY_FINISH:
      return {
        ...state,
        fetchingHistory: false,
      };
    case Types.UPDATE_BILLING_RECORDS: {
      const { map, billingRecords } = action.payload;
      return {
        ...state,
        billingRecordsMonthlyMap: map ? new Map(map) : null,
        billingRecords: billingRecords || [],
      };
    }
    case Types.FETCHING_HISTORY_ERROR:
      return {
        ...state,
        fetchingHistory: false,
        historyFetchError: action.payload,
      };
    case Types.FETCHING_BILLING_DATA_START: {
      const { fetchingBillingDataMap } = state;
      fetchingBillingDataMap.set(action.payload, true);
      return {
        ...state,
        fetchingBillingDataMap: new Map(fetchingBillingDataMap),
        billingDataFetchError: null,
      };
    }
    case Types.FETCHING_BILLING_DATA_FINISH: {
      const { fetchingBillingDataMap } = state;
      fetchingBillingDataMap.set(action.payload, false);
      return {
        ...state,
        fetchingBillingDataMap: new Map(fetchingBillingDataMap),
        billingDataFetchError: null,
      };
    }
    case Types.FETCHING_BILLING_DATA_ERROR: {
      const { fetchingBillingDataMap } = state;
      const { monthNumber, error } = action.payload;
      fetchingBillingDataMap.set(monthNumber, false);
      return {
        ...state,
        fetchingBillingDataMap: new Map(fetchingBillingDataMap),
        billingDataFetchError: error,
      };
    }
    default:
      return state;
  }
};
