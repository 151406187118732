import {
  Alert,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBillingHistory,
  fetchBillingHistoryByOwner,
} from "../../../redux/reducers/billingReducer";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import { useTranslation } from "react-i18next";
import { getCurrencyStr } from "../../../utils/billing";
import { Months } from "../filters/monthSelect";
import { createMonthlyCostsFromBillingRecords } from "./createMonthlyCostsFromBillingRecords";
import { CurrencyCode } from "../../common";
const Decimal = require("decimal.js");

const OwnerContent = ({ selectedOwner, selectedYear, ...containerProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billingRecords, fetchingHistory, historyFetchError } = useSelector(
    (state) => state.billing,
  );
  const [tableRecords, setTableRecords] = useState([]);
  const [filteredTableRecords, setFilteredTableRecords] = useState([]);
  const [filterText, setFilterText] = useState(null);

  useEffect(() => {
    if (selectedOwner != null && selectedOwner.length > 0) {
      dispatch(clearBillingHistory());
      dispatch(fetchBillingHistoryByOwner(selectedOwner, selectedYear));
    }
  }, [selectedOwner, selectedYear]); // eslint-disable-line

  useEffect(() => {
    const tableRecords = createMonthlyCostsFromBillingRecords(
      selectedOwner,
      billingRecords,
    );
    tableRecords.sort((a, b) =>
      (a.accountName || "").localeCompare(b.accountName || ""),
    );
    setTableRecords(tableRecords);
    setFilteredTableRecords(tableRecords);
  }, [billingRecords, setTableRecords, setFilteredTableRecords]); // eslint-disable-line

  useEffect(() => {
    if (filterText != null) {
      setFilteredTableRecords(
        tableRecords.filter((tableRecord) =>
          tableRecord.accountName.toLocaleLowerCase().includes(filterText),
        ),
      );
    }
  }, [filterText, tableRecords, setFilteredTableRecords]);

  useEffect(() => {
    if (historyFetchError != null) {
      dispatch(
        showSnackbar({
          message: historyFetchError,
          severity: "error",
        }),
      );
    }
  }, [historyFetchError, dispatch]);

  const calculateTotalCostFromTableRecords = (tableRecords) => {
    return tableRecords.reduce(
      (acc, next) => {
        const { preTaxOverallCost, overallCost, currency } = next;
        const cost = new Decimal(acc.cost).plus(overallCost).toNumber();
        const preTaxCost = new Decimal(acc.preTaxCost)
          .plus(preTaxOverallCost)
          .toNumber();
        return { cost, preTaxCost, currency };
      },
      { cost: 0, preTaxCost: 0, currency: CurrencyCode.EUR },
    );
  };

  const getInfo = () => {
    if (selectedOwner == null || selectedOwner.length === 0) {
      return <Typography>{t("noneSelected")}</Typography>;
    }

    const result = calculateTotalCostFromTableRecords(filteredTableRecords);
    const currencyStr = getCurrencyStr(result.currency);
    const preTaxTotalCostStr = `${new Decimal(result.preTaxCost).toFixed(2)}${currencyStr}`;
    const totalCostStr = `${new Decimal(result.cost).toFixed(2)}${currencyStr}`;

    return (
      <>
        <Typography>{`${t("owner")}: ${selectedOwner}`}</Typography>
        <Typography sx={{ mt: 0.5 }}>
          {`${t("year")}: ${selectedYear}`}
        </Typography>
        <Typography sx={{ mt: 0.5 }}>
          {`${t("cost")} (${t("preTax")}): ${preTaxTotalCostStr}`}
        </Typography>
        <Typography sx={{ mt: 0.5 }}>
          {`${t("cost")} (${t("withVat")}): ${totalCostStr}`}
        </Typography>
      </>
    );
  };

  return (
    <Box sx={{ ...containerProps }}>
      {getInfo()}
      <TextField
        sx={{ my: 1 }}
        label={t("filter")}
        placeholder={t("tilkkaFilterPlaceholder")}
        size="small"
        variant="outlined"
        value={filterText || ""}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <Alert severity="info">{t("monthlyCostsArePreTax")}</Alert>
      <TableContainer sx={{ mt: 1 }} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>{t("cloud")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("account")}</TableCell>
              {Object.values(Months).map((month) => {
                // Months are 1 to 12
                return (
                  <TableCell key={month} sx={{ fontWeight: "bold" }}>
                    {t(`months.${month}`).substring(0, 3)}
                  </TableCell>
                );
              })}
              <TableCell sx={{ fontWeight: "bold" }}>
                {`${t("total")} (${t("preTax")})`}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {`${t("total")} (${t("withVat")})`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchingHistory === true && (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {fetchingHistory === false && tableRecords.length === 0 && (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  {t("itemsNotFound")}
                </TableCell>
              </TableRow>
            )}
            {fetchingHistory === false &&
              filteredTableRecords.map((tableRecord) => {
                const {
                  accountName,
                  monthlyCostMap,
                  overallCost,
                  preTaxOverallCost,
                  currency,
                  service,
                } = tableRecord;
                const currencyStr = getCurrencyStr(currency);
                return (
                  <TableRow key={accountName}>
                    <TableCell>{service}</TableCell>
                    <TableCell>{accountName}</TableCell>
                    {Object.values(Months).map((month) => {
                      // Months are 1 to 12
                      let preTaxCostStr = "-";
                      const preTaxCost = monthlyCostMap.get(month);
                      if (preTaxCost != null) {
                        preTaxCostStr = `${preTaxCost || "-"}${currencyStr}`;
                      }
                      return <TableCell key={month}>{preTaxCostStr}</TableCell>;
                    })}
                    <TableCell>{`${preTaxOverallCost}${currencyStr}`}</TableCell>
                    <TableCell>{`${overallCost}${currencyStr}`}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OwnerContent;
