import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import TaxCodeTable from "./taxCodeTable";
import CostCenterTable from "./costCenterTable";
import BillingAccountNumberTable from "./billingAccountNumberTable";
import InternalOrderNumberTable from "./internalOrderNumberTable";
import ProductCodeTable from "./productCodeTable";
import PurchaseGroupNumberTable from "./purchaseGroupNumberTable";

const TabValues = Object.freeze({
  TaxCodes: "taxCodes",
  CostCenters: "costCenters",
  BillingAccountNumbers: "billingAccountNumbers",
  InternalOrderNumbers: "internalOrderNumbers",
  ProductCodes: "productCodes",
  PurchaseGroupNumbers: "purchaseGroupNumbers",
});

const SapBillingData = ({ ...containerPops }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(TabValues.TaxCodes);

  return (
    <Box
      sx={{
        p: 2,
        ...containerPops,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Tabs value={value} onChange={(_e, newValue) => setValue(newValue)}>
          <Tab value={TabValues.TaxCodes} label={t("taxCodes")} />
          <Tab value={TabValues.CostCenters} label={t("costCenters")} />
          <Tab
            value={TabValues.BillingAccountNumbers}
            label={t("billingAccountNumbers")}
          />
          <Tab
            value={TabValues.InternalOrderNumbers}
            label={t("internalOrderNumbers")}
          />
          <Tab value={TabValues.ProductCodes} label={t("productCodes")} />
          <Tab
            value={TabValues.PurchaseGroupNumbers}
            label={t("purchaseGroupNumbers")}
          />
        </Tabs>
      </Box>
      {value === TabValues.TaxCodes && <TaxCodeTable />}
      {value === TabValues.CostCenters && <CostCenterTable />}
      {value === TabValues.BillingAccountNumbers && (
        <BillingAccountNumberTable />
      )}
      {value === TabValues.InternalOrderNumbers && <InternalOrderNumberTable />}
      {value === TabValues.ProductCodes && <ProductCodeTable />}
      {value === TabValues.PurchaseGroupNumbers && <PurchaseGroupNumberTable />}
    </Box>
  );
};

SapBillingData.propTypes = {};

export default SapBillingData;
