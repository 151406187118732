import * as Joi from "joi";
import {
  getDomainFromEmailAddress,
  getVirtualEmailDomainsFromConfig,
} from "../../../utils/virtualEmail";

export const VirtualEmailValidatedProps = Object.freeze({
  ID: "id",
  MANAGED: "managed",
  PROTECTED: "protected",
  OWNER: "owner",
  RECIPIENTS: "recipients",
});

const isRecipientsAllowed = (recipients, allowedRecipientDomains) => {
  for (const recipient of recipients) {
    const domain = getDomainFromEmailAddress(recipient);
    if (allowedRecipientDomains.includes(domain) === false) {
      return false;
    }
  }
  return true;
};

const getSchema = (configuration, existingVirtualEmails) => {
  const { AllowedOwnerDomains, AllowedRecipientDomains } = configuration;
  return Joi.object({
    id: Joi.string()
      .email({ tlds: false })
      .custom((value, helper) => {
        const alreadyAdded =
          existingVirtualEmails.find((vmail) => vmail.id === value) != null;
        const domain = getDomainFromEmailAddress(value);
        const allDomains = getVirtualEmailDomainsFromConfig(configuration);
        if (allDomains.includes(domain) === false || alreadyAdded) {
          return helper.error("any.invalid");
        }
        return value;
      })
      .required(),
    managed: Joi.bool().allow(null, "").optional(),
    owner: Joi.string()
      .email({ tlds: false })
      .custom((value, helper) => {
        const domain = getDomainFromEmailAddress(value);
        if (AllowedOwnerDomains.includes(domain) === false) {
          return helper.error("any.invalid");
        }
        return value;
      })
      .required(),
    protected: Joi.bool().allow(null, "").optional(),
    recipients: Joi.string()
      .custom((value, helper) => {
        try {
          const recipientsArray = JSON.parse(value);
          const isEmpty = recipientsArray.length === 0;
          const isAllowed = isRecipientsAllowed(
            recipientsArray,
            AllowedRecipientDomains
          );
          const tooManyRecipients = recipientsArray.length > 50;
          if (isEmpty || isAllowed === false || tooManyRecipients) {
            return helper.error("any.invalid");
          }
          return value;
        } catch {
          return helper.error("any.invalid");
        }
      })
      .required(),
    invalidRecipients: Joi.string().allow(null, "").optional(),
    createdAt: Joi.string().allow(null, "").optional(),
    modifiedAt: Joi.string().allow(null, "").optional(),
    deleted: Joi.bool().allow(null).optional(),
  });
};

export const validateVirtualEmailRecord = (
  record,
  configuration,
  existingVirtualEmails
) => {
  // sanity
  if (configuration == null) {
    return new Map();
  }

  const schema = getSchema(configuration, existingVirtualEmails);
  const result = schema.validate(record, {
    abortEarly: false,
  });
  const { error } = result;
  if (error != null) {
    const { details } = error;
    return details.reduce((acc, next) => {
      // NOTE! ID field is shown as "virtualEmail" for clarification in the UI
      acc.set(next.context.key, next.message.replace('"id"', '"virtualEmail"'));
      return acc;
    }, new Map());
  }

  return new Map();
};
