import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchaseGroupNumbers } from "../../../redux/reducers/sapBillingDataReducer";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";

const PurchaseGroupNumberTable = ({ ...containerPops }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    purchaseGroupNumbers,
    purchaseGroupFetchError,
    fetchingPurchaseGroupNumbers,
  } = useSelector((state) => state.sapBilling);

  useEffect(() => {
    dispatch(fetchPurchaseGroupNumbers());
  }, [dispatch]);

  // Error notifications
  useEffect(() => {
    if (purchaseGroupFetchError != null) {
      dispatch(
        showSnackbar({
          message: purchaseGroupFetchError,
          severity: "error",
        })
      );
    }
  }, [purchaseGroupFetchError, dispatch]);

  const records = purchaseGroupNumbers || [];
  return (
    <TableContainer component={Paper} sx={{ ...containerPops }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("purchaseGroupNumber")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("description")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchingPurchaseGroupNumbers === true && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <Loader />
              </TableCell>
            </TableRow>
          )}
          {fetchingPurchaseGroupNumbers === false && records.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {fetchingPurchaseGroupNumbers === false &&
            records.map((row) => {
              const value = row.value || row.purchaseGroupNumber;
              return (
                <TableRow
                  key={value}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{value}</TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PurchaseGroupNumberTable.propTypes = {};

export default PurchaseGroupNumberTable;
