import {
  Box,
  Popover,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CostCenterStr from "../tilkka/costCenterStr";
import PruchaseGroupNumberStr from "../tilkka/purchaseGroupNumberStr";
import GlAccountNumberStr from "../tilkka/glAccountNumberStr";
import OrderNumberStr from "../tilkka/orderNumberStr";
import ProductCodeStr from "../tilkka/productCodeStr";
import TaxCodeStr from "../tilkka/taxCodeStr";
import Decimal from "decimal.js";
import moment from "moment";

const AccountInfoPopover = ({ record }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    costCenter,
    purchaseGroupNumber,
    glAccountNumber,
    orderNumber,
    productCode,
    taxCode,
    createdAt,
    vatRate,
  } = record;

  const vatStr = `${new Decimal(vatRate * 100 - 100).toFixed(1)}%`;

  const open = Boolean(anchorEl);
  return (
    <Box>
      <IconButton color="primary" onClick={handleClick}>
        <InfoIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, width: "100%" }}>
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemText
                primary={<CostCenterStr objectValue={costCenter} />}
                secondary={t("costCenter")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <PruchaseGroupNumberStr objectValue={purchaseGroupNumber} />
                }
                secondary={t("purchaseGroupNumber")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<GlAccountNumberStr objectValue={glAccountNumber} />}
                secondary={t("glAccountNumber")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<OrderNumberStr objectValue={orderNumber} />}
                secondary={t("orderNumber")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<ProductCodeStr objectValue={productCode} />}
                secondary={t("productCode")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<TaxCodeStr objectValue={taxCode} />}
                secondary={t("taxCode")}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={vatStr} secondary={t("vat")} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={moment(createdAt).format("DD.MM.YYYY HH:mm:ss")}
                secondary={t("created")}
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </Box>
  );
};

export default AccountInfoPopover;
