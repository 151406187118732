import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { fetchDocument } from "../../redux/reducers/docsReducer";
import { Box, CircularProgress, Typography } from "@mui/material";

const ApiDoc = () => {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const { doc, fetchingDoc, docFetchingError } = useSelector(
    (state) => state.docs
  );

  useEffect(() => {
    if (docId) {
      dispatch(fetchDocument(docId));
    }
  }, [docId, dispatch]);

  const renderCenter = (component) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {component}
      </Box>
    );
  };

  return (
    <Box>
      {(doc == null || fetchingDoc === true) &&
        renderCenter(<CircularProgress size={40} />)}
      {docFetchingError != null &&
        renderCenter(
          <Typography variant="h5" color="error">
            {docFetchingError}
          </Typography>
        )}
      {fetchingDoc === false && docFetchingError == null && doc != null && (
        <SwaggerUI spec={doc} />
      )}
    </Box>
  );
};

export default ApiDoc;
