import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTilkkaRecords } from "../../../redux/reducers/tilkkaReducer";
import { tilkkaRecordsToOwnerList } from "../../../utils/tilkka";
import { useTranslation } from "react-i18next";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";

const OwnerList = ({ selectedOwner, onSelect, ...containerProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [filterText, setFilterText] = useState(null);
  const { tilkkaRecords, fetchingTilkkaRecords, tilkkaRecordsFetchError } =
    useSelector((state) => state.tilkka);

  useEffect(() => {
    dispatch(fetchTilkkaRecords());
  }, []); // eslint-disable-line

  useEffect(() => {
    setFilteredOwners(tilkkaRecordsToOwnerList(tilkkaRecords));
  }, [tilkkaRecords, setFilteredOwners]);

  useEffect(() => {
    if (filterText != null) {
      const owners = tilkkaRecordsToOwnerList(tilkkaRecords);
      setFilteredOwners(
        owners.filter((owner) => owner.toLocaleLowerCase().includes(filterText))
      );
    }
  }, [filterText, tilkkaRecords, setFilteredOwners]);

  useEffect(() => {
    if (tilkkaRecordsFetchError != null) {
      dispatch(
        showSnackbar({
          message: tilkkaRecordsFetchError,
          severity: "error",
        })
      );
    }
  }, [tilkkaRecordsFetchError, dispatch]);

  return (
    <Box sx={{ ...containerProps }}>
      <Typography>{t("accountOwners")}</Typography>
      <TextField
        sx={{ my: 1 }}
        label={t("filter")}
        placeholder={t("ownerFilterPlaceholder")}
        size="small"
        variant="outlined"
        value={filterText || ""}
        onChange={(e) => setFilterText(e.target.value)}
      />
      {fetchingTilkkaRecords === true && <Loader mt={1} />}
      {fetchingTilkkaRecords === false && (
        <List dense>
          {filteredOwners.map((owner) => (
            <ListItemButton
              key={owner}
              onClick={() => onSelect(owner)}
              selected={selectedOwner === owner}
            >
              <ListItemText primary={owner} />
            </ListItemButton>
          ))}
        </List>
      )}
    </Box>
  );
};

export default OwnerList;
