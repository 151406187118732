import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Layout from "../layout/layout";
import { Tabs, Tab, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearBillingHistory } from "../../redux/reducers/billingReducer";
import PageHeaderText from "../pageHeaderText/pageHeaderText";
import { Permission, hasPermission } from "../../auth/permission";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const IntegrationPaths = Object.freeze({
  SAP_BILLING_DATA: "sap-billing-data",
  EXTERNAL_CLIENTS: "external-clients",
});

const Integration = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state) => state.user);
  const [value, setValue] = useState(IntegrationPaths.SAP_BILLING_DATA);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Object.values(IntegrationPaths).forEach((path) => {
      if (location.pathname.includes(path)) {
        setValue(path);
      }
    });
  }, [location.pathname, setValue]);

  const onTabChanged = (newValue) => {
    dispatch(clearBillingHistory());
    setValue(newValue);
    navigate(newValue);
  };

  const showExternalClients = hasPermission(userData, Permission.ADMIN);
  return (
    <Layout p={2}>
      <PageHeaderText text={t("integration")} />
      <Paper sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(_e, newValue) => onTabChanged(newValue)}
          >
            <Tab
              value={IntegrationPaths.SAP_BILLING_DATA}
              label={t("sapBillingData")}
            />
            {showExternalClients === true && (
              <Tab
                value={IntegrationPaths.EXTERNAL_CLIENTS}
                label={t("externalClients")}
              />
            )}
          </Tabs>
        </Box>
        <Outlet />
      </Paper>
    </Layout>
  );
};

export default Integration;
