import { calculatePreTaxAndVatCostForRecord } from "../../../utils/billing";

const Decimal = require("decimal.js");

// TODO: this should probably be in the backend
export const createMonthlyCostsFromBillingRecords = (owner, billingRecords) => {
  const ownerMap = billingRecords.reduce((acc, next) => {
    const {
      accountId,
      accountName,
      monthNumber,
      cost,
      preTaxCost,
      currency,
      service,
    } = calculatePreTaxAndVatCostForRecord(next);

    let entry;
    if (acc.has(accountId)) {
      entry = acc.get(accountId);
    } else {
      entry = {
        owner,
        accountId,
        accountName,
        monthlyCostMap: new Map(),
        overallCost: 0,
        preTaxOverallCost: 0,
        service,
      };
    }

    entry.monthlyCostMap.set(monthNumber, new Decimal(preTaxCost).toFixed(2));
    entry.overallCost = new Decimal(entry.overallCost).plus(cost).toFixed(2);
    entry.preTaxOverallCost = new Decimal(entry.preTaxOverallCost)
      .plus(preTaxCost)
      .toFixed(2);
    entry.currency = currency;
    acc.set(accountId, entry);
    return acc;
  }, new Map());

  return [...ownerMap].map(([, value]) => value);
};
