import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchInternalOrderNumbers } from "../../../redux/reducers/sapBillingDataReducer";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import moment from "moment";
import LoadMoreButton from "../../loadMoreButton/loadMoreButton";

const InternalOrderNumberTable = ({ ...containerPops }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    internalOrderNumbers,
    fetchingInternalOrderNumbers,
    internalOrderNumbersFetchError,
    internalOrderNumbersCursor,
  } = useSelector((state) => state.sapBilling);

  useEffect(() => {
    dispatch(fetchInternalOrderNumbers());
  }, [dispatch]);

  // Error notifications
  useEffect(() => {
    if (internalOrderNumbersFetchError != null) {
      dispatch(
        showSnackbar({
          message: internalOrderNumbersFetchError,
          severity: "error",
        })
      );
    }
  }, [internalOrderNumbersFetchError, dispatch]);

  const records = internalOrderNumbers || [];
  return (
    <Box>
      <TableContainer component={Paper} sx={{ ...containerPops }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("internalOrderNumber")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("description")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("updated")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchingInternalOrderNumbers === true && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {fetchingInternalOrderNumbers === false && records.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {t("itemsNotFound")}
                </TableCell>
              </TableRow>
            )}
            {records.map((row) => {
              const value = row.value || row.internalOrderNumber;
              return (
                <TableRow
                  key={value}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{value}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    {moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadMoreButton
        mt={2}
        visible={internalOrderNumbersCursor != null}
        loading={fetchingInternalOrderNumbers}
        onClick={() =>
          dispatch(fetchInternalOrderNumbers(internalOrderNumbersCursor))
        }
      />
    </Box>
  );
};

InternalOrderNumberTable.propTypes = {};

export default InternalOrderNumberTable;
