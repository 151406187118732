import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Authorized from "../../../auth/authorized";
import { Permission } from "../../../auth/permission";
import { getBillingData } from "../../../redux/reducers/billingReducer";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import { CurrencyCode, ReportType } from "../../common";
import { createFileReport } from "../../../redux/reducers/reportReducer";
import CurrencySelect from "../filters/currencySelect";
import TooltipButton from "../../tooltipButton/tooltipButton";
import { isAws } from "../../../utils/billing";

const BillingByServiceActions = ({ service, year, monthNumber }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetchingBillingDataMap, billingDataFetchError } = useSelector(
    (state) => state.billing,
  );
  const { creatingReportDataMap, createReportError } = useSelector(
    (state) => state.report,
  );
  const { userData } = useSelector((state) => state.user);
  const [targetCurrency, setTargetCurrency] = useState(CurrencyCode.EUR);

  useEffect(() => {
    if (createReportError != null) {
      dispatch(
        showSnackbar({
          message: createReportError,
          severity: "error",
        }),
      );
    } else if (billingDataFetchError != null) {
      dispatch(
        showSnackbar({
          message: billingDataFetchError,
          severity: "error",
        }),
      );
    }
  }, [createReportError, billingDataFetchError, dispatch]);

  const fetchingBillingData = fetchingBillingDataMap.get(monthNumber) === true;
  const creatingReport = creatingReportDataMap.get(monthNumber) === true;
  const fetching = fetchingBillingData || creatingReport;
  return (
    <Authorized user={userData} permission={Permission.BILLING_EDIT}>
      <Box sx={{ my: 2 }}>
        {isAws(service) === true && (
          <CurrencySelect
            value={targetCurrency}
            onSelect={(newCurrency) => setTargetCurrency(newCurrency)}
            disabled={fetching === true}
          />
        )}
        <TooltipButton
          ml={1}
          buttonText={`${t("createFileReport")} - ${targetCurrency}`}
          tooltip={t("createExcelTooltip")}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              createFileReport(
                ReportType.BASWARE,
                service,
                year,
                monthNumber,
                targetCurrency,
                true,
              ),
            ).then((report) => {
              if (report) {
                window.open(report.urls[0], "_blank");
              }
            });
          }}
          disabled={fetching === true}
          loading={fetching}
        />

        <TooltipButton
          ml={2}
          buttonText={t("update")}
          tooltip={t("updateBillingDataTooltip")}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(getBillingData(service, year, monthNumber));
          }}
          disabled={fetching === true}
          loading={fetching}
        />
      </Box>
    </Authorized>
  );
};

export default BillingByServiceActions;
