const Types = {
  SET_SNACKBAR_DATA: "SET_SNACKBAR_DATA",
};

export const showSnackbar = (snacbarData) => ({
  type: Types.SET_SNACKBAR_DATA,
  payload: { ...snacbarData, open: true },
});

export const hideSnackbar = () => ({
  type: Types.SET_SNACKBAR_DATA,
  payload: INIT_STATE,
});

const INIT_STATE = {
  snackbar: {
    open: false,
    severity: "error",
    message: "",
  },
};

export const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.SET_SNACKBAR_DATA: {
      return { ...state, snackbar: action.payload };
    }
    default:
      return state;
  }
};
