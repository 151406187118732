import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Layout from "../layout/layout";
import { Tabs, Tab, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearBillingHistory } from "../../redux/reducers/billingReducer";
import PageHeaderText from "../pageHeaderText/pageHeaderText";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const BillingPaths = Object.freeze({
  ALL: "all",
  AWS: "aws",
  AWS_OLD: "aws-old",
  AZURE: "azure",
  GCP: "gcp",
  OWNER: "owner",
  ACCOUNT: "account",
  REPORT_HISTORY: "report-history",
  TILKKA: "tilkka",
  DISCOUNTS: "discounts",
});

const Billing = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(BillingPaths.ALL);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Object.values(BillingPaths).forEach((path) => {
      if (location.pathname.includes(path)) {
        setValue(path);
      }
    });
  }, [location.pathname, setValue]);

  const onTabChanged = (newValue) => {
    dispatch(clearBillingHistory());
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Layout p={2}>
      <PageHeaderText text={t("billing")} />
      <Paper sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(_e, newValue) => onTabChanged(newValue)}
          >
            <Tab value={BillingPaths.ALL} label={t("all")} />
            <Tab value={BillingPaths.AWS} label="AWS" />
            <Tab value={BillingPaths.AWS_OLD} label="AWS (old)" />
            <Tab value={BillingPaths.AZURE} label="Azure" />
            <Tab value={BillingPaths.GCP} label="GCP" />
            <Tab value={BillingPaths.OWNER} label={t("byOwner")} />
            <Tab value={BillingPaths.ACCOUNT} label={t("byAccount")} />
            <Tab value={BillingPaths.REPORT_HISTORY} label={t("reports")} />
            <Tab value={BillingPaths.TILKKA} label={t("tilkka")} />
            <Tab value={BillingPaths.DISCOUNTS} label={t("discounts")} />
          </Tabs>
          <Outlet />
        </Box>
      </Paper>
    </Layout>
  );
};

export default Billing;
