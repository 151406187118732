import { isEmptyString } from "../../../utils/tilkka";

const TaxCodeStr = ({ objectValue }) => {
  // sanity
  if (objectValue == null) {
    return "-";
  }

  const { value, description } = objectValue;
  if (isEmptyString(description)) {
    return value;
  }
  return `${description} (${value})`;
};

export default TaxCodeStr;
