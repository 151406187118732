import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import Loader from "../loader/loader";
import { useTranslation } from "react-i18next";
import LogEntry from "./logEntry";

const LogPanel = ({
  fetchingLogTargets,
  logTargets,
  fetchingLogs,
  logs,
  selectedLogTarget,
  onLogTargetSelected,
  ...containerProps
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", ...containerProps }}>
      <Paper sx={{ flex: 2 }}>
        {fetchingLogTargets === true && <Loader mt={1} />}
        {fetchingLogTargets === false && (
          <List dense>
            {logTargets.map((logTarget) => (
              <ListItemButton
                key={logTarget}
                onClick={() => onLogTargetSelected(logTarget)}
                selected={selectedLogTarget === logTarget}
              >
                <ListItemText primary={logTarget} />
              </ListItemButton>
            ))}
          </List>
        )}
      </Paper>
      <Paper sx={{ flex: 3, ml: 2, overflow: "auto" }}>
        {fetchingLogs === true && <Loader />}
        {fetchingLogs === false &&
          logs.map((log) => <LogEntry key={log.timestamp} logRecord={log} />)}
        {fetchingLogs === false && logs.length === 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{t("itemsNotFound")}</Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default LogPanel;
