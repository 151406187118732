import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillingHistory } from "../../../redux/reducers/billingReducer";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import { RecordDataType, ReportType } from "../../common";
import Authorized from "../../../auth/authorized";
import { Permission } from "../../../auth/permission";
import { createFileReport } from "../../../redux/reducers/reportReducer";
import TooltipButton from "../../tooltipButton/tooltipButton";

const BillingAllActions = ({ year, monthNumber }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { creatingReportDataMap, createReportError } = useSelector(
    (state) => state.report,
  );
  const { userData } = useSelector((state) => state.user);

  useEffect(() => {
    if (createReportError != null) {
      dispatch(
        showSnackbar({
          message: createReportError,
          severity: "error",
        }),
      );
    }
  }, [createReportError, dispatch]);

  const doCreateReport = () => {
    dispatch(
      createFileReport(
        ReportType.BASWARE,
        "ALL",
        year,
        monthNumber,
        "EUR",
        false,
      ),
    )
      .then(() => {
        dispatch(
          fetchBillingHistory(RecordDataType.DATA_AGGREGATED, null, year),
        );
      })
      .catch((error) =>
        console.error("Failed to update data from cloud", error),
      );
  };

  const fetching = creatingReportDataMap.get(monthNumber) === true;
  return (
    <Authorized user={userData} permission={Permission.BILLING_EDIT}>
      <TooltipButton
        my={1}
        buttonText={t("createFileReport")}
        tooltip={t("createExcelsTooltip")}
        onClick={(e) => {
          e.stopPropagation();
          doCreateReport();
        }}
        loading={fetching}
      />
    </Authorized>
  );
};

export default BillingAllActions;
