export const Roles = Object.freeze({
  ADMIN: "ADMIN",
  USER: "USER",
  VIEWER: "VIEWER",
  DOC_VIEWER: "DOC_VIEWER",
});

export const hasRole = (user, role) => {
  const { roles } = user;
  return roles.includes(role);
};
