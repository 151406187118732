import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { calculateSummaryFromMonthlyMap } from "../../../utils/billing";
import StatusIcon, { StatusIconStatus } from "../statusIcon/statusIcon";
import CostSummary from "./costSummary";

const BillingSummary = ({
  complete,
  billingRecordsMonthlyMap,
  recordCountLabel,
  excludeZeroCost,
  hideRecordCount,
  hideCost,
  hideOriginalCost,
  ...containerPops
}) => {
  const { t } = useTranslation();

  const {
    yearlyCost,
    preTaxYearlyCost,
    originalYearlyCost,
    preTaxOriginalYearlyCost,
    accountCount,
    currency,
    originalCurrency,
  } = calculateSummaryFromMonthlyMap(billingRecordsMonthlyMap, excludeZeroCost);

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        display: "flex",
        alignItems: "center",
        p: 2,
        color: "primary.contrastText",
        ...containerPops,
      }}
    >
      <StatusIcon
        mr={1}
        status={complete ? StatusIconStatus.Ok : StatusIconStatus.Warning}
        okTooltip={t("timePeriodComplete")}
        warningTooltip={t("timePeriodIncomplete")}
      />
      <Typography sx={{ minWidth: 100 }}>{t("yearlyCost")}</Typography>
      <CostSummary
        preTaxTotalCost={preTaxYearlyCost}
        preTaxOriginalTotalCost={preTaxOriginalYearlyCost}
        totalCost={yearlyCost}
        originalTotalCost={originalYearlyCost}
        currency={currency}
        originalCurrency={originalCurrency}
        complete={complete}
        recordCountLabel={recordCountLabel}
        recordCount={accountCount}
        hideRecordCount={hideRecordCount}
        hideCost={hideCost}
        hideOriginalCost={hideOriginalCost}
      />
    </Box>
  );
};

export default BillingSummary;
