import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const YearSelect = ({
  value,
  onSelect,
  minValue = 2022,
  ...containerProps
}) => {
  const { t } = useTranslation();
  return (
    <TextField
      size="small"
      sx={{ minWidth: "100px", ...containerProps }}
      label={t("year")}
      type="number"
      value={value}
      onChange={(e) => {
        onSelect(Number(e.target.value));
      }}
      InputProps={{
        inputProps: {
          max: 9999,
          min: minValue,
        },
      }}
      variant="outlined"
    />
  );
};

export default YearSelect;
