const Types = {
  SET_USER_DATA: "SET_USER_DATA",
};

export const setUserData = (cognitoUser) => {
  const { tokens } = cognitoUser;
  const tokenData = tokens.idToken.payload;

  const userData = {
    username: tokenData.sub,
    email: tokenData["cognito:username"],
    roles: JSON.parse(tokenData.roles),
  };

  return {
    type: Types.SET_USER_DATA,
    payload: userData,
  };
};

const INIT_STATE = {
  userData: null,
};

export const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.SET_USER_DATA: {
      return { ...state, userData: action.payload };
    }
    default:
      return state;
  }
};
