import { Alert, Box, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../loader/loader";
import { useEffect } from "react";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";

const Configuration = ({ ...containerProps }) => {
  const {
    virtualEmailConfiguration,
    fetchingVirtualEmailConfiguration,
    virtualEmailConfigurationFetchingError,
  } = useSelector((state) => state.virtualEmail);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Error notifications
  useEffect(() => {
    if (virtualEmailConfigurationFetchingError != null) {
      dispatch(
        showSnackbar({
          message: virtualEmailConfigurationFetchingError,
          severity: "error",
        })
      );
    }
  }, [virtualEmailConfigurationFetchingError, dispatch]);

  const renderList = (items) => {
    return (
      <Box>
        {items.map((item) => (
          <Typography key={item} variant="body1">
            {item}
          </Typography>
        ))}
      </Box>
    );
  };

  const renderMap = (map) => {
    let items = [];
    for (const key of Object.keys(map)) {
      items.push(`${key} -> ${map[key]}`);
    }

    return renderList(items);
  };

  return (
    <Box sx={{ ...containerProps }}>
      {fetchingVirtualEmailConfiguration === true && <Loader />}
      <Alert sx={{ my: 2 }} severity="info">
        {`${t("virtualEmailUpdateInfo")} `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/elisa-public-cloud-infra/aws-virtual-email"
        >
          aws-virtual-email
        </a>
      </Alert>
      <Paper sx={{ p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
          {t("virtualEmailDomainsProd")}
        </Typography>
        {renderList(virtualEmailConfiguration.VirtualEmailDomainsProd || [])}
      </Paper>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
          {t("virtualEmailDomainsTest")}
        </Typography>
        {renderList(virtualEmailConfiguration.VirtualEmailDomainsTest || [])}
      </Paper>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
          {t("allowedOwnerDomains")}
        </Typography>
        {renderList(virtualEmailConfiguration.AllowedOwnerDomains || [])}
      </Paper>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
          {t("allowedRecipientDomains")}
        </Typography>
        {renderList(virtualEmailConfiguration.AllowedRecipientDomains || [])}
      </Paper>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
          {t("masterEmailByDomain")}
        </Typography>
        {renderMap(virtualEmailConfiguration.MasterEmailMap || {})}
      </Paper>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
          {t("bounceEmailsByDomain")}
        </Typography>
        {renderMap(virtualEmailConfiguration.BouncesEmailAddressMap || {})}
      </Paper>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
          {t("filteredDomains")}
        </Typography>
        {renderList(virtualEmailConfiguration.FilteredDomains || [])}
      </Paper>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography sx={{ mb: 1 }} variant="subtitle1" fontWeight="bold">
          {t("blockedEmailAddresses")}
        </Typography>
        {renderList(virtualEmailConfiguration.BlockedEmailAddresses || [])}
      </Paper>
    </Box>
  );
};

export default Configuration;
