import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import Authorized from "../../../auth/authorized";
import { useSelector } from "react-redux";
import { Permission } from "../../../auth/permission";
import Loader from "../../loader/loader";
import StatusIcon from "../statusIcon/statusIcon";
import { validateTilkkaRecord } from "./validateTilkkaRecord";
import { getValidationErrorsStr } from "../../../utils/virtualEmail";
import { getIsoDateStr } from "../../../utils/date";
import TooltipIconButton from "../../tooltipIconButton/tooltipIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { SortDirection, getComparator } from "../../../utils/table";
import SortableTableCell from "../../table/sortableTableCell";
import TaxCodeStr from "./taxCodeStr";
import CostCenterStr from "./costCenterStr";
import PruchaseGroupNumberStr from "./purchaseGroupNumberStr";
import GlAccountNumberStr from "./glAccountNumberStr";
import OrderNumberStr from "./orderNumberStr";
import ProductCodeStr from "./productCodeStr";
import AccountOwner from "./accountOwner";
import { getTilkkaRecordStatus } from "../../../utils/tilkka";

const SortProps = Object.freeze({
  SERVICE: "service",
  ACCOUNT_NAME: "accountName",
  OWNER: "owner",
  PRODUCT_CODE: "productCode.value",
  COST_CENTER: "costCenter.value",
  ORDER_NUMBER: "orderNumber.value",
  TAX_CODE: "taxCode.value",
  PURCHASE_GROUP_NUMBER: "purchaseGroupNumber.value",
  GL_ACCOUNT_NUMBER: "glAccountNumber.value",
  STATUS: "status",
  MODIFIED: "modifiedAt",
  CREATED: "createdAt",
});

const TilkkaTable = ({
  records,
  loading,
  onEditRow,
  onDeleteRow,
  onRestoreRow,
  ...containerPops
}) => {
  const { t } = useTranslation();
  const { userData } = useSelector((state) => state.user);
  const [order, setOrder] = useState(SortDirection.Descending);
  const [orderByProp, setOrderByProp] = useState(SortProps.ACCOUNT_NAME);
  const [sortedRecords, setSortedRecords] = useState([]);

  useEffect(() => {
    setSortedRecords(
      records.map((record) => {
        const validationErrorMap = validateTilkkaRecord(record);
        const statusError = getValidationErrorsStr(validationErrorMap);
        const status = getTilkkaRecordStatus(record, statusError);
        return { ...record, status, statusError };
      })
    );
  }, [records, setSortedRecords]);

  const handleRequestSort = (property) => {
    const isAsc = orderByProp === property && order === SortDirection.Ascending;
    const newOrder = isAsc ? SortDirection.Descending : SortDirection.Ascending;
    setOrder(newOrder);
    setOrderByProp(property);
    setSortedRecords(
      [...sortedRecords].sort(getComparator(newOrder, property))
    );
  };

  const getOriginalRecordByAccountId = (accountId) => {
    return records.find((record) => record.accountId === accountId);
  };

  return (
    <TableContainer component={Paper} sx={{ ...containerPops }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.STATUS}
              label={t("status")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.ACCOUNT_NAME}
              label={t("account")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.OWNER}
              label={t("ownerEmail")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.SERVICE}
              label={t("service")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.TAX_CODE}
              label={t("taxCode")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.COST_CENTER}
              label={t("costCenter")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.PURCHASE_GROUP_NUMBER}
              label={t("purchaseGroupNumber")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.GL_ACCOUNT_NUMBER}
              label={t("glAccountNumber")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.ORDER_NUMBER}
              label={t("orderNumber")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.PRODUCT_CODE}
              label={t("productCode")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.MODIFIED}
              label={t("modified")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.CREATED}
              label={t("created")}
              onSort={handleRequestSort}
            />
            <TableCell sx={{ fontWeight: "bold" }}>{t("actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading === true && (
            <TableRow>
              <TableCell colSpan={13} align="center">
                <Loader />
              </TableCell>
            </TableRow>
          )}
          {loading === false && sortedRecords.length === 0 && (
            <TableRow>
              <TableCell colSpan={13} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {((loading === false && sortedRecords) || []).map((row) => {
            const {
              status,
              statusError,
              accountName,
              owner,
              accountInactive,
              ownerInvalid,
              accountId,
              service,
              costCenter,
              purchaseGroupNumber,
              glAccountNumber,
              orderNumber,
              productCode,
              taxCode,
              accountDeleted,
              description,
            } = row;

            return (
              <TableRow
                key={row.accountId}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  backgroundColor:
                    accountInactive === true || accountDeleted === true
                      ? "lightgrey"
                      : null,
                }}
              >
                <TableCell>
                  <StatusIcon
                    status={status}
                    okTooltip={t("tilkkaStatusOk")}
                    warningTooltip={statusError}
                    deletedTooltip={t("tilkkaDeleteTooltip")}
                    inactiveTooltip={t("tilkkaInactiveTooltip")}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography fontWeight="700">{accountName}</Typography>
                  <Typography variant="body2">{accountId}</Typography>
                  <Typography variant="body2">{description || "-"}</Typography>
                </TableCell>
                <TableCell>
                  <AccountOwner owner={owner} ownerInvalid={ownerInvalid} />
                </TableCell>
                <TableCell>{service.toUpperCase()}</TableCell>
                <TableCell>
                  <TaxCodeStr objectValue={taxCode} />
                </TableCell>
                <TableCell>
                  <CostCenterStr objectValue={costCenter} />
                </TableCell>
                <TableCell>
                  <PruchaseGroupNumberStr objectValue={purchaseGroupNumber} />
                </TableCell>
                <TableCell>
                  <GlAccountNumberStr objectValue={glAccountNumber} />
                </TableCell>
                <TableCell>
                  <OrderNumberStr objectValue={orderNumber} />
                </TableCell>
                <TableCell>
                  <ProductCodeStr objectValue={productCode} />
                </TableCell>
                <TableCell>{getIsoDateStr(row.modifiedAt)}</TableCell>
                <TableCell>{getIsoDateStr(row.createdAt)}</TableCell>
                <TableCell>
                  <Authorized
                    user={userData}
                    permission={Permission.TILKKA_EDIT}
                  >
                    <TooltipIconButton
                      tooltip={t("edit")}
                      icon={<EditIcon />}
                      onClick={() =>
                        onEditRow(getOriginalRecordByAccountId(row.accountId))
                      }
                    />
                    {accountDeleted !== true && (
                      <TooltipIconButton
                        tooltip={t("delete")}
                        icon={<DeleteIcon />}
                        onClick={() =>
                          onDeleteRow(
                            getOriginalRecordByAccountId(row.accountId)
                          )
                        }
                      />
                    )}
                    {accountDeleted === true && (
                      <TooltipIconButton
                        tooltip={t("restore")}
                        icon={<RestoreFromTrashIcon />}
                        onClick={() =>
                          onRestoreRow(
                            getOriginalRecordByAccountId(row.accountId)
                          )
                        }
                      />
                    )}
                  </Authorized>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TilkkaTable.propTypes = {
  records: PropTypes.array,
  loading: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onRestoreRow: PropTypes.func,
};

export default TilkkaTable;
