import { Box, Typography } from "@mui/material";

const DescriptionValue = ({
  description,
  value,
  renderValueAsComponent,
  ...containerProps
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", ...containerProps }}>
      <Typography minWidth="250px" fontWeight="bold" variant="body2">
        {description}
      </Typography>
      {renderValueAsComponent !== true && (
        <Typography variant="body2">{value}</Typography>
      )}
      {renderValueAsComponent === true && value}
    </Box>
  );
};

export default DescriptionValue;
