import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTilkkaRecord,
  fetchTilkkaRecords,
  restoreTilkkaRecord,
  saveTilkkaRecord,
} from "../../../redux/reducers/tilkkaReducer";
import TilkkaTable from "./tilkkaTable";
import EditTilkkaRecordDialog from "./editTilkkaRecordDialog";
import { useTranslation } from "react-i18next";
import {
  filterTilkkaRecords,
  getTilkkaRecordProps,
} from "../../../utils/tilkka";
import { Box } from "@mui/system";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import { Permission } from "../../../auth/permission";
import Authorized from "../../../auth/authorized";
import LabelCheckbox from "../../labelCheckbox/labelCheckbox";
import ConfirmDialog from "../../confirmDialog/confirmDialog";

export const DEFAULT_DIALOG_DATA = {
  open: false,
  title: "",
  onClose: () => {},
  buttonText: "",
  onSave: () => {},
  onOk: () => {},
};

const DEFAULT_CONFIRM_DIALOG_DATA = {
  open: false,
};

const Tilkka = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    tilkkaRecords,
    fetchingTilkkaRecords,
    tilkkaRecordsFetchError,
    savingTilkkaRecord,
    tilkkaRecordSaveError,
  } = useSelector((state) => state.tilkka);
  const { userData } = useSelector((state) => state.user);
  const [editDialogData, setEditDialogData] = useState(DEFAULT_DIALOG_DATA);
  const [confirmDialogData, setConfirmDialogData] = useState(
    DEFAULT_CONFIRM_DIALOG_DATA
  );
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filterText, setFilterText] = useState(null);
  const [hideInactive, setHideInactive] = useState(true);
  const [hideDeleted, setHideDeleted] = useState(true);

  useEffect(() => {
    dispatch(fetchTilkkaRecords());
  }, []); // eslint-disable-line

  useEffect(() => {
    setFilteredRecords(tilkkaRecords);
  }, [tilkkaRecords, setFilteredRecords]);

  useEffect(() => {
    let records = tilkkaRecords;

    if (hideInactive) {
      records = records.filter((record) => record.accountInactive !== true);
    }

    if (hideDeleted) {
      records = records.filter((record) => record.accountDeleted !== true);
    }

    if (filterText != null) {
      records = filterTilkkaRecords(filterText, records);
    }

    setFilteredRecords(records);
  }, [
    filterText,
    hideInactive,
    hideDeleted,
    tilkkaRecords,
    setFilteredRecords,
  ]);

  useEffect(() => {
    if (tilkkaRecordSaveError != null) {
      dispatch(
        showSnackbar({
          message: tilkkaRecordSaveError,
          severity: "error",
        })
      );
    } else if (tilkkaRecordsFetchError != null) {
      dispatch(
        showSnackbar({
          message: tilkkaRecordsFetchError,
          severity: "error",
        })
      );
    }
  }, [tilkkaRecordSaveError, tilkkaRecordsFetchError, dispatch]);

  const editRecord = (data = null, existingAccountIds = []) => {
    setEditDialogData({
      open: true,
      record: data != null ? data : {},
      title: data == null ? t("createNewTilkkaRecord") : t("editTilkkaRecord"),
      onClose: () => setEditDialogData(DEFAULT_DIALOG_DATA),
      onSave: (record) => {
        dispatch(saveTilkkaRecord(getTilkkaRecordProps(record)))
          .then(() => {
            setEditDialogData(DEFAULT_DIALOG_DATA);
          })
          .catch((err) => console.error(err));
      },
      existingAccountIds,
    });
  };

  const deleteRecord = (record) => {
    const { accountId, accountName } = record;
    setConfirmDialogData({
      open: true,
      title: `${t("delete")} ${accountName} (${accountId})?`,
      body: t("deleteRestoreInfo"),
      onClose: (result) => {
        if (result === true) {
          dispatch(deleteTilkkaRecord(accountId))
            .then(() => {
              setConfirmDialogData(DEFAULT_CONFIRM_DIALOG_DATA);
            })
            .catch((err) => console.error(err));
        } else {
          setConfirmDialogData(DEFAULT_CONFIRM_DIALOG_DATA);
        }
      },
    });
  };

  const restoreRecord = (record) => {
    const { accountId, accountName } = record;
    setConfirmDialogData({
      open: true,
      title: `${t("restore")} ${accountName} (${accountId})?`,
      onClose: (result) => {
        if (result === true) {
          dispatch(restoreTilkkaRecord(accountId))
            .then(() => {
              setConfirmDialogData(DEFAULT_CONFIRM_DIALOG_DATA);
            })
            .catch((err) => console.error(err));
        } else {
          setConfirmDialogData(DEFAULT_CONFIRM_DIALOG_DATA);
        }
      },
    });
  };

  const existingAccountIds = (tilkkaRecords || []).map(
    (record) => record.accountId
  );
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
        <Authorized user={userData} permission={Permission.TILKKA_EDIT}>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => editRecord(null, existingAccountIds)}
          >
            {t("addNew")}
          </Button>
        </Authorized>
        <TextField
          sx={{ mr: 2, minWidth: 500 }}
          label={t("filter")}
          placeholder={t("tilkkaFilterPlaceholder")}
          size="small"
          variant="outlined"
          value={filterText || ""}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <LabelCheckbox
          value={hideInactive}
          onChange={setHideInactive}
          label={t("hideInactive")}
        />
        <LabelCheckbox
          value={hideDeleted}
          onChange={setHideDeleted}
          label={t("hideDeleted")}
        />
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography variant="subtitle1">{`${filteredRecords.length} ${t(
          "records"
        ).toLocaleLowerCase()}`}</Typography>
      </Box>
      <TilkkaTable
        records={filteredRecords}
        loading={fetchingTilkkaRecords}
        onEditRow={(row) => editRecord(row)}
        onDeleteRow={(row) => deleteRecord(row)}
        onRestoreRow={(row) => restoreRecord(row)}
      />
      <EditTilkkaRecordDialog {...editDialogData} saving={savingTilkkaRecord} />
      <ConfirmDialog {...confirmDialogData} loading={savingTilkkaRecord} />
    </Box>
  );
};

export default Tilkka;
